@for $i from 5 through 150{
  @if $i % 5 == 0 {
    .padding-#{$i} {
      padding: #{$i}px;
    }
    .padding-t-#{$i} {
      padding-top: #{$i}px;
    }
    .padding-b-#{$i} {
      padding-bottom: #{$i}px;
    }
    .padding-l-#{$i} {
      padding-left: #{$i}px;
    }
    .padding-r-#{$i} {
      padding-right: #{$i}px;
    }
    .padding-lr-#{$i} {
      padding-left: #{$i}px;
      padding-right: #{$i}px;
    }
    .padding-tb-#{$i} {
      padding-top: #{$i}px;
      padding-bottom: #{$i}px;
    }
    .padding-tl-#{$i} {
      padding-top: #{$i}px;
      padding-left: #{$i}px;
    }
    .padding-tr-#{$i} {
      padding-top: #{$i}px;
      padding-right: #{$i}px;
    }
    .padding-bl-#{$i} {
      padding-bottom: #{$i}px;
      padding-left: #{$i}px;
    }
    .padding-br-#{$i} {
      padding-bottom: #{$i}px;
      padding-right: #{$i}px;
    }
  }
}

@media (min-width: 640px) {
  @for $i from 5 through 150 {
      @if $i % 5==0 {
        .sm\:padding-#{$i} {
          padding: #{$i}px;
        }
  
        .sm\:padding-t-#{$i} {
          padding-top: #{$i}px;
        }
  
        .sm\:padding-b-#{$i} {
          padding-bottom: #{$i}px;
        }
  
        .sm\:padding-l-#{$i} {
          padding-left: #{$i}px;
        }
  
        .sm\:padding-r-#{$i} {
          padding-right: #{$i}px;
        }
  
        .sm\:padding-lr-#{$i} {
          padding-left: #{$i}px;
          padding-right: #{$i}px;
        }
  
        .sm\:padding-tb-#{$i} {
          padding-top: #{$i}px;
          padding-bottom: #{$i}px;
        }
  
        .sm\:padding-tl-#{$i} {
          padding-top: #{$i}px;
          padding-left: #{$i}px;
        }
  
        .sm\:padding-tr-#{$i} {
          padding-top: #{$i}px;
          padding-right: #{$i}px;
        }
  
        .sm\:padding-bl-#{$i} {
          padding-bottom: #{$i}px;
          padding-left: #{$i}px;
        }
  
        .sm\:padding-br-#{$i} {
          padding-bottom: #{$i}px;
          padding-right: #{$i}px;
        }
      }
    }
}

@media (min-width: 768px) {
  @for $i from 5 through 150 {
      @if $i % 5==0 {
        .md\:padding-#{$i} {
          padding: #{$i}px;
        }
  
        .md\:padding-t-#{$i} {
          padding-top: #{$i}px;
        }
  
        .md\:padding-b-#{$i} {
          padding-bottom: #{$i}px;
        }
  
        .md\:padding-l-#{$i} {
          padding-left: #{$i}px;
        }
  
        .md\:padding-r-#{$i} {
          padding-right: #{$i}px;
        }
  
        .md\:padding-lr-#{$i} {
          padding-left: #{$i}px;
          padding-right: #{$i}px;
        }
  
        .md\:padding-tb-#{$i} {
          padding-top: #{$i}px;
          padding-bottom: #{$i}px;
        }
  
        .md\:padding-tl-#{$i} {
          padding-top: #{$i}px;
          padding-left: #{$i}px;
        }
  
        .md\:padding-tr-#{$i} {
          padding-top: #{$i}px;
          padding-right: #{$i}px;
        }
  
        .md\:padding-bl-#{$i} {
          padding-bottom: #{$i}px;
          padding-left: #{$i}px;
        }
  
        .md\:padding-br-#{$i} {
          padding-bottom: #{$i}px;
          padding-right: #{$i}px;
        }
      }
    }
}

@media (min-width: 1024px) {
  @for $i from 5 through 150 {
      @if $i % 5==0 {
        .lg\:padding-#{$i} {
          padding: #{$i}px;
        }
  
        .lg\:padding-t-#{$i} {
          padding-top: #{$i}px;
        }
  
        .lg\:padding-b-#{$i} {
          padding-bottom: #{$i}px;
        }
  
        .lg\:padding-l-#{$i} {
          padding-left: #{$i}px;
        }
  
        .lg\:padding-r-#{$i} {
          padding-right: #{$i}px;
        }
  
        .lg\:padding-lr-#{$i} {
          padding-left: #{$i}px;
          padding-right: #{$i}px;
        }
  
        .lg\:padding-tb-#{$i} {
          padding-top: #{$i}px;
          padding-bottom: #{$i}px;
        }
  
        .lg\:padding-tl-#{$i} {
          padding-top: #{$i}px;
          padding-left: #{$i}px;
        }
  
        .lg\:padding-tr-#{$i} {
          padding-top: #{$i}px;
          padding-right: #{$i}px;
        }
  
        .lg\:padding-bl-#{$i} {
          padding-bottom: #{$i}px;
          padding-left: #{$i}px;
        }
  
        .lg\:padding-br-#{$i} {
          padding-bottom: #{$i}px;
          padding-right: #{$i}px;
        }
      }
    }
}

@media (min-width: 1280px) {
  @for $i from 5 through 150 {
      @if $i % 5==0 {
        .xl\:padding-#{$i} {
          padding: #{$i}px;
        }
  
        .xl\:padding-t-#{$i} {
          padding-top: #{$i}px;
        }
  
        .xl\:padding-b-#{$i} {
          padding-bottom: #{$i}px;
        }
  
        .xl\:padding-l-#{$i} {
          padding-left: #{$i}px;
        }
  
        .xl\:padding-r-#{$i} {
          padding-right: #{$i}px;
        }
  
        .xl\:padding-lr-#{$i} {
          padding-left: #{$i}px;
          padding-right: #{$i}px;
        }
  
        .xl\:padding-tb-#{$i} {
          padding-top: #{$i}px;
          padding-bottom: #{$i}px;
        }
  
        .xl\:padding-tl-#{$i} {
          padding-top: #{$i}px;
          padding-left: #{$i}px;
        }
  
        .xl\:padding-tr-#{$i} {
          padding-top: #{$i}px;
          padding-right: #{$i}px;
        }
  
        .xl\:padding-bl-#{$i} {
          padding-bottom: #{$i}px;
          padding-left: #{$i}px;
        }
  
        .xl\:padding-br-#{$i} {
          padding-bottom: #{$i}px;
          padding-right: #{$i}px;
        }
      }
    }
}

@media (min-width: 1536px) {
  @for $i from 5 through 150 {
      @if $i % 5==0 {
        .\32xl\:padding-#{$i} {
          padding: #{$i}px;
        }
  
        .\32xl\:padding-t-#{$i} {
          padding-top: #{$i}px;
        }
  
        .\32xl\:padding-b-#{$i} {
          padding-bottom: #{$i}px;
        }
  
        .\32xl\:padding-l-#{$i} {
          padding-left: #{$i}px;
        }
  
        .\32xl\:padding-r-#{$i} {
          padding-right: #{$i}px;
        }
  
        .\32xl\:padding-lr-#{$i} {
          padding-left: #{$i}px;
          padding-right: #{$i}px;
        }
  
        .\32xl\:padding-tb-#{$i} {
          padding-top: #{$i}px;
          padding-bottom: #{$i}px;
        }
  
        .\32xl\:padding-tl-#{$i} {
          padding-top: #{$i}px;
          padding-left: #{$i}px;
        }
  
        .\32xl\:padding-tr-#{$i} {
          padding-top: #{$i}px;
          padding-right: #{$i}px;
        }
  
        .\32xl\:padding-bl-#{$i} {
          padding-bottom: #{$i}px;
          padding-left: #{$i}px;
        }
  
        .\32xl\:padding-br-#{$i} {
          padding-bottom: #{$i}px;
          padding-right: #{$i}px;
        }
      }
    }
}