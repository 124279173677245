.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-30 {
  z-index: 30
}

.z-40 {
  z-index: 40
}

.z-50 {
  z-index: 50
}

.z-auto {
  z-index: auto
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.inline-flex {
  display: inline-flex
}

.table {
  display: table
}

.inline-table {
  display: inline-table
}

.table-caption {
  display: table-caption
}

.table-cell {
  display: table-cell
}

.table-column {
  display: table-column
}

.table-column-group {
  display: table-column-group
}

.table-footer-group {
  display: table-footer-group
}

.table-header-group {
  display: table-header-group
}

.table-row-group {
  display: table-row-group
}

.table-row {
  display: table-row
}

.flow-root {
  display: flow-root
}

.inline-grid {
  display: inline-grid
}

.contents {
  display: contents
}

.list-item {
  display: list-item
}

.hidden {
  display: none
}

@media (min-width: 640px) {
  .sm\:static {
    position: static
  }

  .sm\:fixed {
    position: fixed
  }

  .sm\:absolute {
    position: absolute
  }

  .sm\:relative {
    position: relative
  }

  .sm\:sticky {
    position: sticky
  }

  .sm\:z-0 {
    z-index: 0
  }

  .sm\:z-10 {
    z-index: 10
  }

  .sm\:z-20 {
    z-index: 20
  }

  .sm\:z-30 {
    z-index: 30
  }

  .sm\:z-40 {
    z-index: 40
  }

  .sm\:z-50 {
    z-index: 50
  }

  .sm\:z-auto {
    z-index: auto
  }

  .sm\:block {
    display: block
  }

  .sm\:inline-block {
    display: inline-block
  }

  .sm\:inline {
    display: inline
  }

  .sm\:flex {
    display: flex
  }

  .sm\:inline-flex {
    display: inline-flex
  }

  .sm\:table {
    display: table
  }

  .sm\:inline-table {
    display: inline-table
  }

  .sm\:table-caption {
    display: table-caption
  }

  .sm\:table-cell {
    display: table-cell
  }

  .sm\:table-column {
    display: table-column
  }

  .sm\:table-column-group {
    display: table-column-group
  }

  .sm\:table-footer-group {
    display: table-footer-group
  }

  .sm\:table-header-group {
    display: table-header-group
  }

  .sm\:table-row-group {
    display: table-row-group
  }

  .sm\:table-row {
    display: table-row
  }

  .sm\:flow-root {
    display: flow-root
  }

  .sm\:grid {
    display: grid
  }

  .sm\:inline-grid {
    display: inline-grid
  }

  .sm\:contents {
    display: contents
  }

  .sm\:list-item {
    display: list-item
  }

  .sm\:hidden {
    display: none
  }
}

@media (min-width: 768px) {
  .md\:static {
    position: static
  }

  .md\:fixed {
    position: fixed
  }

  .md\:absolute {
    position: absolute
  }

  .md\:relative {
    position: relative
  }

  .md\:sticky {
    position: sticky
  }

  .md\:z-0 {
    z-index: 0
  }

  .md\:z-10 {
    z-index: 10
  }

  .md\:z-20 {
    z-index: 20
  }

  .md\:z-30 {
    z-index: 30
  }

  .md\:z-40 {
    z-index: 40
  }

  .md\:z-50 {
    z-index: 50
  }

  .md\:z-auto {
    z-index: auto
  }

  .md\:block {
    display: block
  }

  .md\:inline-block {
    display: inline-block
  }

  .md\:inline {
    display: inline
  }

  .md\:flex {
    display: flex
  }

  .md\:inline-flex {
    display: inline-flex
  }

  .md\:table {
    display: table
  }

  .md\:inline-table {
    display: inline-table
  }

  .md\:table-caption {
    display: table-caption
  }

  .md\:table-cell {
    display: table-cell
  }

  .md\:table-column {
    display: table-column
  }

  .md\:table-column-group {
    display: table-column-group
  }

  .md\:table-footer-group {
    display: table-footer-group
  }

  .md\:table-header-group {
    display: table-header-group
  }

  .md\:table-row-group {
    display: table-row-group
  }

  .md\:table-row {
    display: table-row
  }

  .md\:flow-root {
    display: flow-root
  }

  .md\:grid {
    display: grid
  }

  .md\:inline-grid {
    display: inline-grid
  }

  .md\:contents {
    display: contents
  }

  .md\:list-item {
    display: list-item
  }

  .md\:hidden {
    display: none
  }
}

@media (min-width: 1024px) {
  .lg\:static {
    position: static
  }

  .lg\:fixed {
    position: fixed
  }

  .lg\:absolute {
    position: absolute
  }

  .lg\:relative {
    position: relative
  }

  .lg\:sticky {
    position: sticky
  }

  .lg\:z-0 {
    z-index: 0
  }

  .lg\:z-10 {
    z-index: 10
  }

  .lg\:z-20 {
    z-index: 20
  }

  .lg\:z-30 {
    z-index: 30
  }

  .lg\:z-40 {
    z-index: 40
  }

  .lg\:z-50 {
    z-index: 50
  }

  .lg\:z-auto {
    z-index: auto
  }

  .lg\:block {
    display: block
  }

  .lg\:inline-block {
    display: inline-block
  }

  .lg\:inline {
    display: inline
  }

  .lg\:flex {
    display: flex
  }

  .lg\:inline-flex {
    display: inline-flex
  }

  .lg\:table {
    display: table
  }

  .lg\:inline-table {
    display: inline-table
  }

  .lg\:table-caption {
    display: table-caption
  }

  .lg\:table-cell {
    display: table-cell
  }

  .lg\:table-column {
    display: table-column
  }

  .lg\:table-column-group {
    display: table-column-group
  }

  .lg\:table-footer-group {
    display: table-footer-group
  }

  .lg\:table-header-group {
    display: table-header-group
  }

  .lg\:table-row-group {
    display: table-row-group
  }

  .lg\:table-row {
    display: table-row
  }

  .lg\:flow-root {
    display: flow-root
  }

  .lg\:grid {
    display: grid
  }

  .lg\:inline-grid {
    display: inline-grid
  }

  .lg\:contents {
    display: contents
  }

  .lg\:list-item {
    display: list-item
  }

  .lg\:hidden {
    display: none
  }
}

@media (min-width: 1280px) {
  .xl\:static {
    position: static
  }

  .xl\:fixed {
    position: fixed
  }

  .xl\:absolute {
    position: absolute
  }

  .xl\:relative {
    position: relative
  }

  .xl\:sticky {
    position: sticky
  }

  .xl\:z-0 {
    z-index: 0
  }

  .xl\:z-10 {
    z-index: 10
  }

  .xl\:z-20 {
    z-index: 20
  }

  .xl\:z-30 {
    z-index: 30
  }

  .xl\:z-40 {
    z-index: 40
  }

  .xl\:z-50 {
    z-index: 50
  }

  .xl\:z-auto {
    z-index: auto
  }

  .xl\:block {
    display: block
  }

  .xl\:inline-block {
    display: inline-block
  }

  .xl\:inline {
    display: inline
  }

  .xl\:flex {
    display: flex
  }

  .xl\:inline-flex {
    display: inline-flex
  }

  .xl\:table {
    display: table
  }

  .xl\:inline-table {
    display: inline-table
  }

  .xl\:table-caption {
    display: table-caption
  }

  .xl\:table-cell {
    display: table-cell
  }

  .xl\:table-column {
    display: table-column
  }

  .xl\:table-column-group {
    display: table-column-group
  }

  .xl\:table-footer-group {
    display: table-footer-group
  }

  .xl\:table-header-group {
    display: table-header-group
  }

  .xl\:table-row-group {
    display: table-row-group
  }

  .xl\:table-row {
    display: table-row
  }

  .xl\:flow-root {
    display: flow-root
  }

  .xl\:grid {
    display: grid
  }

  .xl\:inline-grid {
    display: inline-grid
  }

  .xl\:contents {
    display: contents
  }

  .xl\:list-item {
    display: list-item
  }

  .xl\:hidden {
    display: none
  }
}

@media (min-width: 1536px) {
  .\32xl\:static {
    position: static
  }

  .\32xl\:fixed {
    position: fixed
  }

  .\32xl\:absolute {
    position: absolute
  }

  .\32xl\:relative {
    position: relative
  }

  .\32xl\:sticky {
    position: sticky
  }

  .\32xl\:z-0 {
    z-index: 0
  }

  .\32xl\:z-10 {
    z-index: 10
  }

  .\32xl\:z-20 {
    z-index: 20
  }

  .\32xl\:z-30 {
    z-index: 30
  }

  .\32xl\:z-40 {
    z-index: 40
  }

  .\32xl\:z-50 {
    z-index: 50
  }

  .\32xl\:z-auto {
    z-index: auto
  }

  .\32xl\:block {
    display: block
  }

  .\32xl\:inline-block {
    display: inline-block
  }

  .\32xl\:inline {
    display: inline
  }

  .\32xl\:flex {
    display: flex
  }

  .\32xl\:inline-flex {
    display: inline-flex
  }

  .\32xl\:table {
    display: table
  }

  .\32xl\:inline-table {
    display: inline-table
  }

  .\32xl\:table-caption {
    display: table-caption
  }

  .\32xl\:table-cell {
    display: table-cell
  }

  .\32xl\:table-column {
    display: table-column
  }

  .\32xl\:table-column-group {
    display: table-column-group
  }

  .\32xl\:table-footer-group {
    display: table-footer-group
  }

  .\32xl\:table-header-group {
    display: table-header-group
  }

  .\32xl\:table-row-group {
    display: table-row-group
  }

  .\32xl\:table-row {
    display: table-row
  }

  .\32xl\:flow-root {
    display: flow-root
  }

  .\32xl\:grid {
    display: grid
  }

  .\32xl\:inline-grid {
    display: inline-grid
  }

  .\32xl\:contents {
    display: contents
  }

  .\32xl\:list-item {
    display: list-item
  }

  .\32xl\:hidden {
    display: none
  }
}