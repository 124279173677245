/* You can add global styles to this file, and also import other style files */

@import "sass/unitec-default-theme";
@import "sass/helpers/main";
@import "sass/student-requests/styles";
@import "sass/chatbot";

* {
	font-family: 'Raleway', sans-serif;
	box-sizing: border-box;
}

body {
	margin: 0;
}


/* 

::-webkit-scrollbar {
	width: 7px;
}




::-webkit-scrollbar-track {}




::-webkit-scrollbar-thumb {
	background: rgba($unitec-main-gray, 0.8);
	border-radius: 10px;
}
*/
.container {
	min-height: 100vh;
	;
}

table {
	.avatar {
		width: 30px;
		height: 30px;
	}
}

.md-icon {
	font-size: 30px;
}

.lg-icon {
	font-size: 50px;
}

.xl-icon {
	font-size: 100px;
}

.bg-white {
	background: white;
}

.mat-input-element {
	height: 100%;
}

.label-dynamic-input-data .mat-radio-label-content,
.label-dynamic-input-data .mat-slide-toggle-content {
	font-family: inherit;
	white-space: normal;
	font-weight: inherit;
	font-size: inherit;
	line-height: inherit;
}

.mat-slide-toggle {
	height: auto;
	min-width: 24px;
}

#outerContainer .hiddenMediumView {
	display: block !important;
}

#outerContainer .download.hiddenMediumView,
#outerContainer .print.hiddenMediumView {
	visibility: hidden !important;
}

#outerContainer .download.hiddenMediumView.showBtn,
#outerContainer .print.hiddenMediumView.showBtn {
	visibility: visible !important;
}

#outerContainer #mainContainer {
	min-width: 280px !important;
}

.fill-layout-nf {
	height: 100%;
	min-height: 100%;
	width: 100%;
	flex-direction: row-reverse;
	box-sizing: border-box;
}

.mat-flat-button.mat-bluemoon,
.mat-raised-button.mat-bluemoon,
.mat-fab.mat-bluemoon,
.mat-mini-fab.mat-bluemoon {
	background-color: #244b7f;
	color: #fff;
}

.app-spacer {
	flex: 1 1 auto;
}

::ng-deep button#openFile {
	display: none !important;
	visibility: hidden !important;
}

.clamp-message {
	font-size: 14px;
	color: #951414;
	font-weight: bold;
	text-align: justify;
}

.schedule-group-dates {
	font-size: 12px;
	font-weight: 500;
}

.overflow-container {
    height: auto;
    overflow: auto;
}
.pointer {
	cursor: pointer;
}

.border {
	border-width: 1px;
}

.lowercase {
	text-transform: lowercase;
}

.italic {
	font-style: italic;
}

.outline {
	outline-style: solid;
}

.filter {
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
