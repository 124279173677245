.gap-0 {
  gap: 0px
}

.gap-px {
  gap: 1px
}

.gap-0\.5 {
  gap: 0.125rem
}

.gap-1 {
  gap: 0.25rem
}

.gap-1\.5 {
  gap: 0.375rem
}

.gap-2 {
  gap: 0.5rem
}

.gap-2\.5 {
  gap: 0.625rem
}

.gap-3 {
  gap: 0.75rem
}

.gap-3\.5 {
  gap: 0.875rem
}

.gap-4 {
  gap: 1rem
}

.gap-5 {
  gap: 1.25rem
}

.gap-6 {
  gap: 1.5rem
}

.gap-7 {
  gap: 1.75rem
}

.gap-8 {
  gap: 2rem
}

.gap-9 {
  gap: 2.25rem
}

.gap-10 {
  gap: 2.5rem
}

.gap-11 {
  gap: 2.75rem
}

.gap-12 {
  gap: 3rem
}

.gap-14 {
  gap: 3.5rem
}

.gap-16 {
  gap: 4rem
}

.gap-20 {
  gap: 5rem
}

.gap-24 {
  gap: 6rem
}

.gap-28 {
  gap: 7rem
}

.gap-32 {
  gap: 8rem
}

.gap-36 {
  gap: 9rem
}

.gap-40 {
  gap: 10rem
}

.gap-44 {
  gap: 11rem
}

.gap-48 {
  gap: 12rem
}

.gap-52 {
  gap: 13rem
}

.gap-56 {
  gap: 14rem
}

.gap-60 {
  gap: 15rem
}

.gap-64 {
  gap: 16rem
}

.gap-72 {
  gap: 18rem
}

.gap-80 {
  gap: 20rem
}

.gap-96 {
  gap: 24rem
}

.gap-x-0 {
  column-gap: 0px
}

.gap-y-0 {
  row-gap: 0px
}

.gap-x-px {
  column-gap: 1px
}

.gap-y-px {
  row-gap: 1px
}

.gap-x-0\.5 {
  column-gap: 0.125rem
}

.gap-y-0\.5 {
  row-gap: 0.125rem
}

.gap-x-1 {
  column-gap: 0.25rem
}

.gap-y-1 {
  row-gap: 0.25rem
}

.gap-x-1\.5 {
  column-gap: 0.375rem
}

.gap-y-1\.5 {
  row-gap: 0.375rem
}

.gap-x-2 {
  column-gap: 0.5rem
}

.gap-y-2 {
  row-gap: 0.5rem
}

.gap-x-2\.5 {
  column-gap: 0.625rem
}

.gap-y-2\.5 {
  row-gap: 0.625rem
}

.gap-x-3 {
  column-gap: 0.75rem
}

.gap-y-3 {
  row-gap: 0.75rem
}

.gap-x-3\.5 {
  column-gap: 0.875rem
}

.gap-y-3\.5 {
  row-gap: 0.875rem
}

.gap-x-4 {
  column-gap: 1rem
}

.gap-y-4 {
  row-gap: 1rem
}

.gap-x-5 {
  column-gap: 1.25rem
}

.gap-y-5 {
  row-gap: 1.25rem
}

.gap-x-6 {
  column-gap: 1.5rem
}

.gap-y-6 {
  row-gap: 1.5rem
}

.gap-x-7 {
  column-gap: 1.75rem
}

.gap-y-7 {
  row-gap: 1.75rem
}

.gap-x-8 {
  column-gap: 2rem
}

.gap-y-8 {
  row-gap: 2rem
}

.gap-x-9 {
  column-gap: 2.25rem
}

.gap-y-9 {
  row-gap: 2.25rem
}

.gap-x-10 {
  column-gap: 2.5rem
}

.gap-y-10 {
  row-gap: 2.5rem
}

.gap-x-11 {
  column-gap: 2.75rem
}

.gap-y-11 {
  row-gap: 2.75rem
}

.gap-x-12 {
  column-gap: 3rem
}

.gap-y-12 {
  row-gap: 3rem
}

.gap-x-14 {
  column-gap: 3.5rem
}

.gap-y-14 {
  row-gap: 3.5rem
}

.gap-x-16 {
  column-gap: 4rem
}

.gap-y-16 {
  row-gap: 4rem
}

.gap-x-20 {
  column-gap: 5rem
}

.gap-y-20 {
  row-gap: 5rem
}

.gap-x-24 {
  column-gap: 6rem
}

.gap-y-24 {
  row-gap: 6rem
}

.gap-x-28 {
  column-gap: 7rem
}

.gap-y-28 {
  row-gap: 7rem
}

.gap-x-32 {
  column-gap: 8rem
}

.gap-y-32 {
  row-gap: 8rem
}

.gap-x-36 {
  column-gap: 9rem
}

.gap-y-36 {
  row-gap: 9rem
}

.gap-x-40 {
  column-gap: 10rem
}

.gap-y-40 {
  row-gap: 10rem
}

.gap-x-44 {
  column-gap: 11rem
}

.gap-y-44 {
  row-gap: 11rem
}

.gap-x-48 {
  column-gap: 12rem
}

.gap-y-48 {
  row-gap: 12rem
}

.gap-x-52 {
  column-gap: 13rem
}

.gap-y-52 {
  row-gap: 13rem
}

.gap-x-56 {
  column-gap: 14rem
}

.gap-y-56 {
  row-gap: 14rem
}

.gap-x-60 {
  column-gap: 15rem
}

.gap-y-60 {
  row-gap: 15rem
}

.gap-x-64 {
  column-gap: 16rem
}

.gap-y-64 {
  row-gap: 16rem
}

.gap-x-72 {
  column-gap: 18rem
}

.gap-y-72 {
  row-gap: 18rem
}

.gap-x-80 {
  column-gap: 20rem
}

.gap-y-80 {
  row-gap: 20rem
}

.gap-x-96 {
  column-gap: 24rem
}

.gap-y-96 {
  row-gap: 24rem
}

@media (min-width: 640px) {
  .sm\:gap-0 {
    gap: 0px
  }

  .sm\:gap-px {
    gap: 1px
  }

  .sm\:gap-0\.5 {
    gap: 0.125rem
  }

  .sm\:gap-1 {
    gap: 0.25rem
  }

  .sm\:gap-1\.5 {
    gap: 0.375rem
  }

  .sm\:gap-2 {
    gap: 0.5rem
  }

  .sm\:gap-2\.5 {
    gap: 0.625rem
  }

  .sm\:gap-3 {
    gap: 0.75rem
  }

  .sm\:gap-3\.5 {
    gap: 0.875rem
  }

  .sm\:gap-4 {
    gap: 1rem
  }

  .sm\:gap-5 {
    gap: 1.25rem
  }

  .sm\:gap-6 {
    gap: 1.5rem
  }

  .sm\:gap-7 {
    gap: 1.75rem
  }

  .sm\:gap-8 {
    gap: 2rem
  }

  .sm\:gap-9 {
    gap: 2.25rem
  }

  .sm\:gap-10 {
    gap: 2.5rem
  }

  .sm\:gap-11 {
    gap: 2.75rem
  }

  .sm\:gap-12 {
    gap: 3rem
  }

  .sm\:gap-14 {
    gap: 3.5rem
  }

  .sm\:gap-16 {
    gap: 4rem
  }

  .sm\:gap-20 {
    gap: 5rem
  }

  .sm\:gap-24 {
    gap: 6rem
  }

  .sm\:gap-28 {
    gap: 7rem
  }

  .sm\:gap-32 {
    gap: 8rem
  }

  .sm\:gap-36 {
    gap: 9rem
  }

  .sm\:gap-40 {
    gap: 10rem
  }

  .sm\:gap-44 {
    gap: 11rem
  }

  .sm\:gap-48 {
    gap: 12rem
  }

  .sm\:gap-52 {
    gap: 13rem
  }

  .sm\:gap-56 {
    gap: 14rem
  }

  .sm\:gap-60 {
    gap: 15rem
  }

  .sm\:gap-64 {
    gap: 16rem
  }

  .sm\:gap-72 {
    gap: 18rem
  }

  .sm\:gap-80 {
    gap: 20rem
  }

  .sm\:gap-96 {
    gap: 24rem
  }

  .sm\:gap-x-0 {
    column-gap: 0px
  }

  .sm\:gap-y-0 {
    row-gap: 0px
  }

  .sm\:gap-x-px {
    column-gap: 1px
  }

  .sm\:gap-y-px {
    row-gap: 1px
  }

  .sm\:gap-x-0\.5 {
    column-gap: 0.125rem
  }

  .sm\:gap-y-0\.5 {
    row-gap: 0.125rem
  }

  .sm\:gap-x-1 {
    column-gap: 0.25rem
  }

  .sm\:gap-y-1 {
    row-gap: 0.25rem
  }

  .sm\:gap-x-1\.5 {
    column-gap: 0.375rem
  }

  .sm\:gap-y-1\.5 {
    row-gap: 0.375rem
  }

  .sm\:gap-x-2 {
    column-gap: 0.5rem
  }

  .sm\:gap-y-2 {
    row-gap: 0.5rem
  }

  .sm\:gap-x-2\.5 {
    column-gap: 0.625rem
  }

  .sm\:gap-y-2\.5 {
    row-gap: 0.625rem
  }

  .sm\:gap-x-3 {
    column-gap: 0.75rem
  }

  .sm\:gap-y-3 {
    row-gap: 0.75rem
  }

  .sm\:gap-x-3\.5 {
    column-gap: 0.875rem
  }

  .sm\:gap-y-3\.5 {
    row-gap: 0.875rem
  }

  .sm\:gap-x-4 {
    column-gap: 1rem
  }

  .sm\:gap-y-4 {
    row-gap: 1rem
  }

  .sm\:gap-x-5 {
    column-gap: 1.25rem
  }

  .sm\:gap-y-5 {
    row-gap: 1.25rem
  }

  .sm\:gap-x-6 {
    column-gap: 1.5rem
  }

  .sm\:gap-y-6 {
    row-gap: 1.5rem
  }

  .sm\:gap-x-7 {
    column-gap: 1.75rem
  }

  .sm\:gap-y-7 {
    row-gap: 1.75rem
  }

  .sm\:gap-x-8 {
    column-gap: 2rem
  }

  .sm\:gap-y-8 {
    row-gap: 2rem
  }

  .sm\:gap-x-9 {
    column-gap: 2.25rem
  }

  .sm\:gap-y-9 {
    row-gap: 2.25rem
  }

  .sm\:gap-x-10 {
    column-gap: 2.5rem
  }

  .sm\:gap-y-10 {
    row-gap: 2.5rem
  }

  .sm\:gap-x-11 {
    column-gap: 2.75rem
  }

  .sm\:gap-y-11 {
    row-gap: 2.75rem
  }

  .sm\:gap-x-12 {
    column-gap: 3rem
  }

  .sm\:gap-y-12 {
    row-gap: 3rem
  }

  .sm\:gap-x-14 {
    column-gap: 3.5rem
  }

  .sm\:gap-y-14 {
    row-gap: 3.5rem
  }

  .sm\:gap-x-16 {
    column-gap: 4rem
  }

  .sm\:gap-y-16 {
    row-gap: 4rem
  }

  .sm\:gap-x-20 {
    column-gap: 5rem
  }

  .sm\:gap-y-20 {
    row-gap: 5rem
  }

  .sm\:gap-x-24 {
    column-gap: 6rem
  }

  .sm\:gap-y-24 {
    row-gap: 6rem
  }

  .sm\:gap-x-28 {
    column-gap: 7rem
  }

  .sm\:gap-y-28 {
    row-gap: 7rem
  }

  .sm\:gap-x-32 {
    column-gap: 8rem
  }

  .sm\:gap-y-32 {
    row-gap: 8rem
  }

  .sm\:gap-x-36 {
    column-gap: 9rem
  }

  .sm\:gap-y-36 {
    row-gap: 9rem
  }

  .sm\:gap-x-40 {
    column-gap: 10rem
  }

  .sm\:gap-y-40 {
    row-gap: 10rem
  }

  .sm\:gap-x-44 {
    column-gap: 11rem
  }

  .sm\:gap-y-44 {
    row-gap: 11rem
  }

  .sm\:gap-x-48 {
    column-gap: 12rem
  }

  .sm\:gap-y-48 {
    row-gap: 12rem
  }

  .sm\:gap-x-52 {
    column-gap: 13rem
  }

  .sm\:gap-y-52 {
    row-gap: 13rem
  }

  .sm\:gap-x-56 {
    column-gap: 14rem
  }

  .sm\:gap-y-56 {
    row-gap: 14rem
  }

  .sm\:gap-x-60 {
    column-gap: 15rem
  }

  .sm\:gap-y-60 {
    row-gap: 15rem
  }

  .sm\:gap-x-64 {
    column-gap: 16rem
  }

  .sm\:gap-y-64 {
    row-gap: 16rem
  }

  .sm\:gap-x-72 {
    column-gap: 18rem
  }

  .sm\:gap-y-72 {
    row-gap: 18rem
  }

  .sm\:gap-x-80 {
    column-gap: 20rem
  }

  .sm\:gap-y-80 {
    row-gap: 20rem
  }

  .sm\:gap-x-96 {
    column-gap: 24rem
  }

  .sm\:gap-y-96 {
    row-gap: 24rem
  }

  .sm\:gap-\[0px\] {
    gap: 0px;
  }

  .sm\:gap-\[5px\] {
    gap: 5px;
  }

  .sm\:gap-\[10px\] {
    gap: 10px;
  }

  .sm\:gap-\[15px\] {
    gap: 15px;
  }

  .sm\:gap-\[20px\] {
    gap: 20px;
  }

  .sm\:gap-\[25px\] {
    gap: 25px;
  }

  .sm\:gap-\[30px\] {
    gap: 30px;
  }

  .sm\:gap-\[35px\] {
    gap: 35px;
  }

  .sm\:gap-\[40px\] {
    gap: 40px;
  }

  .sm\:gap-\[45px\] {
    gap: 45px;
  }

  .sm\:gap-\[50px\] {
    gap: 50px;
  }

  .sm\:gap-\[55px\] {
    gap: 55px;
  }

  .sm\:gap-\[60px\] {
    gap: 60px;
  }

  .sm\:gap-\[65px\] {
    gap: 65px;
  }

  .sm\:gap-\[70px\] {
    gap: 70px;
  }

  .sm\:gap-\[75px\] {
    gap: 75px;
  }

  .sm\:gap-\[80px\] {
    gap: 80px;
  }

  .sm\:gap-\[85px\] {
    gap: 85px;
  }

  .sm\:gap-\[90px\] {
    gap: 90px;
  }

  .sm\:gap-\[95px\] {
    gap: 95px;
  }

  .sm\:gap-\[100px\] {
    gap: 100px;
  }
}

@media (min-width: 768px) {
  .md\:gap-0 {
    gap: 0px
  }

  .md\:gap-px {
    gap: 1px
  }

  .md\:gap-0\.5 {
    gap: 0.125rem
  }

  .md\:gap-1 {
    gap: 0.25rem
  }

  .md\:gap-1\.5 {
    gap: 0.375rem
  }

  .md\:gap-2 {
    gap: 0.5rem
  }

  .md\:gap-2\.5 {
    gap: 0.625rem
  }

  .md\:gap-3 {
    gap: 0.75rem
  }

  .md\:gap-3\.5 {
    gap: 0.875rem
  }

  .md\:gap-4 {
    gap: 1rem
  }

  .md\:gap-5 {
    gap: 1.25rem
  }

  .md\:gap-6 {
    gap: 1.5rem
  }

  .md\:gap-7 {
    gap: 1.75rem
  }

  .md\:gap-8 {
    gap: 2rem
  }

  .md\:gap-9 {
    gap: 2.25rem
  }

  .md\:gap-10 {
    gap: 2.5rem
  }

  .md\:gap-11 {
    gap: 2.75rem
  }

  .md\:gap-12 {
    gap: 3rem
  }

  .md\:gap-14 {
    gap: 3.5rem
  }

  .md\:gap-16 {
    gap: 4rem
  }

  .md\:gap-20 {
    gap: 5rem
  }

  .md\:gap-24 {
    gap: 6rem
  }

  .md\:gap-28 {
    gap: 7rem
  }

  .md\:gap-32 {
    gap: 8rem
  }

  .md\:gap-36 {
    gap: 9rem
  }

  .md\:gap-40 {
    gap: 10rem
  }

  .md\:gap-44 {
    gap: 11rem
  }

  .md\:gap-48 {
    gap: 12rem
  }

  .md\:gap-52 {
    gap: 13rem
  }

  .md\:gap-56 {
    gap: 14rem
  }

  .md\:gap-60 {
    gap: 15rem
  }

  .md\:gap-64 {
    gap: 16rem
  }

  .md\:gap-72 {
    gap: 18rem
  }

  .md\:gap-80 {
    gap: 20rem
  }

  .md\:gap-96 {
    gap: 24rem
  }

  .md\:gap-x-0 {
    column-gap: 0px
  }

  .md\:gap-y-0 {
    row-gap: 0px
  }

  .md\:gap-x-px {
    column-gap: 1px
  }

  .md\:gap-y-px {
    row-gap: 1px
  }

  .md\:gap-x-0\.5 {
    column-gap: 0.125rem
  }

  .md\:gap-y-0\.5 {
    row-gap: 0.125rem
  }

  .md\:gap-x-1 {
    column-gap: 0.25rem
  }

  .md\:gap-y-1 {
    row-gap: 0.25rem
  }

  .md\:gap-x-1\.5 {
    column-gap: 0.375rem
  }

  .md\:gap-y-1\.5 {
    row-gap: 0.375rem
  }

  .md\:gap-x-2 {
    column-gap: 0.5rem
  }

  .md\:gap-y-2 {
    row-gap: 0.5rem
  }

  .md\:gap-x-2\.5 {
    column-gap: 0.625rem
  }

  .md\:gap-y-2\.5 {
    row-gap: 0.625rem
  }

  .md\:gap-x-3 {
    column-gap: 0.75rem
  }

  .md\:gap-y-3 {
    row-gap: 0.75rem
  }

  .md\:gap-x-3\.5 {
    column-gap: 0.875rem
  }

  .md\:gap-y-3\.5 {
    row-gap: 0.875rem
  }

  .md\:gap-x-4 {
    column-gap: 1rem
  }

  .md\:gap-y-4 {
    row-gap: 1rem
  }

  .md\:gap-x-5 {
    column-gap: 1.25rem
  }

  .md\:gap-y-5 {
    row-gap: 1.25rem
  }

  .md\:gap-x-6 {
    column-gap: 1.5rem
  }

  .md\:gap-y-6 {
    row-gap: 1.5rem
  }

  .md\:gap-x-7 {
    column-gap: 1.75rem
  }

  .md\:gap-y-7 {
    row-gap: 1.75rem
  }

  .md\:gap-x-8 {
    column-gap: 2rem
  }

  .md\:gap-y-8 {
    row-gap: 2rem
  }

  .md\:gap-x-9 {
    column-gap: 2.25rem
  }

  .md\:gap-y-9 {
    row-gap: 2.25rem
  }

  .md\:gap-x-10 {
    column-gap: 2.5rem
  }

  .md\:gap-y-10 {
    row-gap: 2.5rem
  }

  .md\:gap-x-11 {
    column-gap: 2.75rem
  }

  .md\:gap-y-11 {
    row-gap: 2.75rem
  }

  .md\:gap-x-12 {
    column-gap: 3rem
  }

  .md\:gap-y-12 {
    row-gap: 3rem
  }

  .md\:gap-x-14 {
    column-gap: 3.5rem
  }

  .md\:gap-y-14 {
    row-gap: 3.5rem
  }

  .md\:gap-x-16 {
    column-gap: 4rem
  }

  .md\:gap-y-16 {
    row-gap: 4rem
  }

  .md\:gap-x-20 {
    column-gap: 5rem
  }

  .md\:gap-y-20 {
    row-gap: 5rem
  }

  .md\:gap-x-24 {
    column-gap: 6rem
  }

  .md\:gap-y-24 {
    row-gap: 6rem
  }

  .md\:gap-x-28 {
    column-gap: 7rem
  }

  .md\:gap-y-28 {
    row-gap: 7rem
  }

  .md\:gap-x-32 {
    column-gap: 8rem
  }

  .md\:gap-y-32 {
    row-gap: 8rem
  }

  .md\:gap-x-36 {
    column-gap: 9rem
  }

  .md\:gap-y-36 {
    row-gap: 9rem
  }

  .md\:gap-x-40 {
    column-gap: 10rem
  }

  .md\:gap-y-40 {
    row-gap: 10rem
  }

  .md\:gap-x-44 {
    column-gap: 11rem
  }

  .md\:gap-y-44 {
    row-gap: 11rem
  }

  .md\:gap-x-48 {
    column-gap: 12rem
  }

  .md\:gap-y-48 {
    row-gap: 12rem
  }

  .md\:gap-x-52 {
    column-gap: 13rem
  }

  .md\:gap-y-52 {
    row-gap: 13rem
  }

  .md\:gap-x-56 {
    column-gap: 14rem
  }

  .md\:gap-y-56 {
    row-gap: 14rem
  }

  .md\:gap-x-60 {
    column-gap: 15rem
  }

  .md\:gap-y-60 {
    row-gap: 15rem
  }

  .md\:gap-x-64 {
    column-gap: 16rem
  }

  .md\:gap-y-64 {
    row-gap: 16rem
  }

  .md\:gap-x-72 {
    column-gap: 18rem
  }

  .md\:gap-y-72 {
    row-gap: 18rem
  }

  .md\:gap-x-80 {
    column-gap: 20rem
  }

  .md\:gap-y-80 {
    row-gap: 20rem
  }

  .md\:gap-x-96 {
    column-gap: 24rem
  }

  .md\:gap-y-96 {
    row-gap: 24rem
  }

  .md\:gap-\[0px\] {
    gap: 0px;
  }

  .md\:gap-\[5px\] {
    gap: 5px;
  }

  .md\:gap-\[10px\] {
    gap: 10px;
  }

  .md\:gap-\[15px\] {
    gap: 15px;
  }

  .md\:gap-\[20px\] {
    gap: 20px;
  }

  .md\:gap-\[25px\] {
    gap: 25px;
  }

  .md\:gap-\[30px\] {
    gap: 30px;
  }

  .md\:gap-\[35px\] {
    gap: 35px;
  }

  .md\:gap-\[40px\] {
    gap: 40px;
  }

  .md\:gap-\[45px\] {
    gap: 45px;
  }

  .md\:gap-\[50px\] {
    gap: 50px;
  }

  .md\:gap-\[55px\] {
    gap: 55px;
  }

  .md\:gap-\[60px\] {
    gap: 60px;
  }

  .md\:gap-\[65px\] {
    gap: 65px;
  }

  .md\:gap-\[70px\] {
    gap: 70px;
  }

  .md\:gap-\[75px\] {
    gap: 75px;
  }

  .md\:gap-\[80px\] {
    gap: 80px;
  }

  .md\:gap-\[85px\] {
    gap: 85px;
  }

  .md\:gap-\[90px\] {
    gap: 90px;
  }

  .md\:gap-\[95px\] {
    gap: 95px;
  }

  .md\:gap-\[100px\] {
    gap: 100px;
  }
}

@media (min-width: 1024px) {
  .lg\:gap-0 {
    gap: 0px
  }

  .lg\:gap-px {
    gap: 1px
  }

  .lg\:gap-0\.5 {
    gap: 0.125rem
  }

  .lg\:gap-1 {
    gap: 0.25rem
  }

  .lg\:gap-1\.5 {
    gap: 0.375rem
  }

  .lg\:gap-2 {
    gap: 0.5rem
  }

  .lg\:gap-2\.5 {
    gap: 0.625rem
  }

  .lg\:gap-3 {
    gap: 0.75rem
  }

  .lg\:gap-3\.5 {
    gap: 0.875rem
  }

  .lg\:gap-4 {
    gap: 1rem
  }

  .lg\:gap-5 {
    gap: 1.25rem
  }

  .lg\:gap-6 {
    gap: 1.5rem
  }

  .lg\:gap-7 {
    gap: 1.75rem
  }

  .lg\:gap-8 {
    gap: 2rem
  }

  .lg\:gap-9 {
    gap: 2.25rem
  }

  .lg\:gap-10 {
    gap: 2.5rem
  }

  .lg\:gap-11 {
    gap: 2.75rem
  }

  .lg\:gap-12 {
    gap: 3rem
  }

  .lg\:gap-14 {
    gap: 3.5rem
  }

  .lg\:gap-16 {
    gap: 4rem
  }

  .lg\:gap-20 {
    gap: 5rem
  }

  .lg\:gap-24 {
    gap: 6rem
  }

  .lg\:gap-28 {
    gap: 7rem
  }

  .lg\:gap-32 {
    gap: 8rem
  }

  .lg\:gap-36 {
    gap: 9rem
  }

  .lg\:gap-40 {
    gap: 10rem
  }

  .lg\:gap-44 {
    gap: 11rem
  }

  .lg\:gap-48 {
    gap: 12rem
  }

  .lg\:gap-52 {
    gap: 13rem
  }

  .lg\:gap-56 {
    gap: 14rem
  }

  .lg\:gap-60 {
    gap: 15rem
  }

  .lg\:gap-64 {
    gap: 16rem
  }

  .lg\:gap-72 {
    gap: 18rem
  }

  .lg\:gap-80 {
    gap: 20rem
  }

  .lg\:gap-96 {
    gap: 24rem
  }

  .lg\:gap-x-0 {
    column-gap: 0px
  }

  .lg\:gap-y-0 {
    row-gap: 0px
  }

  .lg\:gap-x-px {
    column-gap: 1px
  }

  .lg\:gap-y-px {
    row-gap: 1px
  }

  .lg\:gap-x-0\.5 {
    column-gap: 0.125rem
  }

  .lg\:gap-y-0\.5 {
    row-gap: 0.125rem
  }

  .lg\:gap-x-1 {
    column-gap: 0.25rem
  }

  .lg\:gap-y-1 {
    row-gap: 0.25rem
  }

  .lg\:gap-x-1\.5 {
    column-gap: 0.375rem
  }

  .lg\:gap-y-1\.5 {
    row-gap: 0.375rem
  }

  .lg\:gap-x-2 {
    column-gap: 0.5rem
  }

  .lg\:gap-y-2 {
    row-gap: 0.5rem
  }

  .lg\:gap-x-2\.5 {
    column-gap: 0.625rem
  }

  .lg\:gap-y-2\.5 {
    row-gap: 0.625rem
  }

  .lg\:gap-x-3 {
    column-gap: 0.75rem
  }

  .lg\:gap-y-3 {
    row-gap: 0.75rem
  }

  .lg\:gap-x-3\.5 {
    column-gap: 0.875rem
  }

  .lg\:gap-y-3\.5 {
    row-gap: 0.875rem
  }

  .lg\:gap-x-4 {
    column-gap: 1rem
  }

  .lg\:gap-y-4 {
    row-gap: 1rem
  }

  .lg\:gap-x-5 {
    column-gap: 1.25rem
  }

  .lg\:gap-y-5 {
    row-gap: 1.25rem
  }

  .lg\:gap-x-6 {
    column-gap: 1.5rem
  }

  .lg\:gap-y-6 {
    row-gap: 1.5rem
  }

  .lg\:gap-x-7 {
    column-gap: 1.75rem
  }

  .lg\:gap-y-7 {
    row-gap: 1.75rem
  }

  .lg\:gap-x-8 {
    column-gap: 2rem
  }

  .lg\:gap-y-8 {
    row-gap: 2rem
  }

  .lg\:gap-x-9 {
    column-gap: 2.25rem
  }

  .lg\:gap-y-9 {
    row-gap: 2.25rem
  }

  .lg\:gap-x-10 {
    column-gap: 2.5rem
  }

  .lg\:gap-y-10 {
    row-gap: 2.5rem
  }

  .lg\:gap-x-11 {
    column-gap: 2.75rem
  }

  .lg\:gap-y-11 {
    row-gap: 2.75rem
  }

  .lg\:gap-x-12 {
    column-gap: 3rem
  }

  .lg\:gap-y-12 {
    row-gap: 3rem
  }

  .lg\:gap-x-14 {
    column-gap: 3.5rem
  }

  .lg\:gap-y-14 {
    row-gap: 3.5rem
  }

  .lg\:gap-x-16 {
    column-gap: 4rem
  }

  .lg\:gap-y-16 {
    row-gap: 4rem
  }

  .lg\:gap-x-20 {
    column-gap: 5rem
  }

  .lg\:gap-y-20 {
    row-gap: 5rem
  }

  .lg\:gap-x-24 {
    column-gap: 6rem
  }

  .lg\:gap-y-24 {
    row-gap: 6rem
  }

  .lg\:gap-x-28 {
    column-gap: 7rem
  }

  .lg\:gap-y-28 {
    row-gap: 7rem
  }

  .lg\:gap-x-32 {
    column-gap: 8rem
  }

  .lg\:gap-y-32 {
    row-gap: 8rem
  }

  .lg\:gap-x-36 {
    column-gap: 9rem
  }

  .lg\:gap-y-36 {
    row-gap: 9rem
  }

  .lg\:gap-x-40 {
    column-gap: 10rem
  }

  .lg\:gap-y-40 {
    row-gap: 10rem
  }

  .lg\:gap-x-44 {
    column-gap: 11rem
  }

  .lg\:gap-y-44 {
    row-gap: 11rem
  }

  .lg\:gap-x-48 {
    column-gap: 12rem
  }

  .lg\:gap-y-48 {
    row-gap: 12rem
  }

  .lg\:gap-x-52 {
    column-gap: 13rem
  }

  .lg\:gap-y-52 {
    row-gap: 13rem
  }

  .lg\:gap-x-56 {
    column-gap: 14rem
  }

  .lg\:gap-y-56 {
    row-gap: 14rem
  }

  .lg\:gap-x-60 {
    column-gap: 15rem
  }

  .lg\:gap-y-60 {
    row-gap: 15rem
  }

  .lg\:gap-x-64 {
    column-gap: 16rem
  }

  .lg\:gap-y-64 {
    row-gap: 16rem
  }

  .lg\:gap-x-72 {
    column-gap: 18rem
  }

  .lg\:gap-y-72 {
    row-gap: 18rem
  }

  .lg\:gap-x-80 {
    column-gap: 20rem
  }

  .lg\:gap-y-80 {
    row-gap: 20rem
  }

  .lg\:gap-x-96 {
    column-gap: 24rem
  }

  .lg\:gap-y-96 {
    row-gap: 24rem
  }

  .lg\:gap-\[0px\] {
    gap: 0px;
  }

  .lg\:gap-\[5px\] {
    gap: 5px;
  }

  .lg\:gap-\[10px\] {
    gap: 10px;
  }

  .lg\:gap-\[15px\] {
    gap: 15px;
  }

  .lg\:gap-\[20px\] {
    gap: 20px;
  }

  .lg\:gap-\[25px\] {
    gap: 25px;
  }

  .lg\:gap-\[30px\] {
    gap: 30px;
  }

  .lg\:gap-\[35px\] {
    gap: 35px;
  }

  .lg\:gap-\[40px\] {
    gap: 40px;
  }

  .lg\:gap-\[45px\] {
    gap: 45px;
  }

  .lg\:gap-\[50px\] {
    gap: 50px;
  }

  .lg\:gap-\[55px\] {
    gap: 55px;
  }

  .lg\:gap-\[60px\] {
    gap: 60px;
  }

  .lg\:gap-\[65px\] {
    gap: 65px;
  }

  .lg\:gap-\[70px\] {
    gap: 70px;
  }

  .lg\:gap-\[75px\] {
    gap: 75px;
  }

  .lg\:gap-\[80px\] {
    gap: 80px;
  }

  .lg\:gap-\[85px\] {
    gap: 85px;
  }

  .lg\:gap-\[90px\] {
    gap: 90px;
  }

  .lg\:gap-\[95px\] {
    gap: 95px;
  }

  .lg\:gap-\[100px\] {
    gap: 100px;
  }
}

@media (min-width: 1280px) {
  .xl\:gap-0 {
    gap: 0px
  }

  .xl\:gap-px {
    gap: 1px
  }

  .xl\:gap-0\.5 {
    gap: 0.125rem
  }

  .xl\:gap-1 {
    gap: 0.25rem
  }

  .xl\:gap-1\.5 {
    gap: 0.375rem
  }

  .xl\:gap-2 {
    gap: 0.5rem
  }

  .xl\:gap-2\.5 {
    gap: 0.625rem
  }

  .xl\:gap-3 {
    gap: 0.75rem
  }

  .xl\:gap-3\.5 {
    gap: 0.875rem
  }

  .xl\:gap-4 {
    gap: 1rem
  }

  .xl\:gap-5 {
    gap: 1.25rem
  }

  .xl\:gap-6 {
    gap: 1.5rem
  }

  .xl\:gap-7 {
    gap: 1.75rem
  }

  .xl\:gap-8 {
    gap: 2rem
  }

  .xl\:gap-9 {
    gap: 2.25rem
  }

  .xl\:gap-10 {
    gap: 2.5rem
  }

  .xl\:gap-11 {
    gap: 2.75rem
  }

  .xl\:gap-12 {
    gap: 3rem
  }

  .xl\:gap-14 {
    gap: 3.5rem
  }

  .xl\:gap-16 {
    gap: 4rem
  }

  .xl\:gap-20 {
    gap: 5rem
  }

  .xl\:gap-24 {
    gap: 6rem
  }

  .xl\:gap-28 {
    gap: 7rem
  }

  .xl\:gap-32 {
    gap: 8rem
  }

  .xl\:gap-36 {
    gap: 9rem
  }

  .xl\:gap-40 {
    gap: 10rem
  }

  .xl\:gap-44 {
    gap: 11rem
  }

  .xl\:gap-48 {
    gap: 12rem
  }

  .xl\:gap-52 {
    gap: 13rem
  }

  .xl\:gap-56 {
    gap: 14rem
  }

  .xl\:gap-60 {
    gap: 15rem
  }

  .xl\:gap-64 {
    gap: 16rem
  }

  .xl\:gap-72 {
    gap: 18rem
  }

  .xl\:gap-80 {
    gap: 20rem
  }

  .xl\:gap-96 {
    gap: 24rem
  }

  .xl\:gap-x-0 {
    column-gap: 0px
  }

  .xl\:gap-y-0 {
    row-gap: 0px
  }

  .xl\:gap-x-px {
    column-gap: 1px
  }

  .xl\:gap-y-px {
    row-gap: 1px
  }

  .xl\:gap-x-0\.5 {
    column-gap: 0.125rem
  }

  .xl\:gap-y-0\.5 {
    row-gap: 0.125rem
  }

  .xl\:gap-x-1 {
    column-gap: 0.25rem
  }

  .xl\:gap-y-1 {
    row-gap: 0.25rem
  }

  .xl\:gap-x-1\.5 {
    column-gap: 0.375rem
  }

  .xl\:gap-y-1\.5 {
    row-gap: 0.375rem
  }

  .xl\:gap-x-2 {
    column-gap: 0.5rem
  }

  .xl\:gap-y-2 {
    row-gap: 0.5rem
  }

  .xl\:gap-x-2\.5 {
    column-gap: 0.625rem
  }

  .xl\:gap-y-2\.5 {
    row-gap: 0.625rem
  }

  .xl\:gap-x-3 {
    column-gap: 0.75rem
  }

  .xl\:gap-y-3 {
    row-gap: 0.75rem
  }

  .xl\:gap-x-3\.5 {
    column-gap: 0.875rem
  }

  .xl\:gap-y-3\.5 {
    row-gap: 0.875rem
  }

  .xl\:gap-x-4 {
    column-gap: 1rem
  }

  .xl\:gap-y-4 {
    row-gap: 1rem
  }

  .xl\:gap-x-5 {
    column-gap: 1.25rem
  }

  .xl\:gap-y-5 {
    row-gap: 1.25rem
  }

  .xl\:gap-x-6 {
    column-gap: 1.5rem
  }

  .xl\:gap-y-6 {
    row-gap: 1.5rem
  }

  .xl\:gap-x-7 {
    column-gap: 1.75rem
  }

  .xl\:gap-y-7 {
    row-gap: 1.75rem
  }

  .xl\:gap-x-8 {
    column-gap: 2rem
  }

  .xl\:gap-y-8 {
    row-gap: 2rem
  }

  .xl\:gap-x-9 {
    column-gap: 2.25rem
  }

  .xl\:gap-y-9 {
    row-gap: 2.25rem
  }

  .xl\:gap-x-10 {
    column-gap: 2.5rem
  }

  .xl\:gap-y-10 {
    row-gap: 2.5rem
  }

  .xl\:gap-x-11 {
    column-gap: 2.75rem
  }

  .xl\:gap-y-11 {
    row-gap: 2.75rem
  }

  .xl\:gap-x-12 {
    column-gap: 3rem
  }

  .xl\:gap-y-12 {
    row-gap: 3rem
  }

  .xl\:gap-x-14 {
    column-gap: 3.5rem
  }

  .xl\:gap-y-14 {
    row-gap: 3.5rem
  }

  .xl\:gap-x-16 {
    column-gap: 4rem
  }

  .xl\:gap-y-16 {
    row-gap: 4rem
  }

  .xl\:gap-x-20 {
    column-gap: 5rem
  }

  .xl\:gap-y-20 {
    row-gap: 5rem
  }

  .xl\:gap-x-24 {
    column-gap: 6rem
  }

  .xl\:gap-y-24 {
    row-gap: 6rem
  }

  .xl\:gap-x-28 {
    column-gap: 7rem
  }

  .xl\:gap-y-28 {
    row-gap: 7rem
  }

  .xl\:gap-x-32 {
    column-gap: 8rem
  }

  .xl\:gap-y-32 {
    row-gap: 8rem
  }

  .xl\:gap-x-36 {
    column-gap: 9rem
  }

  .xl\:gap-y-36 {
    row-gap: 9rem
  }

  .xl\:gap-x-40 {
    column-gap: 10rem
  }

  .xl\:gap-y-40 {
    row-gap: 10rem
  }

  .xl\:gap-x-44 {
    column-gap: 11rem
  }

  .xl\:gap-y-44 {
    row-gap: 11rem
  }

  .xl\:gap-x-48 {
    column-gap: 12rem
  }

  .xl\:gap-y-48 {
    row-gap: 12rem
  }

  .xl\:gap-x-52 {
    column-gap: 13rem
  }

  .xl\:gap-y-52 {
    row-gap: 13rem
  }

  .xl\:gap-x-56 {
    column-gap: 14rem
  }

  .xl\:gap-y-56 {
    row-gap: 14rem
  }

  .xl\:gap-x-60 {
    column-gap: 15rem
  }

  .xl\:gap-y-60 {
    row-gap: 15rem
  }

  .xl\:gap-x-64 {
    column-gap: 16rem
  }

  .xl\:gap-y-64 {
    row-gap: 16rem
  }

  .xl\:gap-x-72 {
    column-gap: 18rem
  }

  .xl\:gap-y-72 {
    row-gap: 18rem
  }

  .xl\:gap-x-80 {
    column-gap: 20rem
  }

  .xl\:gap-y-80 {
    row-gap: 20rem
  }

  .xl\:gap-x-96 {
    column-gap: 24rem
  }

  .xl\:gap-y-96 {
    row-gap: 24rem
  }

  .xl\:gap-\[0px\] {
    gap: 0px;
  }

  .xl\:gap-\[5px\] {
    gap: 5px;
  }

  .xl\:gap-\[10px\] {
    gap: 10px;
  }

  .xl\:gap-\[15px\] {
    gap: 15px;
  }

  .xl\:gap-\[20px\] {
    gap: 20px;
  }

  .xl\:gap-\[25px\] {
    gap: 25px;
  }

  .xl\:gap-\[30px\] {
    gap: 30px;
  }

  .xl\:gap-\[35px\] {
    gap: 35px;
  }

  .xl\:gap-\[40px\] {
    gap: 40px;
  }

  .xl\:gap-\[45px\] {
    gap: 45px;
  }

  .xl\:gap-\[50px\] {
    gap: 50px;
  }

  .xl\:gap-\[55px\] {
    gap: 55px;
  }

  .xl\:gap-\[60px\] {
    gap: 60px;
  }

  .xl\:gap-\[65px\] {
    gap: 65px;
  }

  .xl\:gap-\[70px\] {
    gap: 70px;
  }

  .xl\:gap-\[75px\] {
    gap: 75px;
  }

  .xl\:gap-\[80px\] {
    gap: 80px;
  }

  .xl\:gap-\[85px\] {
    gap: 85px;
  }

  .xl\:gap-\[90px\] {
    gap: 90px;
  }

  .xl\:gap-\[95px\] {
    gap: 95px;
  }

  .xl\:gap-\[100px\] {
    gap: 100px;
  }
}

@media (min-width: 1536px) {
  .\32xl\:gap-0 {
    gap: 0px
  }

  .\32xl\:gap-px {
    gap: 1px
  }

  .\32xl\:gap-0\.5 {
    gap: 0.125rem
  }

  .\32xl\:gap-1 {
    gap: 0.25rem
  }

  .\32xl\:gap-1\.5 {
    gap: 0.375rem
  }

  .\32xl\:gap-2 {
    gap: 0.5rem
  }

  .\32xl\:gap-2\.5 {
    gap: 0.625rem
  }

  .\32xl\:gap-3 {
    gap: 0.75rem
  }

  .\32xl\:gap-3\.5 {
    gap: 0.875rem
  }

  .\32xl\:gap-4 {
    gap: 1rem
  }

  .\32xl\:gap-5 {
    gap: 1.25rem
  }

  .\32xl\:gap-6 {
    gap: 1.5rem
  }

  .\32xl\:gap-7 {
    gap: 1.75rem
  }

  .\32xl\:gap-8 {
    gap: 2rem
  }

  .\32xl\:gap-9 {
    gap: 2.25rem
  }

  .\32xl\:gap-10 {
    gap: 2.5rem
  }

  .\32xl\:gap-11 {
    gap: 2.75rem
  }

  .\32xl\:gap-12 {
    gap: 3rem
  }

  .\32xl\:gap-14 {
    gap: 3.5rem
  }

  .\32xl\:gap-16 {
    gap: 4rem
  }

  .\32xl\:gap-20 {
    gap: 5rem
  }

  .\32xl\:gap-24 {
    gap: 6rem
  }

  .\32xl\:gap-28 {
    gap: 7rem
  }

  .\32xl\:gap-32 {
    gap: 8rem
  }

  .\32xl\:gap-36 {
    gap: 9rem
  }

  .\32xl\:gap-40 {
    gap: 10rem
  }

  .\32xl\:gap-44 {
    gap: 11rem
  }

  .\32xl\:gap-48 {
    gap: 12rem
  }

  .\32xl\:gap-52 {
    gap: 13rem
  }

  .\32xl\:gap-56 {
    gap: 14rem
  }

  .\32xl\:gap-60 {
    gap: 15rem
  }

  .\32xl\:gap-64 {
    gap: 16rem
  }

  .\32xl\:gap-72 {
    gap: 18rem
  }

  .\32xl\:gap-80 {
    gap: 20rem
  }

  .\32xl\:gap-96 {
    gap: 24rem
  }

  .\32xl\:gap-x-0 {
    column-gap: 0px
  }

  .\32xl\:gap-y-0 {
    row-gap: 0px
  }

  .\32xl\:gap-x-px {
    column-gap: 1px
  }

  .\32xl\:gap-y-px {
    row-gap: 1px
  }

  .\32xl\:gap-x-0\.5 {
    column-gap: 0.125rem
  }

  .\32xl\:gap-y-0\.5 {
    row-gap: 0.125rem
  }

  .\32xl\:gap-x-1 {
    column-gap: 0.25rem
  }

  .\32xl\:gap-y-1 {
    row-gap: 0.25rem
  }

  .\32xl\:gap-x-1\.5 {
    column-gap: 0.375rem
  }

  .\32xl\:gap-y-1\.5 {
    row-gap: 0.375rem
  }

  .\32xl\:gap-x-2 {
    column-gap: 0.5rem
  }

  .\32xl\:gap-y-2 {
    row-gap: 0.5rem
  }

  .\32xl\:gap-x-2\.5 {
    column-gap: 0.625rem
  }

  .\32xl\:gap-y-2\.5 {
    row-gap: 0.625rem
  }

  .\32xl\:gap-x-3 {
    column-gap: 0.75rem
  }

  .\32xl\:gap-y-3 {
    row-gap: 0.75rem
  }

  .\32xl\:gap-x-3\.5 {
    column-gap: 0.875rem
  }

  .\32xl\:gap-y-3\.5 {
    row-gap: 0.875rem
  }

  .\32xl\:gap-x-4 {
    column-gap: 1rem
  }

  .\32xl\:gap-y-4 {
    row-gap: 1rem
  }

  .\32xl\:gap-x-5 {
    column-gap: 1.25rem
  }

  .\32xl\:gap-y-5 {
    row-gap: 1.25rem
  }

  .\32xl\:gap-x-6 {
    column-gap: 1.5rem
  }

  .\32xl\:gap-y-6 {
    row-gap: 1.5rem
  }

  .\32xl\:gap-x-7 {
    column-gap: 1.75rem
  }

  .\32xl\:gap-y-7 {
    row-gap: 1.75rem
  }

  .\32xl\:gap-x-8 {
    column-gap: 2rem
  }

  .\32xl\:gap-y-8 {
    row-gap: 2rem
  }

  .\32xl\:gap-x-9 {
    column-gap: 2.25rem
  }

  .\32xl\:gap-y-9 {
    row-gap: 2.25rem
  }

  .\32xl\:gap-x-10 {
    column-gap: 2.5rem
  }

  .\32xl\:gap-y-10 {
    row-gap: 2.5rem
  }

  .\32xl\:gap-x-11 {
    column-gap: 2.75rem
  }

  .\32xl\:gap-y-11 {
    row-gap: 2.75rem
  }

  .\32xl\:gap-x-12 {
    column-gap: 3rem
  }

  .\32xl\:gap-y-12 {
    row-gap: 3rem
  }

  .\32xl\:gap-x-14 {
    column-gap: 3.5rem
  }

  .\32xl\:gap-y-14 {
    row-gap: 3.5rem
  }

  .\32xl\:gap-x-16 {
    column-gap: 4rem
  }

  .\32xl\:gap-y-16 {
    row-gap: 4rem
  }

  .\32xl\:gap-x-20 {
    column-gap: 5rem
  }

  .\32xl\:gap-y-20 {
    row-gap: 5rem
  }

  .\32xl\:gap-x-24 {
    column-gap: 6rem
  }

  .\32xl\:gap-y-24 {
    row-gap: 6rem
  }

  .\32xl\:gap-x-28 {
    column-gap: 7rem
  }

  .\32xl\:gap-y-28 {
    row-gap: 7rem
  }

  .\32xl\:gap-x-32 {
    column-gap: 8rem
  }

  .\32xl\:gap-y-32 {
    row-gap: 8rem
  }

  .\32xl\:gap-x-36 {
    column-gap: 9rem
  }

  .\32xl\:gap-y-36 {
    row-gap: 9rem
  }

  .\32xl\:gap-x-40 {
    column-gap: 10rem
  }

  .\32xl\:gap-y-40 {
    row-gap: 10rem
  }

  .\32xl\:gap-x-44 {
    column-gap: 11rem
  }

  .\32xl\:gap-y-44 {
    row-gap: 11rem
  }

  .\32xl\:gap-x-48 {
    column-gap: 12rem
  }

  .\32xl\:gap-y-48 {
    row-gap: 12rem
  }

  .\32xl\:gap-x-52 {
    column-gap: 13rem
  }

  .\32xl\:gap-y-52 {
    row-gap: 13rem
  }

  .\32xl\:gap-x-56 {
    column-gap: 14rem
  }

  .\32xl\:gap-y-56 {
    row-gap: 14rem
  }

  .\32xl\:gap-x-60 {
    column-gap: 15rem
  }

  .\32xl\:gap-y-60 {
    row-gap: 15rem
  }

  .\32xl\:gap-x-64 {
    column-gap: 16rem
  }

  .\32xl\:gap-y-64 {
    row-gap: 16rem
  }

  .\32xl\:gap-x-72 {
    column-gap: 18rem
  }

  .\32xl\:gap-y-72 {
    row-gap: 18rem
  }

  .\32xl\:gap-x-80 {
    column-gap: 20rem
  }

  .\32xl\:gap-y-80 {
    row-gap: 20rem
  }

  .\32xl\:gap-x-96 {
    column-gap: 24rem
  }

  .\32xl\:gap-y-96 {
    row-gap: 24rem
  }

  .\32xl\:gap-\[0px\] {
    gap: 0px;
  }

  .\32xl\:gap-\[5px\] {
    gap: 5px;
  }

  .\32xl\:gap-\[10px\] {
    gap: 10px;
  }

  .\32xl\:gap-\[15px\] {
    gap: 15px;
  }

  .\32xl\:gap-\[20px\] {
    gap: 20px;
  }

  .\32xl\:gap-\[25px\] {
    gap: 25px;
  }

  .\32xl\:gap-\[30px\] {
    gap: 30px;
  }

  .\32xl\:gap-\[35px\] {
    gap: 35px;
  }

  .\32xl\:gap-\[40px\] {
    gap: 40px;
  }

  .\32xl\:gap-\[45px\] {
    gap: 45px;
  }

  .\32xl\:gap-\[50px\] {
    gap: 50px;
  }

  .\32xl\:gap-\[55px\] {
    gap: 55px;
  }

  .\32xl\:gap-\[60px\] {
    gap: 60px;
  }

  .\32xl\:gap-\[65px\] {
    gap: 65px;
  }

  .\32xl\:gap-\[70px\] {
    gap: 70px;
  }

  .\32xl\:gap-\[75px\] {
    gap: 75px;
  }

  .\32xl\:gap-\[80px\] {
    gap: 80px;
  }

  .\32xl\:gap-\[85px\] {
    gap: 85px;
  }

  .\32xl\:gap-\[90px\] {
    gap: 90px;
  }

  .\32xl\:gap-\[95px\] {
    gap: 95px;
  }

  .\32xl\:gap-\[100px\] {
    gap: 100px;
  }
}

.gap-p-4{
  gap: 4%;
}