:root {
    --principal-color: #006FBA;
}

.align-content-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.floating-button {
    right: 30px;
    width: 50px;
    height: 50px;
    bottom: 30px;
    color: #FFF;
    position: fixed;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 2px 2px 3px #999;
    background-color: var(--principal-color);
    z-index: 999999;
}

#webchat-container,
#webchat {
    display: none;
}

.cb-loader-container {
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    position: absolute;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    border: .5px solid #cccccc !important;
    background: #fff;
}

.cb-loader-container .cb-loader-text {
    width: 100%;
    margin-top: 100%;
    text-align: center;
    color: #000;
}

.cb-loader {
    display: flex;
    align-items: center;
    justify-content: center;
}


/** Ring loader styles **/

.cb-loader.ring {
    display: flex;
    height: 5rem;
    position: relative;
    align-items: center;
    justify-content: center;
}

.cb-loader.ring .ring-child {
    width: 5rem;
    height: 5rem;
    display: block;
    border-radius: 50%;
    position: absolute;
    box-sizing: border-box;
    border: .5rem solid var(--principal-color);
    animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--principal-color) transparent transparent transparent;
}

.cb-loader.ring .ring-child:nth-child(1) {
    animation-delay: -0.45s;
}

.cb-loader.ring .ring-child:nth-child(2) {
    animation-delay: -0.3s;
}

.cb-loader.ring .ring-child:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.chatbot-container {
    height: 82vh;
    width: 400px;
    min-width: 400px;
    position: fixed;
    right: 100px;
    top: 70px;
    z-index: 999999999999;
}

#webchat {
    height: 100%;
    border: .5px solid #cccccc !important;
}

.chatbot-header {
    background-color: var(--principal-color);
    height: 40px;
    padding-left: 30px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chatbot-header p {
    margin-top: 15px;
}

.chatbot-header img {
    position: absolute;
    float: left;
    left: 25px;
    margin: auto;
}

.webchat__suggested-action {
    width: 190px !important;
    min-height: 42px !important;
    height: auto !important;
    white-space: normal !important;
}

.webchat__suggested-action span {
    width: auto o !important;
    box-sizing: border-box !important;
}
