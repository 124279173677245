@for $i from 0 through 100 {
    @if $i % 1==0 {
        .width-#{$i} {
            width: calc(#{$i} * 1%);
            min-width: calc(#{$i} * 1%);
            max-width: calc(#{$i} * 1%);
        }
    }
}

@media (min-width: 640px) {
    @for $i from 0 through 100 {
        @if $i % 1==0 {
            .sm\:width-#{$i} {
                width: calc(#{$i} * 1%);
                min-width: calc(#{$i} * 1%);
                max-width: calc(#{$i} * 1%);
            }
        }
    }
}

@media (min-width: 768px) {
    @for $i from 0 through 100 {
        @if $i % 1==0 {
            .md\:width-#{$i} {
                width: calc(#{$i} * 1%);
                min-width: calc(#{$i} * 1%);
                max-width: calc(#{$i} * 1%);
            }
        }
    }
}

@media (min-width: 1024px) {
    @for $i from 0 through 100 {
        @if $i % 1==0 {
            .lg\:width-#{$i} {
                width: calc(#{$i} * 1%);
                min-width: calc(#{$i} * 1%);
                max-width: calc(#{$i} * 1%);
            }
        }
    }
}

@media (min-width: 1280px) {
    @for $i from 0 through 100 {
        @if $i % 1==0 {
            .xl\:width-#{$i} {
                width: calc(#{$i} * 1%);
                min-width: calc(#{$i} * 1%);
                max-width: calc(#{$i} * 1%);
            }
        }
    }
}

@media (min-width: 1536px) {
    @for $i from 0 through 100 {
        @if $i % 1==0 {
            .\32xl\:width-#{$i} {
                width: calc(#{$i} * 1%);
                min-width: calc(#{$i} * 1%);
                max-width: calc(#{$i} * 1%);
            }
        }
    }
}