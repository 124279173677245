.place-content-center {
    place-content: center
}

.place-content-start {
    place-content: start
}

.place-content-end {
    place-content: end
}

.place-content-between {
    place-content: space-between
}

.place-content-around {
    place-content: space-around
}

.place-content-evenly {
    place-content: space-evenly
}

.place-content-baseline {
    place-content: baseline
}

.place-content-stretch {
    place-content: stretch
}

.content-center {
    align-content: center
}

.content-start {
    align-content: flex-start
}

.content-end {
    align-content: flex-end
}

.content-between {
    align-content: space-between
}

.content-around {
    align-content: space-around
}

.content-evenly {
    align-content: space-evenly
}

.content-baseline {
    align-content: baseline
}

.items-start {
    align-items: flex-start
}

.items-end {
    align-items: flex-end
}

.items-center {
    align-items: center
}

.items-baseline {
    align-items: baseline
}

.items-stretch {
    align-items: stretch
}

.justify-start {
    justify-content: flex-start
}

.justify-end {
    justify-content: flex-end
}

.justify-center {
    justify-content: center
}

.justify-between {
    justify-content: space-between
}

.justify-around {
    justify-content: space-around
}

.justify-evenly {
    justify-content: space-evenly
}

.justify-items-start {
    justify-items: start
}

.justify-items-end {
    justify-items: end
}

.justify-items-center {
    justify-items: center
}

.justify-items-stretch {
    justify-items: stretch
}

.self-auto {
    align-self: auto
}

.self-start {
    align-self: flex-start
}

.self-end {
    align-self: flex-end
}

.self-center {
    align-self: center
}

.self-stretch {
    align-self: stretch
}

.self-baseline {
    align-self: baseline
}

.justify-self-auto {
    justify-self: auto
}

.justify-self-start {
    justify-self: start
}

.justify-self-end {
    justify-self: end
}

.justify-self-center {
    justify-self: center
}

.justify-self-stretch {
    justify-self: stretch
}

@media (min-width: 640px) {
    .sm\:place-content-center {
        place-content: center
    }

    .sm\:place-content-start {
        place-content: start
    }

    .sm\:place-content-end {
        place-content: end
    }

    .sm\:place-content-between {
        place-content: space-between
    }

    .sm\:place-content-around {
        place-content: space-around
    }

    .sm\:place-content-evenly {
        place-content: space-evenly
    }

    .sm\:place-content-baseline {
        place-content: baseline
    }

    .sm\:place-content-stretch {
        place-content: stretch
    }

    .sm\:content-center {
        align-content: center
    }

    .sm\:content-start {
        align-content: flex-start
    }

    .sm\:content-end {
        align-content: flex-end
    }

    .sm\:content-between {
        align-content: space-between
    }

    .sm\:content-around {
        align-content: space-around
    }

    .sm\:content-evenly {
        align-content: space-evenly
    }

    .sm\:content-baseline {
        align-content: baseline
    }

    .sm\:items-start {
        align-items: flex-start
    }

    .sm\:items-end {
        align-items: flex-end
    }

    .sm\:items-center {
        align-items: center
    }

    .sm\:items-baseline {
        align-items: baseline
    }

    .sm\:items-stretch {
        align-items: stretch
    }

    .sm\:justify-start {
        justify-content: flex-start
    }

    .sm\:justify-end {
        justify-content: flex-end
    }

    .sm\:justify-center {
        justify-content: center
    }

    .sm\:justify-between {
        justify-content: space-between
    }

    .sm\:justify-around {
        justify-content: space-around
    }

    .sm\:justify-evenly {
        justify-content: space-evenly
    }

    .sm\:justify-items-start {
        justify-items: start
    }

    .sm\:justify-items-end {
        justify-items: end
    }

    .sm\:justify-items-center {
        justify-items: center
    }

    .sm\:justify-items-stretch {
        justify-items: stretch
    }

    .sm\:self-auto {
        align-self: auto
    }

    .sm\:self-start {
        align-self: flex-start
    }

    .sm\:self-end {
        align-self: flex-end
    }

    .sm\:self-center {
        align-self: center
    }

    .sm\:self-stretch {
        align-self: stretch
    }

    .sm\:self-baseline {
        align-self: baseline
    }

    .sm\:justify-self-auto {
        justify-self: auto
    }

    .sm\:justify-self-start {
        justify-self: start
    }

    .sm\:justify-self-end {
        justify-self: end
    }

    .sm\:justify-self-center {
        justify-self: center
    }

    .sm\:justify-self-stretch {
        justify-self: stretch
    }
}

@media (min-width: 768px) {
    .md\:place-content-center {
        place-content: center
    }

    .md\:place-content-start {
        place-content: start
    }

    .md\:place-content-end {
        place-content: end
    }

    .md\:place-content-between {
        place-content: space-between
    }

    .md\:place-content-around {
        place-content: space-around
    }

    .md\:place-content-evenly {
        place-content: space-evenly
    }

    .md\:place-content-baseline {
        place-content: baseline
    }

    .md\:place-content-stretch {
        place-content: stretch
    }

    .md\:content-center {
        align-content: center
    }

    .md\:content-start {
        align-content: flex-start
    }

    .md\:content-end {
        align-content: flex-end
    }

    .md\:content-between {
        align-content: space-between
    }

    .md\:content-around {
        align-content: space-around
    }

    .md\:content-evenly {
        align-content: space-evenly
    }

    .md\:content-baseline {
        align-content: baseline
    }

    .md\:items-start {
        align-items: flex-start
    }

    .md\:items-end {
        align-items: flex-end
    }

    .md\:items-center {
        align-items: center
    }

    .md\:items-baseline {
        align-items: baseline
    }

    .md\:items-stretch {
        align-items: stretch
    }

    .md\:justify-start {
        justify-content: flex-start
    }

    .md\:justify-end {
        justify-content: flex-end
    }

    .md\:justify-center {
        justify-content: center
    }

    .md\:justify-between {
        justify-content: space-between
    }

    .md\:justify-around {
        justify-content: space-around
    }

    .md\:justify-evenly {
        justify-content: space-evenly
    }

    .md\:justify-items-start {
        justify-items: start
    }

    .md\:justify-items-end {
        justify-items: end
    }

    .md\:justify-items-center {
        justify-items: center
    }

    .md\:justify-items-stretch {
        justify-items: stretch
    }

    .md\:self-auto {
        align-self: auto
    }

    .md\:self-start {
        align-self: flex-start
    }

    .md\:self-end {
        align-self: flex-end
    }

    .md\:self-center {
        align-self: center
    }

    .md\:self-stretch {
        align-self: stretch
    }

    .md\:self-baseline {
        align-self: baseline
    }

    .md\:justify-self-auto {
        justify-self: auto
    }

    .md\:justify-self-start {
        justify-self: start
    }

    .md\:justify-self-end {
        justify-self: end
    }

    .md\:justify-self-center {
        justify-self: center
    }

    .md\:justify-self-stretch {
        justify-self: stretch
    }
}

@media (min-width: 1024px) {
    .lg\:place-content-center {
        place-content: center
    }

    .lg\:place-content-start {
        place-content: start
    }

    .lg\:place-content-end {
        place-content: end
    }

    .lg\:place-content-between {
        place-content: space-between
    }

    .lg\:place-content-around {
        place-content: space-around
    }

    .lg\:place-content-evenly {
        place-content: space-evenly
    }

    .lg\:place-content-baseline {
        place-content: baseline
    }

    .lg\:place-content-stretch {
        place-content: stretch
    }

    .lg\:content-center {
        align-content: center
    }

    .lg\:content-start {
        align-content: flex-start
    }

    .lg\:content-end {
        align-content: flex-end
    }

    .lg\:content-between {
        align-content: space-between
    }

    .lg\:content-around {
        align-content: space-around
    }

    .lg\:content-evenly {
        align-content: space-evenly
    }

    .lg\:content-baseline {
        align-content: baseline
    }

    .lg\:items-start {
        align-items: flex-start
    }

    .lg\:items-end {
        align-items: flex-end
    }

    .lg\:items-center {
        align-items: center
    }

    .lg\:items-baseline {
        align-items: baseline
    }

    .lg\:items-stretch {
        align-items: stretch
    }

    .lg\:justify-start {
        justify-content: flex-start
    }

    .lg\:justify-end {
        justify-content: flex-end
    }

    .lg\:justify-center {
        justify-content: center
    }

    .lg\:justify-between {
        justify-content: space-between
    }

    .lg\:justify-around {
        justify-content: space-around
    }

    .lg\:justify-evenly {
        justify-content: space-evenly
    }

    .lg\:justify-items-start {
        justify-items: start
    }

    .lg\:justify-items-end {
        justify-items: end
    }

    .lg\:justify-items-center {
        justify-items: center
    }

    .lg\:justify-items-stretch {
        justify-items: stretch
    }

    .lg\:self-auto {
        align-self: auto
    }

    .lg\:self-start {
        align-self: flex-start
    }

    .lg\:self-end {
        align-self: flex-end
    }

    .lg\:self-center {
        align-self: center
    }

    .lg\:self-stretch {
        align-self: stretch
    }

    .lg\:self-baseline {
        align-self: baseline
    }

    .lg\:justify-self-auto {
        justify-self: auto
    }

    .lg\:justify-self-start {
        justify-self: start
    }

    .lg\:justify-self-end {
        justify-self: end
    }

    .lg\:justify-self-center {
        justify-self: center
    }

    .lg\:justify-self-stretch {
        justify-self: stretch
    }
}

@media (min-width: 1280px) {
    .xl\:place-content-center {
        place-content: center
    }

    .xl\:place-content-start {
        place-content: start
    }

    .xl\:place-content-end {
        place-content: end
    }

    .xl\:place-content-between {
        place-content: space-between
    }

    .xl\:place-content-around {
        place-content: space-around
    }

    .xl\:place-content-evenly {
        place-content: space-evenly
    }

    .xl\:place-content-baseline {
        place-content: baseline
    }

    .xl\:place-content-stretch {
        place-content: stretch
    }

    .xl\:content-center {
        align-content: center
    }

    .xl\:content-start {
        align-content: flex-start
    }

    .xl\:content-end {
        align-content: flex-end
    }

    .xl\:content-between {
        align-content: space-between
    }

    .xl\:content-around {
        align-content: space-around
    }

    .xl\:content-evenly {
        align-content: space-evenly
    }

    .xl\:content-baseline {
        align-content: baseline
    }

    .xl\:items-start {
        align-items: flex-start
    }

    .xl\:items-end {
        align-items: flex-end
    }

    .xl\:items-center {
        align-items: center
    }

    .xl\:items-baseline {
        align-items: baseline
    }

    .xl\:items-stretch {
        align-items: stretch
    }

    .xl\:justify-start {
        justify-content: flex-start
    }

    .xl\:justify-end {
        justify-content: flex-end
    }

    .xl\:justify-center {
        justify-content: center
    }

    .xl\:justify-between {
        justify-content: space-between
    }

    .xl\:justify-around {
        justify-content: space-around
    }

    .xl\:justify-evenly {
        justify-content: space-evenly
    }

    .xl\:justify-items-start {
        justify-items: start
    }

    .xl\:justify-items-end {
        justify-items: end
    }

    .xl\:justify-items-center {
        justify-items: center
    }

    .xl\:justify-items-stretch {
        justify-items: stretch
    }

    .xl\:self-auto {
        align-self: auto
    }

    .xl\:self-start {
        align-self: flex-start
    }

    .xl\:self-end {
        align-self: flex-end
    }

    .xl\:self-center {
        align-self: center
    }

    .xl\:self-stretch {
        align-self: stretch
    }

    .xl\:self-baseline {
        align-self: baseline
    }

    .xl\:justify-self-auto {
        justify-self: auto
    }

    .xl\:justify-self-start {
        justify-self: start
    }

    .xl\:justify-self-end {
        justify-self: end
    }

    .xl\:justify-self-center {
        justify-self: center
    }

    .xl\:justify-self-stretch {
        justify-self: stretch
    }
}

@media (min-width: 1536px) {
    .\32xl\:place-content-center {
        place-content: center
    }

    .\32xl\:place-content-start {
        place-content: start
    }

    .\32xl\:place-content-end {
        place-content: end
    }

    .\32xl\:place-content-between {
        place-content: space-between
    }

    .\32xl\:place-content-around {
        place-content: space-around
    }

    .\32xl\:place-content-evenly {
        place-content: space-evenly
    }

    .\32xl\:place-content-baseline {
        place-content: baseline
    }

    .\32xl\:place-content-stretch {
        place-content: stretch
    }

    .\32xl\:content-center {
        align-content: center
    }

    .\32xl\:content-start {
        align-content: flex-start
    }

    .\32xl\:content-end {
        align-content: flex-end
    }

    .\32xl\:content-between {
        align-content: space-between
    }

    .\32xl\:content-around {
        align-content: space-around
    }

    .\32xl\:content-evenly {
        align-content: space-evenly
    }

    .\32xl\:content-baseline {
        align-content: baseline
    }

    .\32xl\:items-start {
        align-items: flex-start
    }

    .\32xl\:items-end {
        align-items: flex-end
    }

    .\32xl\:items-center {
        align-items: center
    }

    .\32xl\:items-baseline {
        align-items: baseline
    }

    .\32xl\:items-stretch {
        align-items: stretch
    }

    .\32xl\:justify-start {
        justify-content: flex-start
    }

    .\32xl\:justify-end {
        justify-content: flex-end
    }

    .\32xl\:justify-center {
        justify-content: center
    }

    .\32xl\:justify-between {
        justify-content: space-between
    }

    .\32xl\:justify-around {
        justify-content: space-around
    }

    .\32xl\:justify-evenly {
        justify-content: space-evenly
    }

    .\32xl\:justify-items-start {
        justify-items: start
    }

    .\32xl\:justify-items-end {
        justify-items: end
    }

    .\32xl\:justify-items-center {
        justify-items: center
    }

    .\32xl\:justify-items-stretch {
        justify-items: stretch
    }

    .\32xl\:self-auto {
        align-self: auto
    }

    .\32xl\:self-start {
        align-self: flex-start
    }

    .\32xl\:self-end {
        align-self: flex-end
    }

    .\32xl\:self-center {
        align-self: center
    }

    .\32xl\:self-stretch {
        align-self: stretch
    }

    .\32xl\:self-baseline {
        align-self: baseline
    }

    .\32xl\:justify-self-auto {
        justify-self: auto
    }

    .\32xl\:justify-self-start {
        justify-self: start
    }

    .\32xl\:justify-self-end {
        justify-self: end
    }

    .\32xl\:justify-self-center {
        justify-self: center
    }

    .\32xl\:justify-self-stretch {
        justify-self: stretch
    }
}