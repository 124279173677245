@for $i from 6 through 128 {
    @if $i % 2 == 0 {
      .fz-#{$i} {
        font-size: #{$i}px;
      }
      .fz-i-#{$i} {
        font-size: #{$i}px !important;
      }
    }
  }
  