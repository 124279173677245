.blue {
  background-color: #1e3799;
  color: white;
}

.green {
  background-color: #71cf85;
  color: white;
}

.red {
  background-color: #e55039;
  color: white;
}

.orange {
  background-color: #fa983a;
  color: white;
}

.word {
  background-color: #ecf0f1;
  color: blac;
}
