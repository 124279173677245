.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-muted {
  color: #86827c;
}

.text-bold {
  font-weight: bold;
}

.text-bolder {
  font-weight: bolder !important;
}

.text-through{
  text-decoration: line-through;
}

@media (min-width: 640px) {
  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-justify {
    text-align: justify;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-muted {
    color: #86827c;
  }

  .sm\:text-bold {
    font-weight: bold;
  }

  .sm\:text-bolder {
    font-weight: bolder !important;
  }
}

@media (min-width: 768px) {
  .md\:text-center {
    text-align: center;
  }

  .md\:text-justify {
    text-align: justify;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-muted {
    color: #86827c;
  }

  .md\:text-bold {
    font-weight: bold;
  }

  .md\:text-bolder {
    font-weight: bolder !important;
  }
}

@media (min-width: 1024px) {
  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-justify {
    text-align: justify;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-muted {
    color: #86827c;
  }

  .lg\:text-bold {
    font-weight: bold;
  }

  .lg\:text-bolder {
    font-weight: bolder !important;
  }
}

@media (min-width: 1280px) {
  .xl\:text-center {
    text-align: center;
  }

  .xl\:text-justify {
    text-align: justify;
  }

  .xl\:text-right {
    text-align: right;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-muted {
    color: #86827c;
  }

  .xl\:text-bold {
    font-weight: bold;
  }

  .xl\:text-bolder {
    font-weight: bolder !important;
  }
}

@media (min-width: 1536px) {
  .\32xl\:text-center {
    text-align: center;
  }

  .\32xl\:text-justify {
    text-align: justify;
  }

  .\32xl\:text-right {
    text-align: right;
  }

  .\32xl\:text-left {
    text-align: left;
  }

  .\32xl\:text-muted {
    color: #86827c;
  }

  .\32xl\:text-bold {
    font-weight: bold;
  }

  .\32xl\:text-bolder {
    font-weight: bolder !important;
  }
}