.margin-0 {
  margin: 0;
}

@for $i from 5 through 150 {
  @if $i % 5==0 {
    .margin-#{$i} {
      margin: #{$i}px;
    }

    .margin-t-#{$i} {
      margin-top: #{$i}px;
    }

    .margin-b-#{$i} {
      margin-bottom: #{$i}px;
    }

    .margin-l-#{$i} {
      margin-left: #{$i}px;
    }

    .margin-r-#{$i} {
      margin-right: #{$i}px;
    }

    .margin-lr-#{$i} {
      margin-left: #{$i}px;
      margin-right: #{$i}px;
    }

    .margin-tb-#{$i} {
      margin-top: #{$i}px;
      margin-bottom: #{$i}px;
    }

    .margin-tl-#{$i} {
      margin-top: #{$i}px;
      margin-left: #{$i}px;
    }

    .margin-tr-#{$i} {
      margin-top: #{$i}px;
      margin-right: #{$i}px;
    }

    .margin-bl-#{$i} {
      margin-bottom: #{$i}px;
      margin-left: #{$i}px;
    }

    .margin-br-#{$i} {
      margin-bottom: #{$i}px;
      margin-right: #{$i}px;
    }
  }
}

@media (min-width: 640px) {
  @for $i from 5 through 150 {
    @if $i % 5==0 {
      .sm\:margin-#{$i} {
        margin: #{$i}px;
      }

      .sm\:margin-t-#{$i} {
        margin-top: #{$i}px;
      }

      .sm\:margin-b-#{$i} {
        margin-bottom: #{$i}px;
      }

      .sm\:margin-l-#{$i} {
        margin-left: #{$i}px;
      }

      .sm\:margin-r-#{$i} {
        margin-right: #{$i}px;
      }

      .sm\:margin-lr-#{$i} {
        margin-left: #{$i}px;
        margin-right: #{$i}px;
      }

      .sm\:margin-tb-#{$i} {
        margin-top: #{$i}px;
        margin-bottom: #{$i}px;
      }

      .sm\:margin-tl-#{$i} {
        margin-top: #{$i}px;
        margin-left: #{$i}px;
      }

      .sm\:margin-tr-#{$i} {
        margin-top: #{$i}px;
        margin-right: #{$i}px;
      }

      .sm\:margin-bl-#{$i} {
        margin-bottom: #{$i}px;
        margin-left: #{$i}px;
      }

      .sm\:margin-br-#{$i} {
        margin-bottom: #{$i}px;
        margin-right: #{$i}px;
      }
    }
  }
}

@media (min-width: 768px) {
  @for $i from 5 through 150 {
    @if $i % 5==0 {
      .md\:margin-#{$i} {
        margin: #{$i}px;
      }

      .md\:margin-t-#{$i} {
        margin-top: #{$i}px;
      }

      .md\:margin-b-#{$i} {
        margin-bottom: #{$i}px;
      }

      .md\:margin-l-#{$i} {
        margin-left: #{$i}px;
      }

      .md\:margin-r-#{$i} {
        margin-right: #{$i}px;
      }

      .md\:margin-lr-#{$i} {
        margin-left: #{$i}px;
        margin-right: #{$i}px;
      }

      .md\:margin-tb-#{$i} {
        margin-top: #{$i}px;
        margin-bottom: #{$i}px;
      }

      .md\:margin-tl-#{$i} {
        margin-top: #{$i}px;
        margin-left: #{$i}px;
      }

      .md\:margin-tr-#{$i} {
        margin-top: #{$i}px;
        margin-right: #{$i}px;
      }

      .md\:margin-bl-#{$i} {
        margin-bottom: #{$i}px;
        margin-left: #{$i}px;
      }

      .md\:margin-br-#{$i} {
        margin-bottom: #{$i}px;
        margin-right: #{$i}px;
      }
    }
  }
}

@media (min-width: 1024px) {
  @for $i from 5 through 150 {
    @if $i % 5==0 {
      .lg\:margin-#{$i} {
        margin: #{$i}px;
      }

      .lg\:margin-t-#{$i} {
        margin-top: #{$i}px;
      }

      .lg\:margin-b-#{$i} {
        margin-bottom: #{$i}px;
      }

      .lg\:margin-l-#{$i} {
        margin-left: #{$i}px;
      }

      .lg\:margin-r-#{$i} {
        margin-right: #{$i}px;
      }

      .lg\:margin-lr-#{$i} {
        margin-left: #{$i}px;
        margin-right: #{$i}px;
      }

      .lg\:margin-tb-#{$i} {
        margin-top: #{$i}px;
        margin-bottom: #{$i}px;
      }

      .lg\:margin-tl-#{$i} {
        margin-top: #{$i}px;
        margin-left: #{$i}px;
      }

      .lg\:margin-tr-#{$i} {
        margin-top: #{$i}px;
        margin-right: #{$i}px;
      }

      .lg\:margin-bl-#{$i} {
        margin-bottom: #{$i}px;
        margin-left: #{$i}px;
      }

      .lg\:margin-br-#{$i} {
        margin-bottom: #{$i}px;
        margin-right: #{$i}px;
      }
    }
  }
}

@media (min-width: 1280px) {
  @for $i from 5 through 150 {
    @if $i % 5==0 {
      .xl\:margin-#{$i} {
        margin: #{$i}px;
      }

      .xl\:margin-t-#{$i} {
        margin-top: #{$i}px;
      }

      .xl\:margin-b-#{$i} {
        margin-bottom: #{$i}px;
      }

      .xl\:margin-l-#{$i} {
        margin-left: #{$i}px;
      }

      .xl\:margin-r-#{$i} {
        margin-right: #{$i}px;
      }

      .xl\:margin-lr-#{$i} {
        margin-left: #{$i}px;
        margin-right: #{$i}px;
      }

      .xl\:margin-tb-#{$i} {
        margin-top: #{$i}px;
        margin-bottom: #{$i}px;
      }

      .xl\:margin-tl-#{$i} {
        margin-top: #{$i}px;
        margin-left: #{$i}px;
      }

      .xl\:margin-tr-#{$i} {
        margin-top: #{$i}px;
        margin-right: #{$i}px;
      }

      .xl\:margin-bl-#{$i} {
        margin-bottom: #{$i}px;
        margin-left: #{$i}px;
      }

      .xl\:margin-br-#{$i} {
        margin-bottom: #{$i}px;
        margin-right: #{$i}px;
      }
    }
  }
}

@media (min-width: 1536px) {
  @for $i from 5 through 150 {
    @if $i % 5==0 {
      .\32xl\:margin-#{$i} {
        margin: #{$i}px;
      }

      .\32xl\:margin-t-#{$i} {
        margin-top: #{$i}px;
      }

      .\32xl\:margin-b-#{$i} {
        margin-bottom: #{$i}px;
      }

      .\32xl\:margin-l-#{$i} {
        margin-left: #{$i}px;
      }

      .\32xl\:margin-r-#{$i} {
        margin-right: #{$i}px;
      }

      .\32xl\:margin-lr-#{$i} {
        margin-left: #{$i}px;
        margin-right: #{$i}px;
      }

      .\32xl\:margin-tb-#{$i} {
        margin-top: #{$i}px;
        margin-bottom: #{$i}px;
      }

      .\32xl\:margin-tl-#{$i} {
        margin-top: #{$i}px;
        margin-left: #{$i}px;
      }

      .\32xl\:margin-tr-#{$i} {
        margin-top: #{$i}px;
        margin-right: #{$i}px;
      }

      .\32xl\:margin-bl-#{$i} {
        margin-bottom: #{$i}px;
        margin-left: #{$i}px;
      }

      .\32xl\:margin-br-#{$i} {
        margin-bottom: #{$i}px;
        margin-right: #{$i}px;
      }
    }
  }
}