@use '@angular/material' as mat;
@import '@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "vars";
// define 3 theme color
// mat-palette accepts $palette-name, main, lighter and darker variants
$unitec-theme-primary: mat.define-palette($mat-unitec-blue, 700, 300, 900);
$unitec-theme-accent: mat.define-palette($mat-unitec-orange, A500);
$unitec-theme-warn: mat.define-palette(mat.$red-palette);
// create theme (use mat-dark-theme for themes with dark backgrounds)
$unitec-theme: mat.define-light-theme( $unitec-theme-primary, $unitec-theme-accent, $unitec-theme-warn);
@include mat.all-component-themes($unitec-theme);

.width-100 {
	width: 100%;
}
