.order-1 {
    order: 1
  }
  
  .order-2 {
    order: 2
  }
  
  .order-3 {
    order: 3
  }
  
  .order-4 {
    order: 4
  }
  
  .order-5 {
    order: 5
  }
  
  .order-6 {
    order: 6
  }
  
  .order-7 {
    order: 7
  }
  
  .order-8 {
    order: 8
  }
  
  .order-9 {
    order: 9
  }
  
  .order-10 {
    order: 10
  }
  
  .order-11 {
    order: 11
  }
  
  .order-12 {
    order: 12
  }
  
  .order-first {
    order: -9999
  }
  
  .order-last {
    order: 9999
  }
  
  .order-none {
    order: 0
  }
  
  .flex {
    display: flex !important
  }
  
  .flex-1 {
    flex: 1 1 0%
  }
  
  .flex-auto {
    flex: 1 1 auto
  }
  
  .flex-initial {
    flex: 0 1 auto
  }
  
  .flex-none {
    flex: none
  }
  
  .shrink {
    flex-shrink: 1
  }
  
  .shrink-0 {
    flex-shrink: 0
  }
  
  .grow {
    flex-grow: 1
  }
  
  .grow-0 {
    flex-grow: 0
  }
  
  .basis-0 {
    flex-basis: 0px
  }
  
  .basis-1 {
    flex-basis: 0.25rem
  }
  
  .basis-2 {
    flex-basis: 0.5rem
  }
  
  .basis-3 {
    flex-basis: 0.75rem
  }
  
  .basis-4 {
    flex-basis: 1rem
  }
  
  .basis-5 {
    flex-basis: 1.25rem
  }
  
  .basis-6 {
    flex-basis: 1.5rem
  }
  
  .basis-7 {
    flex-basis: 1.75rem
  }
  
  .basis-8 {
    flex-basis: 2rem
  }
  
  .basis-9 {
    flex-basis: 2.25rem
  }
  
  .basis-10 {
    flex-basis: 2.5rem
  }
  
  .basis-11 {
    flex-basis: 2.75rem
  }
  
  .basis-12 {
    flex-basis: 3rem
  }
  
  .basis-14 {
    flex-basis: 3.5rem
  }
  
  .basis-16 {
    flex-basis: 4rem
  }
  
  .basis-20 {
    flex-basis: 5rem
  }
  
  .basis-24 {
    flex-basis: 6rem
  }
  
  .basis-28 {
    flex-basis: 7rem
  }
  
  .basis-32 {
    flex-basis: 8rem
  }
  
  .basis-36 {
    flex-basis: 9rem
  }
  
  .basis-40 {
    flex-basis: 10rem
  }
  
  .basis-44 {
    flex-basis: 11rem
  }
  
  .basis-48 {
    flex-basis: 12rem
  }
  
  .basis-52 {
    flex-basis: 13rem
  }
  
  .basis-56 {
    flex-basis: 14rem
  }
  
  .basis-60 {
    flex-basis: 15rem
  }
  
  .basis-64 {
    flex-basis: 16rem
  }
  
  .basis-72 {
    flex-basis: 18rem
  }
  
  .basis-80 {
    flex-basis: 20rem
  }
  
  .basis-96 {
    flex-basis: 24rem
  }
  
  .basis-auto {
    flex-basis: auto
  }
  
  .basis-px {
    flex-basis: 1px
  }
  
  .basis-0\.5 {
    flex-basis: 0.125rem
  }
  
  .basis-1\.5 {
    flex-basis: 0.375rem
  }
  
  .basis-2\.5 {
    flex-basis: 0.625rem
  }
  
  .basis-3\.5 {
    flex-basis: 0.875rem
  }
  
  .basis-1\/2 {
    flex-basis: 50%
  }
  
  .basis-1\/3 {
    flex-basis: 33.333333%
  }
  
  .basis-2\/3 {
    flex-basis: 66.666667%
  }
  
  .basis-1\/4 {
    flex-basis: 25%
  }
  
  .basis-2\/4 {
    flex-basis: 50%
  }
  
  .basis-3\/4 {
    flex-basis: 75%
  }
  
  .basis-1\/5 {
    flex-basis: 20%
  }
  
  .basis-2\/5 {
    flex-basis: 40%
  }
  
  .basis-3\/5 {
    flex-basis: 60%
  }
  
  .basis-4\/5 {
    flex-basis: 80%
  }
  
  .basis-1\/6 {
    flex-basis: 16.666667%
  }
  
  .basis-2\/6 {
    flex-basis: 33.333333%
  }
  
  .basis-3\/6 {
    flex-basis: 50%
  }
  
  .basis-4\/6 {
    flex-basis: 66.666667%
  }
  
  .basis-5\/6 {
    flex-basis: 83.333333%
  }

  .basis-1\/10 {
    flex-basis: 10%
  }

  .basis-2\/10 {
    flex-basis: 20%
  }

  .basis-3\/10 {
    flex-basis: 30%
  }

  .basis-4\/10 {
    flex-basis: 40%
  }

  .basis-5\/10 {
    flex-basis: 50%
  }

  .basis-6\/10 {
    flex-basis: 60%
  }

  .basis-7\/10 {
    flex-basis: 70%
  }

  .basis-8\/10 {
    flex-basis: 80%
  }

  .basis-9\/10 {
    flex-basis: 90%
  }
  
  .basis-1\/12 {
    flex-basis: 8.333333%
  }
  
  .basis-2\/12 {
    flex-basis: 16.666667%
  }
  
  .basis-3\/12 {
    flex-basis: 25%
  }
  
  .basis-4\/12 {
    flex-basis: 33.333333%
  }
  
  .basis-5\/12 {
    flex-basis: 41.666667%
  }
  
  .basis-6\/12 {
    flex-basis: 50%
  }
  
  .basis-7\/12 {
    flex-basis: 58.333333%
  }
  
  .basis-8\/12 {
    flex-basis: 66.666667%
  }
  
  .basis-9\/12 {
    flex-basis: 75%
  }
  
  .basis-10\/12 {
    flex-basis: 83.333333%
  }
  
  .basis-11\/12 {
    flex-basis: 91.666667%
  }
  
  .basis-full {
    flex-basis: 100%
  }

  .basis-1\/4\.4{
    flex-basis: 22.5%;
  }
  
  .flex-row {
    flex-direction: row
  }
  
  .flex-row-reverse {
    flex-direction: row-reverse
  }
  
  .flex-col {
    flex-direction: column
  }
  
  .flex-col-reverse {
    flex-direction: column-reverse
  }
  
  .flex-wrap {
    flex-wrap: wrap
  }
  
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse
  }
  
  .flex-nowrap {
    flex-wrap: nowrap
  }
  
  @media (min-width: 640px) {
    .sm\:order-1 {
      order: 1
    }
  
    .sm\:order-2 {
      order: 2
    }
  
    .sm\:order-3 {
      order: 3
    }
  
    .sm\:order-4 {
      order: 4
    }
  
    .sm\:order-5 {
      order: 5
    }
  
    .sm\:order-6 {
      order: 6
    }
  
    .sm\:order-7 {
      order: 7
    }
  
    .sm\:order-8 {
      order: 8
    }
  
    .sm\:order-9 {
      order: 9
    }
  
    .sm\:order-10 {
      order: 10
    }
  
    .sm\:order-11 {
      order: 11
    }
  
    .sm\:order-12 {
      order: 12
    }
  
    .sm\:order-first {
      order: -9999
    }
  
    .sm\:order-last {
      order: 9999
    }
  
    .sm\:order-none {
      order: 0
    }
  
    .sm\:flex-1 {
      flex: 1 1 0%
    }
  
    .sm\:flex-auto {
      flex: 1 1 auto
    }
  
    .sm\:flex-initial {
      flex: 0 1 auto
    }
  
    .sm\:flex-none {
      flex: none
    }
  
    .sm\:shrink {
      flex-shrink: 1
    }
  
    .sm\:shrink-0 {
      flex-shrink: 0
    }
  
    .sm\:grow {
      flex-grow: 1
    }
  
    .sm\:grow-0 {
      flex-grow: 0
    }
  
    .sm\:basis-0 {
      flex-basis: 0px
    }
  
    .sm\:basis-1 {
      flex-basis: 0.25rem
    }
  
    .sm\:basis-2 {
      flex-basis: 0.5rem
    }
  
    .sm\:basis-3 {
      flex-basis: 0.75rem
    }
  
    .sm\:basis-4 {
      flex-basis: 1rem
    }
  
    .sm\:basis-5 {
      flex-basis: 1.25rem
    }
  
    .sm\:basis-6 {
      flex-basis: 1.5rem
    }
  
    .sm\:basis-7 {
      flex-basis: 1.75rem
    }
  
    .sm\:basis-8 {
      flex-basis: 2rem
    }
  
    .sm\:basis-9 {
      flex-basis: 2.25rem
    }
  
    .sm\:basis-10 {
      flex-basis: 2.5rem
    }
  
    .sm\:basis-11 {
      flex-basis: 2.75rem
    }
  
    .sm\:basis-12 {
      flex-basis: 3rem
    }
  
    .sm\:basis-14 {
      flex-basis: 3.5rem
    }
  
    .sm\:basis-16 {
      flex-basis: 4rem
    }
  
    .sm\:basis-20 {
      flex-basis: 5rem
    }
  
    .sm\:basis-24 {
      flex-basis: 6rem
    }
  
    .sm\:basis-28 {
      flex-basis: 7rem
    }
  
    .sm\:basis-32 {
      flex-basis: 8rem
    }
  
    .sm\:basis-36 {
      flex-basis: 9rem
    }
  
    .sm\:basis-40 {
      flex-basis: 10rem
    }
  
    .sm\:basis-44 {
      flex-basis: 11rem
    }
  
    .sm\:basis-48 {
      flex-basis: 12rem
    }
  
    .sm\:basis-52 {
      flex-basis: 13rem
    }
  
    .sm\:basis-56 {
      flex-basis: 14rem
    }
  
    .sm\:basis-60 {
      flex-basis: 15rem
    }
  
    .sm\:basis-64 {
      flex-basis: 16rem
    }
  
    .sm\:basis-72 {
      flex-basis: 18rem
    }
  
    .sm\:basis-80 {
      flex-basis: 20rem
    }
  
    .sm\:basis-96 {
      flex-basis: 24rem
    }
  
    .sm\:basis-auto {
      flex-basis: auto
    }
  
    .sm\:basis-px {
      flex-basis: 1px
    }
  
    .sm\:basis-0\.5 {
      flex-basis: 0.125rem
    }
  
    .sm\:basis-1\.5 {
      flex-basis: 0.375rem
    }
  
    .sm\:basis-2\.5 {
      flex-basis: 0.625rem
    }
  
    .sm\:basis-3\.5 {
      flex-basis: 0.875rem
    }
  
    .sm\:basis-1\/2 {
      flex-basis: 50%
    }
  
    .sm\:basis-1\/3 {
      flex-basis: 33.333333%
    }
  
    .sm\:basis-2\/3 {
      flex-basis: 66.666667%
    }
  
    .sm\:basis-1\/4 {
      flex-basis: 25%
    }
  
    .sm\:basis-2\/4 {
      flex-basis: 50%
    }
  
    .sm\:basis-3\/4 {
      flex-basis: 75%
    }
  
    .sm\:basis-1\/5 {
      flex-basis: 20%
    }
  
    .sm\:basis-2\/5 {
      flex-basis: 40%
    }
  
    .sm\:basis-3\/5 {
      flex-basis: 60%
    }
  
    .sm\:basis-4\/5 {
      flex-basis: 80%
    }
  
    .sm\:basis-1\/6 {
      flex-basis: 16.666667%
    }
  
    .sm\:basis-2\/6 {
      flex-basis: 33.333333%
    }
  
    .sm\:basis-3\/6 {
      flex-basis: 50%
    }
  
    .sm\:basis-4\/6 {
      flex-basis: 66.666667%
    }
  
    .sm\:basis-5\/6 {
      flex-basis: 83.333333%
    }

    .sm\:basis-1\/10 {
      flex-basis: 10%
    }

    .sm\:basis-2\/10 {
      flex-basis: 20%
    }
  
    .sm\:basis-3\/10 {
      flex-basis: 30%
    }

    .sm\:basis-4\/10 {
      flex-basis: 40%
    }

    .sm\:basis-5\/10 {
      flex-basis: 50%
    }
  
    .sm\:basis-6\/10 {
      flex-basis: 60%
    }

    .sm\:basis-7\/10 {
      flex-basis: 70%
    }

    .sm\:basis-8\/10 {
      flex-basis: 80%
    }
  
    .sm\:basis-9\/10 {
      flex-basis: 90%
    }
  
    .sm\:basis-1\/12 {
      flex-basis: 8.333333%
    }
  
    .sm\:basis-2\/12 {
      flex-basis: 16.666667%
    }
  
    .sm\:basis-3\/12 {
      flex-basis: 25%
    }
  
    .sm\:basis-4\/12 {
      flex-basis: 33.333333%
    }
  
    .sm\:basis-5\/12 {
      flex-basis: 41.666667%
    }
  
    .sm\:basis-6\/12 {
      flex-basis: 50%
    }
  
    .sm\:basis-7\/12 {
      flex-basis: 58.333333%
    }
  
    .sm\:basis-8\/12 {
      flex-basis: 66.666667%
    }
  
    .sm\:basis-9\/12 {
      flex-basis: 75%
    }
  
    .sm\:basis-10\/12 {
      flex-basis: 83.333333%
    }
  
    .sm\:basis-11\/12 {
      flex-basis: 91.666667%
    }
  
    .sm\:basis-full {
      flex-basis: 100%
    }
  
    .sm\:flex-row {
      flex-direction: row
    }
  
    .sm\:flex-row-reverse {
      flex-direction: row-reverse
    }
  
    .sm\:flex-col {
      flex-direction: column
    }
  
    .sm\:flex-col-reverse {
      flex-direction: column-reverse
    }
  
    .sm\:flex-wrap {
      flex-wrap: wrap
    }
  
    .sm\:flex-wrap-reverse {
      flex-wrap: wrap-reverse
    }
  
    .sm\:flex-nowrap {
      flex-wrap: nowrap
    }
  }
  
  @media (min-width: 768px) {
    .md\:order-1 {
      order: 1
    }
  
    .md\:order-2 {
      order: 2
    }
  
    .md\:order-3 {
      order: 3
    }
  
    .md\:order-4 {
      order: 4
    }
  
    .md\:order-5 {
      order: 5
    }
  
    .md\:order-6 {
      order: 6
    }
  
    .md\:order-7 {
      order: 7
    }
  
    .md\:order-8 {
      order: 8
    }
  
    .md\:order-9 {
      order: 9
    }
  
    .md\:order-10 {
      order: 10
    }
  
    .md\:order-11 {
      order: 11
    }
  
    .md\:order-12 {
      order: 12
    }
  
    .md\:order-first {
      order: -9999
    }
  
    .md\:order-last {
      order: 9999
    }
  
    .md\:order-none {
      order: 0
    }
  
    .md\:flex-1 {
      flex: 1 1 0%
    }
  
    .md\:flex-auto {
      flex: 1 1 auto
    }
  
    .md\:flex-initial {
      flex: 0 1 auto
    }
  
    .md\:flex-none {
      flex: none
    }
  
    .md\:shrink {
      flex-shrink: 1
    }
  
    .md\:shrink-0 {
      flex-shrink: 0
    }
  
    .md\:grow {
      flex-grow: 1
    }
  
    .md\:grow-0 {
      flex-grow: 0
    }
  
    .md\:basis-0 {
      flex-basis: 0px
    }
  
    .md\:basis-1 {
      flex-basis: 0.25rem
    }
  
    .md\:basis-2 {
      flex-basis: 0.5rem
    }
  
    .md\:basis-3 {
      flex-basis: 0.75rem
    }
  
    .md\:basis-4 {
      flex-basis: 1rem
    }
  
    .md\:basis-5 {
      flex-basis: 1.25rem
    }
  
    .md\:basis-6 {
      flex-basis: 1.5rem
    }
  
    .md\:basis-7 {
      flex-basis: 1.75rem
    }
  
    .md\:basis-8 {
      flex-basis: 2rem
    }
  
    .md\:basis-9 {
      flex-basis: 2.25rem
    }
  
    .md\:basis-10 {
      flex-basis: 2.5rem
    }
  
    .md\:basis-11 {
      flex-basis: 2.75rem
    }
  
    .md\:basis-12 {
      flex-basis: 3rem
    }
  
    .md\:basis-14 {
      flex-basis: 3.5rem
    }
  
    .md\:basis-16 {
      flex-basis: 4rem
    }
  
    .md\:basis-20 {
      flex-basis: 5rem
    }
  
    .md\:basis-24 {
      flex-basis: 6rem
    }
  
    .md\:basis-28 {
      flex-basis: 7rem
    }
  
    .md\:basis-32 {
      flex-basis: 8rem
    }
  
    .md\:basis-36 {
      flex-basis: 9rem
    }
  
    .md\:basis-40 {
      flex-basis: 10rem
    }
  
    .md\:basis-44 {
      flex-basis: 11rem
    }
  
    .md\:basis-48 {
      flex-basis: 12rem
    }
  
    .md\:basis-52 {
      flex-basis: 13rem
    }
  
    .md\:basis-56 {
      flex-basis: 14rem
    }
  
    .md\:basis-60 {
      flex-basis: 15rem
    }
  
    .md\:basis-64 {
      flex-basis: 16rem
    }
  
    .md\:basis-72 {
      flex-basis: 18rem
    }
  
    .md\:basis-80 {
      flex-basis: 20rem
    }
  
    .md\:basis-96 {
      flex-basis: 24rem
    }
  
    .md\:basis-auto {
      flex-basis: auto
    }
  
    .md\:basis-px {
      flex-basis: 1px
    }
  
    .md\:basis-0\.5 {
      flex-basis: 0.125rem
    }
  
    .md\:basis-1\.5 {
      flex-basis: 0.375rem
    }
  
    .md\:basis-2\.5 {
      flex-basis: 0.625rem
    }
  
    .md\:basis-3\.5 {
      flex-basis: 0.875rem
    }
  
    .md\:basis-1\/2 {
      flex-basis: 50%
    }
  
    .md\:basis-1\/3 {
      flex-basis: 33.333333%
    }
  
    .md\:basis-2\/3 {
      flex-basis: 66.666667%
    }
  
    .md\:basis-1\/4 {
      flex-basis: 25%
    }
  
    .md\:basis-2\/4 {
      flex-basis: 50%
    }
  
    .md\:basis-3\/4 {
      flex-basis: 75%
    }
  
    .md\:basis-1\/5 {
      flex-basis: 20%
    }
  
    .md\:basis-2\/5 {
      flex-basis: 40%
    }
  
    .md\:basis-3\/5 {
      flex-basis: 60%
    }
  
    .md\:basis-4\/5 {
      flex-basis: 80%
    }
  
    .md\:basis-1\/6 {
      flex-basis: 16.666667%
    }
  
    .md\:basis-2\/6 {
      flex-basis: 33.333333%
    }
  
    .md\:basis-3\/6 {
      flex-basis: 50%
    }
  
    .md\:basis-4\/6 {
      flex-basis: 66.666667%
    }
  
    .md\:basis-5\/6 {
      flex-basis: 83.333333%
    }

    .md\:basis-1\/10 {
      flex-basis: 10%
    }

    .md\:basis-2\/10 {
      flex-basis: 20%
    }
  
    .md\:basis-3\/10 {
      flex-basis: 30%
    }

    .md\:basis-4\/10 {
      flex-basis: 40%
    }

    .md\:basis-5\/10 {
      flex-basis: 50%
    }
  
    .md\:basis-6\/10 {
      flex-basis: 60%
    }

    .md\:basis-7\/10 {
      flex-basis: 70%
    }

    .md\:basis-8\/10 {
      flex-basis: 80%
    }
  
    .md\:basis-9\/10 {
      flex-basis: 90%
    }
  
    .md\:basis-1\/12 {
      flex-basis: 8.333333%
    }
  
    .md\:basis-2\/12 {
      flex-basis: 16.666667%
    }
  
    .md\:basis-3\/12 {
      flex-basis: 25%
    }
  
    .md\:basis-4\/12 {
      flex-basis: 33.333333%
    }
  
    .md\:basis-5\/12 {
      flex-basis: 41.666667%
    }
  
    .md\:basis-6\/12 {
      flex-basis: 50%
    }
  
    .md\:basis-7\/12 {
      flex-basis: 58.333333%
    }
  
    .md\:basis-8\/12 {
      flex-basis: 66.666667%
    }
  
    .md\:basis-9\/12 {
      flex-basis: 75%
    }
  
    .md\:basis-10\/12 {
      flex-basis: 83.333333%
    }
  
    .md\:basis-11\/12 {
      flex-basis: 91.666667%
    }
  
    .md\:basis-full {
      flex-basis: 100%
    }
  
    .md\:flex-row {
      flex-direction: row
    }
  
    .md\:flex-row-reverse {
      flex-direction: row-reverse
    }
  
    .md\:flex-col {
      flex-direction: column
    }
  
    .md\:flex-col-reverse {
      flex-direction: column-reverse
    }
  
    .md\:flex-wrap {
      flex-wrap: wrap
    }
  
    .md\:flex-wrap-reverse {
      flex-wrap: wrap-reverse
    }
  
    .md\:flex-nowrap {
      flex-wrap: nowrap
    }
  }
  
  @media (min-width: 1024px) {
    .lg\:order-1 {
      order: 1
    }
  
    .lg\:order-2 {
      order: 2
    }
  
    .lg\:order-3 {
      order: 3
    }
  
    .lg\:order-4 {
      order: 4
    }
  
    .lg\:order-5 {
      order: 5
    }
  
    .lg\:order-6 {
      order: 6
    }
  
    .lg\:order-7 {
      order: 7
    }
  
    .lg\:order-8 {
      order: 8
    }
  
    .lg\:order-9 {
      order: 9
    }
  
    .lg\:order-10 {
      order: 10
    }
  
    .lg\:order-11 {
      order: 11
    }
  
    .lg\:order-12 {
      order: 12
    }
  
    .lg\:order-first {
      order: -9999
    }
  
    .lg\:order-last {
      order: 9999
    }
  
    .lg\:order-none {
      order: 0
    }
  
    .lg\:flex-1 {
      flex: 1 1 0%
    }
  
    .lg\:flex-auto {
      flex: 1 1 auto
    }
  
    .lg\:flex-initial {
      flex: 0 1 auto
    }
  
    .lg\:flex-none {
      flex: none
    }
  
    .lg\:shrink {
      flex-shrink: 1
    }
  
    .lg\:shrink-0 {
      flex-shrink: 0
    }
  
    .lg\:grow {
      flex-grow: 1
    }
  
    .lg\:grow-0 {
      flex-grow: 0
    }
  
    .lg\:basis-0 {
      flex-basis: 0px
    }
  
    .lg\:basis-1 {
      flex-basis: 0.25rem
    }
  
    .lg\:basis-2 {
      flex-basis: 0.5rem
    }
  
    .lg\:basis-3 {
      flex-basis: 0.75rem
    }
  
    .lg\:basis-4 {
      flex-basis: 1rem
    }
  
    .lg\:basis-5 {
      flex-basis: 1.25rem
    }
  
    .lg\:basis-6 {
      flex-basis: 1.5rem
    }
  
    .lg\:basis-7 {
      flex-basis: 1.75rem
    }
  
    .lg\:basis-8 {
      flex-basis: 2rem
    }
  
    .lg\:basis-9 {
      flex-basis: 2.25rem
    }
  
    .lg\:basis-10 {
      flex-basis: 2.5rem
    }
  
    .lg\:basis-11 {
      flex-basis: 2.75rem
    }
  
    .lg\:basis-12 {
      flex-basis: 3rem
    }
  
    .lg\:basis-14 {
      flex-basis: 3.5rem
    }
  
    .lg\:basis-16 {
      flex-basis: 4rem
    }
  
    .lg\:basis-20 {
      flex-basis: 5rem
    }
  
    .lg\:basis-24 {
      flex-basis: 6rem
    }
  
    .lg\:basis-28 {
      flex-basis: 7rem
    }
  
    .lg\:basis-32 {
      flex-basis: 8rem
    }
  
    .lg\:basis-36 {
      flex-basis: 9rem
    }
  
    .lg\:basis-40 {
      flex-basis: 10rem
    }
  
    .lg\:basis-44 {
      flex-basis: 11rem
    }
  
    .lg\:basis-48 {
      flex-basis: 12rem
    }
  
    .lg\:basis-52 {
      flex-basis: 13rem
    }
  
    .lg\:basis-56 {
      flex-basis: 14rem
    }
  
    .lg\:basis-60 {
      flex-basis: 15rem
    }
  
    .lg\:basis-64 {
      flex-basis: 16rem
    }
  
    .lg\:basis-72 {
      flex-basis: 18rem
    }
  
    .lg\:basis-80 {
      flex-basis: 20rem
    }
  
    .lg\:basis-96 {
      flex-basis: 24rem
    }
  
    .lg\:basis-auto {
      flex-basis: auto
    }
  
    .lg\:basis-px {
      flex-basis: 1px
    }
  
    .lg\:basis-0\.5 {
      flex-basis: 0.125rem
    }
  
    .lg\:basis-1\.5 {
      flex-basis: 0.375rem
    }
  
    .lg\:basis-2\.5 {
      flex-basis: 0.625rem
    }
  
    .lg\:basis-3\.5 {
      flex-basis: 0.875rem
    }
  
    .lg\:basis-1\/2 {
      flex-basis: 50%
    }
  
    .lg\:basis-1\/3 {
      flex-basis: 33.333333%
    }
  
    .lg\:basis-2\/3 {
      flex-basis: 66.666667%
    }
  
    .lg\:basis-1\/4 {
      flex-basis: 25%
    }
  
    .lg\:basis-2\/4 {
      flex-basis: 50%
    }
  
    .lg\:basis-3\/4 {
      flex-basis: 75%
    }
  
    .lg\:basis-1\/5 {
      flex-basis: 20%
    }
  
    .lg\:basis-2\/5 {
      flex-basis: 40%
    }
  
    .lg\:basis-3\/5 {
      flex-basis: 60%
    }
  
    .lg\:basis-4\/5 {
      flex-basis: 80%
    }
  
    .lg\:basis-1\/6 {
      flex-basis: 16.666667%
    }
  
    .lg\:basis-2\/6 {
      flex-basis: 33.333333%
    }
  
    .lg\:basis-3\/6 {
      flex-basis: 50%
    }
  
    .lg\:basis-4\/6 {
      flex-basis: 66.666667%
    }
  
    .lg\:basis-5\/6 {
      flex-basis: 83.333333%
    }

    .lg\:basis-1\/10 {
      flex-basis: 10%
    }

    .lg\:basis-2\/10 {
      flex-basis: 20%
    }
  
    .lg\:basis-3\/10 {
      flex-basis: 30%
    }

    .lg\:basis-4\/10 {
      flex-basis: 40%
    }

    .lg\:basis-5\/10 {
      flex-basis: 50%
    }
  
    .lg\:basis-6\/10 {
      flex-basis: 60%
    }

    .lg\:basis-7\/10 {
      flex-basis: 70%
    }

    .lg\:basis-8\/10 {
      flex-basis: 80%
    }
  
    .lg\:basis-9\/10 {
      flex-basis: 90%
    }
  
    .lg\:basis-1\/12 {
      flex-basis: 8.333333%
    }
  
    .lg\:basis-2\/12 {
      flex-basis: 16.666667%
    }
  
    .lg\:basis-3\/12 {
      flex-basis: 25%
    }
  
    .lg\:basis-4\/12 {
      flex-basis: 33.333333%
    }
  
    .lg\:basis-5\/12 {
      flex-basis: 41.666667%
    }
  
    .lg\:basis-6\/12 {
      flex-basis: 50%
    }
  
    .lg\:basis-7\/12 {
      flex-basis: 58.333333%
    }
  
    .lg\:basis-8\/12 {
      flex-basis: 66.666667%
    }
  
    .lg\:basis-9\/12 {
      flex-basis: 75%
    }
  
    .lg\:basis-10\/12 {
      flex-basis: 83.333333%
    }
  
    .lg\:basis-11\/12 {
      flex-basis: 91.666667%
    }
  
    .lg\:basis-full {
      flex-basis: 100%
    }
  
    .lg\:flex-row {
      flex-direction: row
    }
  
    .lg\:flex-row-reverse {
      flex-direction: row-reverse
    }
  
    .lg\:flex-col {
      flex-direction: column
    }
  
    .lg\:flex-col-reverse {
      flex-direction: column-reverse
    }
  
    .lg\:flex-wrap {
      flex-wrap: wrap
    }
  
    .lg\:flex-wrap-reverse {
      flex-wrap: wrap-reverse
    }
  
    .lg\:flex-nowrap {
      flex-wrap: nowrap
    }
  }
  
  @media (min-width: 1280px) {
    .xl\:order-1 {
      order: 1
    }
  
    .xl\:order-2 {
      order: 2
    }
  
    .xl\:order-3 {
      order: 3
    }
  
    .xl\:order-4 {
      order: 4
    }
  
    .xl\:order-5 {
      order: 5
    }
  
    .xl\:order-6 {
      order: 6
    }
  
    .xl\:order-7 {
      order: 7
    }
  
    .xl\:order-8 {
      order: 8
    }
  
    .xl\:order-9 {
      order: 9
    }
  
    .xl\:order-10 {
      order: 10
    }
  
    .xl\:order-11 {
      order: 11
    }
  
    .xl\:order-12 {
      order: 12
    }
  
    .xl\:order-first {
      order: -9999
    }
  
    .xl\:order-last {
      order: 9999
    }
  
    .xl\:order-none {
      order: 0
    }
  
    .xl\:flex-1 {
      flex: 1 1 0%
    }
  
    .xl\:flex-auto {
      flex: 1 1 auto
    }
  
    .xl\:flex-initial {
      flex: 0 1 auto
    }
  
    .xl\:flex-none {
      flex: none
    }
  
    .xl\:shrink {
      flex-shrink: 1
    }
  
    .xl\:shrink-0 {
      flex-shrink: 0
    }
  
    .xl\:grow {
      flex-grow: 1
    }
  
    .xl\:grow-0 {
      flex-grow: 0
    }
  
    .xl\:basis-0 {
      flex-basis: 0px
    }
  
    .xl\:basis-1 {
      flex-basis: 0.25rem
    }
  
    .xl\:basis-2 {
      flex-basis: 0.5rem
    }
  
    .xl\:basis-3 {
      flex-basis: 0.75rem
    }
  
    .xl\:basis-4 {
      flex-basis: 1rem
    }
  
    .xl\:basis-5 {
      flex-basis: 1.25rem
    }
  
    .xl\:basis-6 {
      flex-basis: 1.5rem
    }
  
    .xl\:basis-7 {
      flex-basis: 1.75rem
    }
  
    .xl\:basis-8 {
      flex-basis: 2rem
    }
  
    .xl\:basis-9 {
      flex-basis: 2.25rem
    }
  
    .xl\:basis-10 {
      flex-basis: 2.5rem
    }
  
    .xl\:basis-11 {
      flex-basis: 2.75rem
    }
  
    .xl\:basis-12 {
      flex-basis: 3rem
    }
  
    .xl\:basis-14 {
      flex-basis: 3.5rem
    }
  
    .xl\:basis-16 {
      flex-basis: 4rem
    }
  
    .xl\:basis-20 {
      flex-basis: 5rem
    }
  
    .xl\:basis-24 {
      flex-basis: 6rem
    }
  
    .xl\:basis-28 {
      flex-basis: 7rem
    }
  
    .xl\:basis-32 {
      flex-basis: 8rem
    }
  
    .xl\:basis-36 {
      flex-basis: 9rem
    }
  
    .xl\:basis-40 {
      flex-basis: 10rem
    }
  
    .xl\:basis-44 {
      flex-basis: 11rem
    }
  
    .xl\:basis-48 {
      flex-basis: 12rem
    }
  
    .xl\:basis-52 {
      flex-basis: 13rem
    }
  
    .xl\:basis-56 {
      flex-basis: 14rem
    }
  
    .xl\:basis-60 {
      flex-basis: 15rem
    }
  
    .xl\:basis-64 {
      flex-basis: 16rem
    }
  
    .xl\:basis-72 {
      flex-basis: 18rem
    }
  
    .xl\:basis-80 {
      flex-basis: 20rem
    }
  
    .xl\:basis-96 {
      flex-basis: 24rem
    }
  
    .xl\:basis-auto {
      flex-basis: auto
    }
  
    .xl\:basis-px {
      flex-basis: 1px
    }
  
    .xl\:basis-0\.5 {
      flex-basis: 0.125rem
    }
  
    .xl\:basis-1\.5 {
      flex-basis: 0.375rem
    }
  
    .xl\:basis-2\.5 {
      flex-basis: 0.625rem
    }
  
    .xl\:basis-3\.5 {
      flex-basis: 0.875rem
    }
  
    .xl\:basis-1\/2 {
      flex-basis: 50%
    }
  
    .xl\:basis-1\/3 {
      flex-basis: 33.333333%
    }
  
    .xl\:basis-2\/3 {
      flex-basis: 66.666667%
    }
  
    .xl\:basis-1\/4 {
      flex-basis: 25%
    }
  
    .xl\:basis-2\/4 {
      flex-basis: 50%
    }
  
    .xl\:basis-3\/4 {
      flex-basis: 75%
    }
  
    .xl\:basis-1\/5 {
      flex-basis: 20%
    }
  
    .xl\:basis-2\/5 {
      flex-basis: 40%
    }
  
    .xl\:basis-3\/5 {
      flex-basis: 60%
    }
  
    .xl\:basis-4\/5 {
      flex-basis: 80%
    }
  
    .xl\:basis-1\/6 {
      flex-basis: 16.666667%
    }
  
    .xl\:basis-2\/6 {
      flex-basis: 33.333333%
    }
  
    .xl\:basis-3\/6 {
      flex-basis: 50%
    }
  
    .xl\:basis-4\/6 {
      flex-basis: 66.666667%
    }
  
    .xl\:basis-5\/6 {
      flex-basis: 83.333333%
    }

    .xl\:basis-1\/10 {
      flex-basis: 10%
    }

    .xl\:basis-2\/10 {
      flex-basis: 20%
    }
  
    .xl\:basis-3\/10 {
      flex-basis: 30%
    }

    .xl\:basis-4\/10 {
      flex-basis: 40%
    }

    .xl\:basis-5\/10 {
      flex-basis: 50%
    }
  
    .xl\:basis-6\/10 {
      flex-basis: 60%
    }

    .xl\:basis-7\/10 {
      flex-basis: 70%
    }

    .xl\:basis-8\/10 {
      flex-basis: 80%
    }
  
    .xl\:basis-9\/10 {
      flex-basis: 90%
    }
  
    .xl\:basis-1\/12 {
      flex-basis: 8.333333%
    }
  
    .xl\:basis-2\/12 {
      flex-basis: 16.666667%
    }
  
    .xl\:basis-3\/12 {
      flex-basis: 25%
    }
  
    .xl\:basis-4\/12 {
      flex-basis: 33.333333%
    }
  
    .xl\:basis-5\/12 {
      flex-basis: 41.666667%
    }
  
    .xl\:basis-6\/12 {
      flex-basis: 50%
    }
  
    .xl\:basis-7\/12 {
      flex-basis: 58.333333%
    }
  
    .xl\:basis-8\/12 {
      flex-basis: 66.666667%
    }
  
    .xl\:basis-9\/12 {
      flex-basis: 75%
    }
  
    .xl\:basis-10\/12 {
      flex-basis: 83.333333%
    }
  
    .xl\:basis-11\/12 {
      flex-basis: 91.666667%
    }
  
    .xl\:basis-full {
      flex-basis: 100%
    }
  
    .xl\:flex-row {
      flex-direction: row
    }
  
    .xl\:flex-row-reverse {
      flex-direction: row-reverse
    }
  
    .xl\:flex-col {
      flex-direction: column
    }
  
    .xl\:flex-col-reverse {
      flex-direction: column-reverse
    }
  
    .xl\:flex-wrap {
      flex-wrap: wrap
    }
  
    .xl\:flex-wrap-reverse {
      flex-wrap: wrap-reverse
    }
  
    .xl\:flex-nowrap {
      flex-wrap: nowrap
    }
  }
  
  @media (min-width: 1536px) {
    .\32xl\:order-1 {
      order: 1
    }
  
    .\32xl\:order-2 {
      order: 2
    }
  
    .\32xl\:order-3 {
      order: 3
    }
  
    .\32xl\:order-4 {
      order: 4
    }
  
    .\32xl\:order-5 {
      order: 5
    }
  
    .\32xl\:order-6 {
      order: 6
    }
  
    .\32xl\:order-7 {
      order: 7
    }
  
    .\32xl\:order-8 {
      order: 8
    }
  
    .\32xl\:order-9 {
      order: 9
    }
  
    .\32xl\:order-10 {
      order: 10
    }
  
    .\32xl\:order-11 {
      order: 11
    }
  
    .\32xl\:order-12 {
      order: 12
    }
  
    .\32xl\:order-first {
      order: -9999
    }
  
    .\32xl\:order-last {
      order: 9999
    }
  
    .\32xl\:order-none {
      order: 0
    }
  
    .\32xl\:flex-1 {
      flex: 1 1 0%
    }
  
    .\32xl\:flex-auto {
      flex: 1 1 auto
    }
  
    .\32xl\:flex-initial {
      flex: 0 1 auto
    }
  
    .\32xl\:flex-none {
      flex: none
    }
  
    .\32xl\:shrink {
      flex-shrink: 1
    }
  
    .\32xl\:shrink-0 {
      flex-shrink: 0
    }
  
    .\32xl\:grow {
      flex-grow: 1
    }
  
    .\32xl\:grow-0 {
      flex-grow: 0
    }
  
    .\32xl\:basis-0 {
      flex-basis: 0px
    }
  
    .\32xl\:basis-1 {
      flex-basis: 0.25rem
    }
  
    .\32xl\:basis-2 {
      flex-basis: 0.5rem
    }
  
    .\32xl\:basis-3 {
      flex-basis: 0.75rem
    }
  
    .\32xl\:basis-4 {
      flex-basis: 1rem
    }
  
    .\32xl\:basis-5 {
      flex-basis: 1.25rem
    }
  
    .\32xl\:basis-6 {
      flex-basis: 1.5rem
    }
  
    .\32xl\:basis-7 {
      flex-basis: 1.75rem
    }
  
    .\32xl\:basis-8 {
      flex-basis: 2rem
    }
  
    .\32xl\:basis-9 {
      flex-basis: 2.25rem
    }
  
    .\32xl\:basis-10 {
      flex-basis: 2.5rem
    }
  
    .\32xl\:basis-11 {
      flex-basis: 2.75rem
    }
  
    .\32xl\:basis-12 {
      flex-basis: 3rem
    }
  
    .\32xl\:basis-14 {
      flex-basis: 3.5rem
    }
  
    .\32xl\:basis-16 {
      flex-basis: 4rem
    }
  
    .\32xl\:basis-20 {
      flex-basis: 5rem
    }
  
    .\32xl\:basis-24 {
      flex-basis: 6rem
    }
  
    .\32xl\:basis-28 {
      flex-basis: 7rem
    }
  
    .\32xl\:basis-32 {
      flex-basis: 8rem
    }
  
    .\32xl\:basis-36 {
      flex-basis: 9rem
    }
  
    .\32xl\:basis-40 {
      flex-basis: 10rem
    }
  
    .\32xl\:basis-44 {
      flex-basis: 11rem
    }
  
    .\32xl\:basis-48 {
      flex-basis: 12rem
    }
  
    .\32xl\:basis-52 {
      flex-basis: 13rem
    }
  
    .\32xl\:basis-56 {
      flex-basis: 14rem
    }
  
    .\32xl\:basis-60 {
      flex-basis: 15rem
    }
  
    .\32xl\:basis-64 {
      flex-basis: 16rem
    }
  
    .\32xl\:basis-72 {
      flex-basis: 18rem
    }
  
    .\32xl\:basis-80 {
      flex-basis: 20rem
    }
  
    .\32xl\:basis-96 {
      flex-basis: 24rem
    }
  
    .\32xl\:basis-auto {
      flex-basis: auto
    }
  
    .\32xl\:basis-px {
      flex-basis: 1px
    }
  
    .\32xl\:basis-0\.5 {
      flex-basis: 0.125rem
    }
  
    .\32xl\:basis-1\.5 {
      flex-basis: 0.375rem
    }
  
    .\32xl\:basis-2\.5 {
      flex-basis: 0.625rem
    }
  
    .\32xl\:basis-3\.5 {
      flex-basis: 0.875rem
    }
  
    .\32xl\:basis-1\/2 {
      flex-basis: 50%
    }
  
    .\32xl\:basis-1\/3 {
      flex-basis: 33.333333%
    }
  
    .\32xl\:basis-2\/3 {
      flex-basis: 66.666667%
    }
  
    .\32xl\:basis-1\/4 {
      flex-basis: 25%
    }
  
    .\32xl\:basis-2\/4 {
      flex-basis: 50%
    }
  
    .\32xl\:basis-3\/4 {
      flex-basis: 75%
    }
  
    .\32xl\:basis-1\/5 {
      flex-basis: 20%
    }
  
    .\32xl\:basis-2\/5 {
      flex-basis: 40%
    }
  
    .\32xl\:basis-3\/5 {
      flex-basis: 60%
    }
  
    .\32xl\:basis-4\/5 {
      flex-basis: 80%
    }
  
    .\32xl\:basis-1\/6 {
      flex-basis: 16.666667%
    }
  
    .\32xl\:basis-2\/6 {
      flex-basis: 33.333333%
    }
  
    .\32xl\:basis-3\/6 {
      flex-basis: 50%
    }
  
    .\32xl\:basis-4\/6 {
      flex-basis: 66.666667%
    }
  
    .\32xl\:basis-5\/6 {
      flex-basis: 83.333333%
    }

    .\32xl\:basis-1\/10 {
      flex-basis: 10%
    }

    .\32xl\:basis-2\/10 {
      flex-basis: 20%
    }
  
    .\32xl\:basis-3\/10 {
      flex-basis: 30%
    }

    .\32xl\:basis-4\/10 {
      flex-basis: 40%
    }

    .\32xl\:basis-5\/10 {
      flex-basis: 50%
    }
  
    .\32xl\:basis-6\/10 {
      flex-basis: 60%
    }

    .\32xl\:basis-7\/10 {
      flex-basis: 70%
    }

    .\32xl\:basis-8\/10 {
      flex-basis: 80%
    }
  
    .\32xl\:basis-9\/10 {
      flex-basis: 90%
    }

    .\32xl\:basis-1\/12 {
      flex-basis: 8.333333%
    }
  
    .\32xl\:basis-2\/12 {
      flex-basis: 16.666667%
    }
  
    .\32xl\:basis-3\/12 {
      flex-basis: 25%
    }
  
    .\32xl\:basis-4\/12 {
      flex-basis: 33.333333%
    }
  
    .\32xl\:basis-5\/12 {
      flex-basis: 41.666667%
    }
  
    .\32xl\:basis-6\/12 {
      flex-basis: 50%
    }
  
    .\32xl\:basis-7\/12 {
      flex-basis: 58.333333%
    }
  
    .\32xl\:basis-8\/12 {
      flex-basis: 66.666667%
    }
  
    .\32xl\:basis-9\/12 {
      flex-basis: 75%
    }
  
    .\32xl\:basis-10\/12 {
      flex-basis: 83.333333%
    }
  
    .\32xl\:basis-11\/12 {
      flex-basis: 91.666667%
    }
  
    .\32xl\:basis-full {
      flex-basis: 100%
    }
  
    .\32xl\:flex-row {
      flex-direction: row
    }
  
    .\32xl\:flex-row-reverse {
      flex-direction: row-reverse
    }
  
    .\32xl\:flex-col {
      flex-direction: column
    }
  
    .\32xl\:flex-col-reverse {
      flex-direction: column-reverse
    }
  
    .\32xl\:flex-wrap {
      flex-wrap: wrap
    }
  
    .\32xl\:flex-wrap-reverse {
      flex-wrap: wrap-reverse
    }
  
    .\32xl\:flex-nowrap {
      flex-wrap: nowrap
    }
  }



  .flex-0 {
    max-width: 0%;
  }
  
  .flex-\[0\%\] {
    max-width: 0%;
  }
  
  .flex-1 {
    max-width: 1%;
  }
  
  .flex-\[1\%\] {
    max-width: 1%;
  }
  
  .flex-2 {
    max-width: 2%;
  }
  
  .flex-\[2\%\] {
    max-width: 2%;
  }
  
  .flex-3 {
    max-width: 3%;
  }
  
  .flex-\[3\%\] {
    max-width: 3%;
  }
  
  .flex-4 {
    max-width: 4%;
  }
  
  .flex-\[4\%\] {
    max-width: 4%;
  }
  
  .flex-5 {
    max-width: 5%;
  }
  
  .flex-\[5\%\] {
    max-width: 5%;
  }
  
  .flex-6 {
    max-width: 6%;
  }
  
  .flex-\[6\%\] {
    max-width: 6%;
  }
  
  .flex-7 {
    max-width: 7%;
  }
  
  .flex-\[7\%\] {
    max-width: 7%;
  }
  
  .flex-8 {
    max-width: 8%;
  }
  
  .flex-\[8\%\] {
    max-width: 8%;
  }
  
  .flex-9 {
    max-width: 9%;
  }
  
  .flex-\[9\%\] {
    max-width: 9%;
  }
  
  .flex-10 {
    max-width: 10%;
  }
  
  .flex-\[10\%\] {
    max-width: 10%;
  }
  
  .flex-11 {
    max-width: 11%;
  }
  
  .flex-\[11\%\] {
    max-width: 11%;
  }
  
  .flex-12 {
    max-width: 12%;
  }
  
  .flex-\[12\%\] {
    max-width: 12%;
  }
  
  .flex-13 {
    max-width: 13%;
  }
  
  .flex-\[13\%\] {
    max-width: 13%;
  }
  
  .flex-14 {
    max-width: 14%;
  }
  
  .flex-\[14\%\] {
    max-width: 14%;
  }
  
  .flex-15 {
    max-width: 15%;
  }
  
  .flex-\[15\%\] {
    max-width: 15%;
  }
  
  .flex-16 {
    max-width: 16%;
  }
  
  .flex-\[16\%\] {
    max-width: 16%;
  }
  
  .flex-17 {
    max-width: 17%;
  }
  
  .flex-\[17\%\] {
    max-width: 17%;
  }
  
  .flex-18 {
    max-width: 18%;
  }
  
  .flex-\[18\%\] {
    max-width: 18%;
  }
  
  .flex-19 {
    max-width: 19%;
  }
  
  .flex-\[19\%\] {
    max-width: 19%;
  }
  
  .flex-20 {
    max-width: 20%;
  }
  
  .flex-\[20\%\] {
    max-width: 20%;
  }
  
  .flex-21 {
    max-width: 21%;
  }
  
  .flex-\[21\%\] {
    max-width: 21%;
  }
  
  .flex-22 {
    max-width: 22%;
  }
  
  .flex-\[22\%\] {
    max-width: 22%;
  }
  
  .flex-23 {
    max-width: 23%;
  }
  
  .flex-\[23\%\] {
    max-width: 23%;
  }
  
  .flex-24 {
    max-width: 24%;
  }
  
  .flex-\[24\%\] {
    max-width: 24%;
  }
  
  .flex-25 {
    max-width: 25%;
  }
  
  .flex-\[25\%\] {
    max-width: 25%;
  }
  
  .flex-26 {
    max-width: 26%;
  }
  
  .flex-\[26\%\] {
    max-width: 26%;
  }
  
  .flex-27 {
    max-width: 27%;
  }
  
  .flex-\[27\%\] {
    max-width: 27%;
  }
  
  .flex-28 {
    max-width: 28%;
  }
  
  .flex-\[28\%\] {
    max-width: 28%;
  }
  
  .flex-29 {
    max-width: 29%;
  }
  
  .flex-\[29\%\] {
    max-width: 29%;
  }
  
  .flex-30 {
    max-width: 30%;
  }
  
  .flex-\[30\%\] {
    max-width: 30%;
  }
  
  .flex-31 {
    max-width: 31%;
  }
  
  .flex-\[31\%\] {
    max-width: 31%;
  }
  
  .flex-32 {
    max-width: 32%;
  }
  
  .flex-\[32\%\] {
    max-width: 32%;
  }
  
  .flex-33 {
    max-width: 33%;
  }
  
  .flex-\[33\%\] {
    max-width: 33%;
  }
  
  .flex-34 {
    max-width: 34%;
  }
  
  .flex-\[34\%\] {
    max-width: 34%;
  }
  
  .flex-35 {
    max-width: 35%;
  }
  
  .flex-\[35\%\] {
    max-width: 35%;
  }
  
  .flex-36 {
    max-width: 36%;
  }
  
  .flex-\[36\%\] {
    max-width: 36%;
  }
  
  .flex-37 {
    max-width: 37%;
  }
  
  .flex-\[37\%\] {
    max-width: 37%;
  }
  
  .flex-38 {
    max-width: 38%;
  }
  
  .flex-\[38\%\] {
    max-width: 38%;
  }
  
  .flex-39 {
    max-width: 39%;
  }
  
  .flex-\[39\%\] {
    max-width: 39%;
  }
  
  .flex-40 {
    max-width: 40%;
  }
  
  .flex-\[40\%\] {
    max-width: 40%;
  }
  
  .flex-41 {
    max-width: 41%;
  }
  
  .flex-\[41\%\] {
    max-width: 41%;
  }
  
  .flex-42 {
    max-width: 42%;
  }
  
  .flex-\[42\%\] {
    max-width: 42%;
  }
  
  .flex-43 {
    max-width: 43%;
  }
  
  .flex-\[43\%\] {
    max-width: 43%;
  }
  
  .flex-44 {
    max-width: 44%;
  }
  
  .flex-\[44\%\] {
    max-width: 44%;
  }
  
  .flex-45 {
    max-width: 45%;
  }
  
  .flex-\[45\%\] {
    max-width: 45%;
  }
  
  .flex-46 {
    max-width: 46%;
  }
  
  .flex-\[46\%\] {
    max-width: 46%;
  }
  
  .flex-47 {
    max-width: 47%;
  }
  
  .flex-\[47\%\] {
    max-width: 47%;
  }
  
  .flex-48 {
    max-width: 48%;
  }
  
  .flex-\[48\%\] {
    max-width: 48%;
  }
  
  .flex-49 {
    max-width: 49%;
  }
  
  .flex-\[49\%\] {
    max-width: 49%;
  }
  
  .flex-50 {
    max-width: 50%;
  }
  
  .flex-\[50\%\] {
    max-width: 50%;
  }
  
  .flex-51 {
    max-width: 51%;
  }
  
  .flex-\[51\%\] {
    max-width: 51%;
  }
  
  .flex-52 {
    max-width: 52%;
  }
  
  .flex-\[52\%\] {
    max-width: 52%;
  }
  
  .flex-53 {
    max-width: 53%;
  }
  
  .flex-\[53\%\] {
    max-width: 53%;
  }
  
  .flex-54 {
    max-width: 54%;
  }
  
  .flex-\[54\%\] {
    max-width: 54%;
  }
  
  .flex-55 {
    max-width: 55%;
  }
  
  .flex-\[55\%\] {
    max-width: 55%;
  }
  
  .flex-56 {
    max-width: 56%;
  }
  
  .flex-\[56\%\] {
    max-width: 56%;
  }
  
  .flex-57 {
    max-width: 57%;
  }
  
  .flex-\[57\%\] {
    max-width: 57%;
  }
  
  .flex-58 {
    max-width: 58%;
  }
  
  .flex-\[58\%\] {
    max-width: 58%;
  }
  
  .flex-59 {
    max-width: 59%;
  }
  
  .flex-\[59\%\] {
    max-width: 59%;
  }
  
  .flex-60 {
    max-width: 60%;
  }
  
  .flex-\[60\%\] {
    max-width: 60%;
  }
  
  .flex-61 {
    max-width: 61%;
  }
  
  .flex-\[61\%\] {
    max-width: 61%;
  }
  
  .flex-62 {
    max-width: 62%;
  }
  
  .flex-\[62\%\] {
    max-width: 62%;
  }
  
  .flex-63 {
    max-width: 63%;
  }
  
  .flex-\[63\%\] {
    max-width: 63%;
  }
  
  .flex-64 {
    max-width: 64%;
  }
  
  .flex-\[64\%\] {
    max-width: 64%;
  }
  
  .flex-65 {
    max-width: 65%;
  }
  
  .flex-\[65\%\] {
    max-width: 65%;
  }
  
  .flex-66 {
    max-width: 66%;
  }
  
  .flex-\[66\%\] {
    max-width: 66%;
  }
  
  .flex-67 {
    max-width: 67%;
  }
  
  .flex-\[67\%\] {
    max-width: 67%;
  }
  
  .flex-68 {
    max-width: 68%;
  }
  
  .flex-\[68\%\] {
    max-width: 68%;
  }
  
  .flex-69 {
    max-width: 69%;
  }
  
  .flex-\[69\%\] {
    max-width: 69%;
  }
  
  .flex-70 {
    max-width: 70%;
  }
  
  .flex-\[70\%\] {
    max-width: 70%;
  }
  
  .flex-71 {
    max-width: 71%;
  }
  
  .flex-\[71\%\] {
    max-width: 71%;
  }
  
  .flex-72 {
    max-width: 72%;
  }
  
  .flex-\[72\%\] {
    max-width: 72%;
  }
  
  .flex-73 {
    max-width: 73%;
  }
  
  .flex-\[73\%\] {
    max-width: 73%;
  }
  
  .flex-74 {
    max-width: 74%;
  }
  
  .flex-\[74\%\] {
    max-width: 74%;
  }
  
  .flex-75 {
    max-width: 75%;
  }
  
  .flex-\[75\%\] {
    max-width: 75%;
  }
  
  .flex-76 {
    max-width: 76%;
  }
  
  .flex-\[76\%\] {
    max-width: 76%;
  }
  
  .flex-77 {
    max-width: 77%;
  }
  
  .flex-\[77\%\] {
    max-width: 77%;
  }
  
  .flex-78 {
    max-width: 78%;
  }
  
  .flex-\[78\%\] {
    max-width: 78%;
  }
  
  .flex-79 {
    max-width: 79%;
  }
  
  .flex-\[79\%\] {
    max-width: 79%;
  }
  
  .flex-80 {
    max-width: 80%;
  }
  
  .flex-\[80\%\] {
    max-width: 80%;
  }
  
  .flex-81 {
    max-width: 81%;
  }
  
  .flex-\[81\%\] {
    max-width: 81%;
  }
  
  .flex-82 {
    max-width: 82%;
  }
  
  .flex-\[82\%\] {
    max-width: 82%;
  }
  
  .flex-83 {
    max-width: 83%;
  }
  
  .flex-\[83\%\] {
    max-width: 83%;
  }
  
  .flex-84 {
    max-width: 84%;
  }
  
  .flex-\[84\%\] {
    max-width: 84%;
  }
  
  .flex-85 {
    max-width: 85%;
  }
  
  .flex-\[85\%\] {
    max-width: 85%;
  }
  
  .flex-86 {
    max-width: 86%;
  }
  
  .flex-\[86\%\] {
    max-width: 86%;
  }
  
  .flex-87 {
    max-width: 87%;
  }
  
  .flex-\[87\%\] {
    max-width: 87%;
  }
  
  .flex-88 {
    max-width: 88%;
  }
  
  .flex-\[88\%\] {
    max-width: 88%;
  }
  
  .flex-89 {
    max-width: 89%;
  }
  
  .flex-\[89\%\] {
    max-width: 89%;
  }
  
  .flex-90 {
    max-width: 90%;
  }
  
  .flex-\[90\%\] {
    max-width: 90%;
  }
  
  .flex-91 {
    max-width: 91%;
  }
  
  .flex-\[91\%\] {
    max-width: 91%;
  }
  
  .flex-92 {
    max-width: 92%;
  }
  
  .flex-\[92\%\] {
    max-width: 92%;
  }
  
  .flex-93 {
    max-width: 93%;
  }
  
  .flex-\[93\%\] {
    max-width: 93%;
  }
  
  .flex-94 {
    max-width: 94%;
  }
  
  .flex-\[94\%\] {
    max-width: 94%;
  }
  
  .flex-95 {
    max-width: 95%;
  }
  
  .flex-\[95\%\] {
    max-width: 95%;
  }
  
  .flex-96 {
    max-width: 96%;
  }
  
  .flex-\[96\%\] {
    max-width: 96%;
  }
  
  .flex-97 {
    max-width: 97%;
  }
  
  .flex-\[97\%\] {
    max-width: 97%;
  }
  
  .flex-98 {
    max-width: 98%;
  }
  
  .flex-\[98\%\] {
    max-width: 98%;
  }
  
  .flex-99 {
    max-width: 99%;
  }
  
  .flex-\[99\%\] {
    max-width: 99%;
  }
  
  .flex-100 {
    max-width: 100%;
  }
  
  .flex-\[100\%\] {
    max-width: 100%;
  }
  
  .gap-\[0px\] {
    gap: 0px;
  }
  
  .gap-\[5px\] {
    gap: 5px;
  }
  
  .gap-\[10px\] {
    gap: 10px;
  }
  
  .gap-\[15px\] {
    gap: 15px;
  }
  
  .gap-\[20px\] {
    gap: 20px;
  }
  
  .gap-\[25px\] {
    gap: 25px;
  }
  
  .gap-\[30px\] {
    gap: 30px;
  }
  
  .gap-\[35px\] {
    gap: 35px;
  }
  
  .gap-\[40px\] {
    gap: 40px;
  }
  
  .gap-\[45px\] {
    gap: 45px;
  }
  
  .gap-\[50px\] {
    gap: 50px;
  }
  
  .gap-\[55px\] {
    gap: 55px;
  }
  
  .gap-\[60px\] {
    gap: 60px;
  }
  
  .gap-\[65px\] {
    gap: 65px;
  }
  
  .gap-\[70px\] {
    gap: 70px;
  }
  
  .gap-\[75px\] {
    gap: 75px;
  }
  
  .gap-\[80px\] {
    gap: 80px;
  }
  
  .gap-\[85px\] {
    gap: 85px;
  }
  
  .gap-\[90px\] {
    gap: 90px;
  }
  
  .gap-\[95px\] {
    gap: 95px;
  }
  
  .gap-\[100px\] {
    gap: 100px;
  }
  
  @media (min-width: 640px) {
    .sm\:flex-0 {
      max-width: 0%;
    }
  
    .sm\:flex-\[0\%\] {
      max-width: 0%;
    }
  
    .sm\:flex-1 {
      max-width: 1%;
    }
  
    .sm\:flex-\[1\%\] {
      max-width: 1%;
    }
  
    .sm\:flex-2 {
      max-width: 2%;
    }
  
    .sm\:flex-\[2\%\] {
      max-width: 2%;
    }
  
    .sm\:flex-3 {
      max-width: 3%;
    }
  
    .sm\:flex-\[3\%\] {
      max-width: 3%;
    }
  
    .sm\:flex-4 {
      max-width: 4%;
    }
  
    .sm\:flex-\[4\%\] {
      max-width: 4%;
    }
  
    .sm\:flex-5 {
      max-width: 5%;
    }
  
    .sm\:flex-\[5\%\] {
      max-width: 5%;
    }
  
    .sm\:flex-6 {
      max-width: 6%;
    }
  
    .sm\:flex-\[6\%\] {
      max-width: 6%;
    }
  
    .sm\:flex-7 {
      max-width: 7%;
    }
  
    .sm\:flex-\[7\%\] {
      max-width: 7%;
    }
  
    .sm\:flex-8 {
      max-width: 8%;
    }
  
    .sm\:flex-\[8\%\] {
      max-width: 8%;
    }
  
    .sm\:flex-9 {
      max-width: 9%;
    }
  
    .sm\:flex-\[9\%\] {
      max-width: 9%;
    }
  
    .sm\:flex-10 {
      max-width: 10%;
    }
  
    .sm\:flex-\[10\%\] {
      max-width: 10%;
    }
  
    .sm\:flex-11 {
      max-width: 11%;
    }
  
    .sm\:flex-\[11\%\] {
      max-width: 11%;
    }
  
    .sm\:flex-12 {
      max-width: 12%;
    }
  
    .sm\:flex-\[12\%\] {
      max-width: 12%;
    }
  
    .sm\:flex-13 {
      max-width: 13%;
    }
  
    .sm\:flex-\[13\%\] {
      max-width: 13%;
    }
  
    .sm\:flex-14 {
      max-width: 14%;
    }
  
    .sm\:flex-\[14\%\] {
      max-width: 14%;
    }
  
    .sm\:flex-15 {
      max-width: 15%;
    }
  
    .sm\:flex-\[15\%\] {
      max-width: 15%;
    }
  
    .sm\:flex-16 {
      max-width: 16%;
    }
  
    .sm\:flex-\[16\%\] {
      max-width: 16%;
    }
  
    .sm\:flex-17 {
      max-width: 17%;
    }
  
    .sm\:flex-\[17\%\] {
      max-width: 17%;
    }
  
    .sm\:flex-18 {
      max-width: 18%;
    }
  
    .sm\:flex-\[18\%\] {
      max-width: 18%;
    }
  
    .sm\:flex-19 {
      max-width: 19%;
    }
  
    .sm\:flex-\[19\%\] {
      max-width: 19%;
    }
  
    .sm\:flex-20 {
      max-width: 20%;
    }
  
    .sm\:flex-\[20\%\] {
      max-width: 20%;
    }
  
    .sm\:flex-21 {
      max-width: 21%;
    }
  
    .sm\:flex-\[21\%\] {
      max-width: 21%;
    }
  
    .sm\:flex-22 {
      max-width: 22%;
    }
  
    .sm\:flex-\[22\%\] {
      max-width: 22%;
    }
  
    .sm\:flex-23 {
      max-width: 23%;
    }
  
    .sm\:flex-\[23\%\] {
      max-width: 23%;
    }
  
    .sm\:flex-24 {
      max-width: 24%;
    }
  
    .sm\:flex-\[24\%\] {
      max-width: 24%;
    }
  
    .sm\:flex-25 {
      max-width: 25%;
    }
  
    .sm\:flex-\[25\%\] {
      max-width: 25%;
    }
  
    .sm\:flex-26 {
      max-width: 26%;
    }
  
    .sm\:flex-\[26\%\] {
      max-width: 26%;
    }
  
    .sm\:flex-27 {
      max-width: 27%;
    }
  
    .sm\:flex-\[27\%\] {
      max-width: 27%;
    }
  
    .sm\:flex-28 {
      max-width: 28%;
    }
  
    .sm\:flex-\[28\%\] {
      max-width: 28%;
    }
  
    .sm\:flex-29 {
      max-width: 29%;
    }
  
    .sm\:flex-\[29\%\] {
      max-width: 29%;
    }
  
    .sm\:flex-30 {
      max-width: 30%;
    }
  
    .sm\:flex-\[30\%\] {
      max-width: 30%;
    }
  
    .sm\:flex-31 {
      max-width: 31%;
    }
  
    .sm\:flex-\[31\%\] {
      max-width: 31%;
    }
  
    .sm\:flex-32 {
      max-width: 32%;
    }
  
    .sm\:flex-\[32\%\] {
      max-width: 32%;
    }
  
    .sm\:flex-33 {
      max-width: 33%;
    }
  
    .sm\:flex-\[33\%\] {
      max-width: 33%;
    }
  
    .sm\:flex-34 {
      max-width: 34%;
    }
  
    .sm\:flex-\[34\%\] {
      max-width: 34%;
    }
  
    .sm\:flex-35 {
      max-width: 35%;
    }
  
    .sm\:flex-\[35\%\] {
      max-width: 35%;
    }
  
    .sm\:flex-36 {
      max-width: 36%;
    }
  
    .sm\:flex-\[36\%\] {
      max-width: 36%;
    }
  
    .sm\:flex-37 {
      max-width: 37%;
    }
  
    .sm\:flex-\[37\%\] {
      max-width: 37%;
    }
  
    .sm\:flex-38 {
      max-width: 38%;
    }
  
    .sm\:flex-\[38\%\] {
      max-width: 38%;
    }
  
    .sm\:flex-39 {
      max-width: 39%;
    }
  
    .sm\:flex-\[39\%\] {
      max-width: 39%;
    }
  
    .sm\:flex-40 {
      max-width: 40%;
    }
  
    .sm\:flex-\[40\%\] {
      max-width: 40%;
    }
  
    .sm\:flex-41 {
      max-width: 41%;
    }
  
    .sm\:flex-\[41\%\] {
      max-width: 41%;
    }
  
    .sm\:flex-42 {
      max-width: 42%;
    }
  
    .sm\:flex-\[42\%\] {
      max-width: 42%;
    }
  
    .sm\:flex-43 {
      max-width: 43%;
    }
  
    .sm\:flex-\[43\%\] {
      max-width: 43%;
    }
  
    .sm\:flex-44 {
      max-width: 44%;
    }
  
    .sm\:flex-\[44\%\] {
      max-width: 44%;
    }
  
    .sm\:flex-45 {
      max-width: 45%;
    }
  
    .sm\:flex-\[45\%\] {
      max-width: 45%;
    }
  
    .sm\:flex-46 {
      max-width: 46%;
    }
  
    .sm\:flex-\[46\%\] {
      max-width: 46%;
    }
  
    .sm\:flex-47 {
      max-width: 47%;
    }
  
    .sm\:flex-\[47\%\] {
      max-width: 47%;
    }
  
    .sm\:flex-48 {
      max-width: 48%;
    }
  
    .sm\:flex-\[48\%\] {
      max-width: 48%;
    }
  
    .sm\:flex-49 {
      max-width: 49%;
    }
  
    .sm\:flex-\[49\%\] {
      max-width: 49%;
    }
  
    .sm\:flex-50 {
      max-width: 50%;
    }
  
    .sm\:flex-\[50\%\] {
      max-width: 50%;
    }
  
    .sm\:flex-51 {
      max-width: 51%;
    }
  
    .sm\:flex-\[51\%\] {
      max-width: 51%;
    }
  
    .sm\:flex-52 {
      max-width: 52%;
    }
  
    .sm\:flex-\[52\%\] {
      max-width: 52%;
    }
  
    .sm\:flex-53 {
      max-width: 53%;
    }
  
    .sm\:flex-\[53\%\] {
      max-width: 53%;
    }
  
    .sm\:flex-54 {
      max-width: 54%;
    }
  
    .sm\:flex-\[54\%\] {
      max-width: 54%;
    }
  
    .sm\:flex-55 {
      max-width: 55%;
    }
  
    .sm\:flex-\[55\%\] {
      max-width: 55%;
    }
  
    .sm\:flex-56 {
      max-width: 56%;
    }
  
    .sm\:flex-\[56\%\] {
      max-width: 56%;
    }
  
    .sm\:flex-57 {
      max-width: 57%;
    }
  
    .sm\:flex-\[57\%\] {
      max-width: 57%;
    }
  
    .sm\:flex-58 {
      max-width: 58%;
    }
  
    .sm\:flex-\[58\%\] {
      max-width: 58%;
    }
  
    .sm\:flex-59 {
      max-width: 59%;
    }
  
    .sm\:flex-\[59\%\] {
      max-width: 59%;
    }
  
    .sm\:flex-60 {
      max-width: 60%;
    }
  
    .sm\:flex-\[60\%\] {
      max-width: 60%;
    }
  
    .sm\:flex-61 {
      max-width: 61%;
    }
  
    .sm\:flex-\[61\%\] {
      max-width: 61%;
    }
  
    .sm\:flex-62 {
      max-width: 62%;
    }
  
    .sm\:flex-\[62\%\] {
      max-width: 62%;
    }
  
    .sm\:flex-63 {
      max-width: 63%;
    }
  
    .sm\:flex-\[63\%\] {
      max-width: 63%;
    }
  
    .sm\:flex-64 {
      max-width: 64%;
    }
  
    .sm\:flex-\[64\%\] {
      max-width: 64%;
    }
  
    .sm\:flex-65 {
      max-width: 65%;
    }
  
    .sm\:flex-\[65\%\] {
      max-width: 65%;
    }
  
    .sm\:flex-66 {
      max-width: 66%;
    }
  
    .sm\:flex-\[66\%\] {
      max-width: 66%;
    }
  
    .sm\:flex-67 {
      max-width: 67%;
    }
  
    .sm\:flex-\[67\%\] {
      max-width: 67%;
    }
  
    .sm\:flex-68 {
      max-width: 68%;
    }
  
    .sm\:flex-\[68\%\] {
      max-width: 68%;
    }
  
    .sm\:flex-69 {
      max-width: 69%;
    }
  
    .sm\:flex-\[69\%\] {
      max-width: 69%;
    }
  
    .sm\:flex-70 {
      max-width: 70%;
    }
  
    .sm\:flex-\[70\%\] {
      max-width: 70%;
    }
  
    .sm\:flex-71 {
      max-width: 71%;
    }
  
    .sm\:flex-\[71\%\] {
      max-width: 71%;
    }
  
    .sm\:flex-72 {
      max-width: 72%;
    }
  
    .sm\:flex-\[72\%\] {
      max-width: 72%;
    }
  
    .sm\:flex-73 {
      max-width: 73%;
    }
  
    .sm\:flex-\[73\%\] {
      max-width: 73%;
    }
  
    .sm\:flex-74 {
      max-width: 74%;
    }
  
    .sm\:flex-\[74\%\] {
      max-width: 74%;
    }
  
    .sm\:flex-75 {
      max-width: 75%;
    }
  
    .sm\:flex-\[75\%\] {
      max-width: 75%;
    }
  
    .sm\:flex-76 {
      max-width: 76%;
    }
  
    .sm\:flex-\[76\%\] {
      max-width: 76%;
    }
  
    .sm\:flex-77 {
      max-width: 77%;
    }
  
    .sm\:flex-\[77\%\] {
      max-width: 77%;
    }
  
    .sm\:flex-78 {
      max-width: 78%;
    }
  
    .sm\:flex-\[78\%\] {
      max-width: 78%;
    }
  
    .sm\:flex-79 {
      max-width: 79%;
    }
  
    .sm\:flex-\[79\%\] {
      max-width: 79%;
    }
  
    .sm\:flex-80 {
      max-width: 80%;
    }
  
    .sm\:flex-\[80\%\] {
      max-width: 80%;
    }
  
    .sm\:flex-81 {
      max-width: 81%;
    }
  
    .sm\:flex-\[81\%\] {
      max-width: 81%;
    }
  
    .sm\:flex-82 {
      max-width: 82%;
    }
  
    .sm\:flex-\[82\%\] {
      max-width: 82%;
    }
  
    .sm\:flex-83 {
      max-width: 83%;
    }
  
    .sm\:flex-\[83\%\] {
      max-width: 83%;
    }
  
    .sm\:flex-84 {
      max-width: 84%;
    }
  
    .sm\:flex-\[84\%\] {
      max-width: 84%;
    }
  
    .sm\:flex-85 {
      max-width: 85%;
    }
  
    .sm\:flex-\[85\%\] {
      max-width: 85%;
    }
  
    .sm\:flex-86 {
      max-width: 86%;
    }
  
    .sm\:flex-\[86\%\] {
      max-width: 86%;
    }
  
    .sm\:flex-87 {
      max-width: 87%;
    }
  
    .sm\:flex-\[87\%\] {
      max-width: 87%;
    }
  
    .sm\:flex-88 {
      max-width: 88%;
    }
  
    .sm\:flex-\[88\%\] {
      max-width: 88%;
    }
  
    .sm\:flex-89 {
      max-width: 89%;
    }
  
    .sm\:flex-\[89\%\] {
      max-width: 89%;
    }
  
    .sm\:flex-90 {
      max-width: 90%;
    }
  
    .sm\:flex-\[90\%\] {
      max-width: 90%;
    }
  
    .sm\:flex-91 {
      max-width: 91%;
    }
  
    .sm\:flex-\[91\%\] {
      max-width: 91%;
    }
  
    .sm\:flex-92 {
      max-width: 92%;
    }
  
    .sm\:flex-\[92\%\] {
      max-width: 92%;
    }
  
    .sm\:flex-93 {
      max-width: 93%;
    }
  
    .sm\:flex-\[93\%\] {
      max-width: 93%;
    }
  
    .sm\:flex-94 {
      max-width: 94%;
    }
  
    .sm\:flex-\[94\%\] {
      max-width: 94%;
    }
  
    .sm\:flex-95 {
      max-width: 95%;
    }
  
    .sm\:flex-\[95\%\] {
      max-width: 95%;
    }
  
    .sm\:flex-96 {
      max-width: 96%;
    }
  
    .sm\:flex-\[96\%\] {
      max-width: 96%;
    }
  
    .sm\:flex-97 {
      max-width: 97%;
    }
  
    .sm\:flex-\[97\%\] {
      max-width: 97%;
    }
  
    .sm\:flex-98 {
      max-width: 98%;
    }
  
    .sm\:flex-\[98\%\] {
      max-width: 98%;
    }
  
    .sm\:flex-99 {
      max-width: 99%;
    }
  
    .sm\:flex-\[99\%\] {
      max-width: 99%;
    }
  
    .sm\:flex-100 {
      max-width: 100%;
    }
  
    .sm\:flex-\[100\%\] {
      max-width: 100%;
    }
  
    
  }
  @media (min-width: 768px) {
    .md\:flex-0 {
      max-width: 0%;
    }
  
    .md\:flex-\[0\%\] {
      max-width: 0%;
    }
  
    .md\:flex-1 {
      max-width: 1%;
    }
  
    .md\:flex-\[1\%\] {
      max-width: 1%;
    }
  
    .md\:flex-2 {
      max-width: 2%;
    }
  
    .md\:flex-\[2\%\] {
      max-width: 2%;
    }
  
    .md\:flex-3 {
      max-width: 3%;
    }
  
    .md\:flex-\[3\%\] {
      max-width: 3%;
    }
  
    .md\:flex-4 {
      max-width: 4%;
    }
  
    .md\:flex-\[4\%\] {
      max-width: 4%;
    }
  
    .md\:flex-5 {
      max-width: 5%;
    }
  
    .md\:flex-\[5\%\] {
      max-width: 5%;
    }
  
    .md\:flex-6 {
      max-width: 6%;
    }
  
    .md\:flex-\[6\%\] {
      max-width: 6%;
    }
  
    .md\:flex-7 {
      max-width: 7%;
    }
  
    .md\:flex-\[7\%\] {
      max-width: 7%;
    }
  
    .md\:flex-8 {
      max-width: 8%;
    }
  
    .md\:flex-\[8\%\] {
      max-width: 8%;
    }
  
    .md\:flex-9 {
      max-width: 9%;
    }
  
    .md\:flex-\[9\%\] {
      max-width: 9%;
    }
  
    .md\:flex-10 {
      max-width: 10%;
    }
  
    .md\:flex-\[10\%\] {
      max-width: 10%;
    }
  
    .md\:flex-11 {
      max-width: 11%;
    }
  
    .md\:flex-\[11\%\] {
      max-width: 11%;
    }
  
    .md\:flex-12 {
      max-width: 12%;
    }
  
    .md\:flex-\[12\%\] {
      max-width: 12%;
    }
  
    .md\:flex-13 {
      max-width: 13%;
    }
  
    .md\:flex-\[13\%\] {
      max-width: 13%;
    }
  
    .md\:flex-14 {
      max-width: 14%;
    }
  
    .md\:flex-\[14\%\] {
      max-width: 14%;
    }
  
    .md\:flex-15 {
      max-width: 15%;
    }
  
    .md\:flex-\[15\%\] {
      max-width: 15%;
    }
  
    .md\:flex-16 {
      max-width: 16%;
    }
  
    .md\:flex-\[16\%\] {
      max-width: 16%;
    }
  
    .md\:flex-17 {
      max-width: 17%;
    }
  
    .md\:flex-\[17\%\] {
      max-width: 17%;
    }
  
    .md\:flex-18 {
      max-width: 18%;
    }
  
    .md\:flex-\[18\%\] {
      max-width: 18%;
    }
  
    .md\:flex-19 {
      max-width: 19%;
    }
  
    .md\:flex-\[19\%\] {
      max-width: 19%;
    }
  
    .md\:flex-20 {
      max-width: 20%;
    }
  
    .md\:flex-\[20\%\] {
      max-width: 20%;
    }
  
    .md\:flex-21 {
      max-width: 21%;
    }
  
    .md\:flex-\[21\%\] {
      max-width: 21%;
    }
  
    .md\:flex-22 {
      max-width: 22%;
    }
  
    .md\:flex-\[22\%\] {
      max-width: 22%;
    }
  
    .md\:flex-23 {
      max-width: 23%;
    }
  
    .md\:flex-\[23\%\] {
      max-width: 23%;
    }
  
    .md\:flex-24 {
      max-width: 24%;
    }
  
    .md\:flex-\[24\%\] {
      max-width: 24%;
    }
  
    .md\:flex-25 {
      max-width: 25%;
    }
  
    .md\:flex-\[25\%\] {
      max-width: 25%;
    }
  
    .md\:flex-26 {
      max-width: 26%;
    }
  
    .md\:flex-\[26\%\] {
      max-width: 26%;
    }
  
    .md\:flex-27 {
      max-width: 27%;
    }
  
    .md\:flex-\[27\%\] {
      max-width: 27%;
    }
  
    .md\:flex-28 {
      max-width: 28%;
    }
  
    .md\:flex-\[28\%\] {
      max-width: 28%;
    }
  
    .md\:flex-29 {
      max-width: 29%;
    }
  
    .md\:flex-\[29\%\] {
      max-width: 29%;
    }
  
    .md\:flex-30 {
      max-width: 30%;
    }
  
    .md\:flex-\[30\%\] {
      max-width: 30%;
    }
  
    .md\:flex-31 {
      max-width: 31%;
    }
  
    .md\:flex-\[31\%\] {
      max-width: 31%;
    }
  
    .md\:flex-32 {
      max-width: 32%;
    }
  
    .md\:flex-\[32\%\] {
      max-width: 32%;
    }
  
    .md\:flex-33 {
      max-width: 33%;
    }
  
    .md\:flex-\[33\%\] {
      max-width: 33%;
    }
  
    .md\:flex-34 {
      max-width: 34%;
    }
  
    .md\:flex-\[34\%\] {
      max-width: 34%;
    }
  
    .md\:flex-35 {
      max-width: 35%;
    }
  
    .md\:flex-\[35\%\] {
      max-width: 35%;
    }
  
    .md\:flex-36 {
      max-width: 36%;
    }
  
    .md\:flex-\[36\%\] {
      max-width: 36%;
    }
  
    .md\:flex-37 {
      max-width: 37%;
    }
  
    .md\:flex-\[37\%\] {
      max-width: 37%;
    }
  
    .md\:flex-38 {
      max-width: 38%;
    }
  
    .md\:flex-\[38\%\] {
      max-width: 38%;
    }
  
    .md\:flex-39 {
      max-width: 39%;
    }
  
    .md\:flex-\[39\%\] {
      max-width: 39%;
    }
  
    .md\:flex-40 {
      max-width: 40%;
    }
  
    .md\:flex-\[40\%\] {
      max-width: 40%;
    }
  
    .md\:flex-41 {
      max-width: 41%;
    }
  
    .md\:flex-\[41\%\] {
      max-width: 41%;
    }
  
    .md\:flex-42 {
      max-width: 42%;
    }
  
    .md\:flex-\[42\%\] {
      max-width: 42%;
    }
  
    .md\:flex-43 {
      max-width: 43%;
    }
  
    .md\:flex-\[43\%\] {
      max-width: 43%;
    }
  
    .md\:flex-44 {
      max-width: 44%;
    }
  
    .md\:flex-\[44\%\] {
      max-width: 44%;
    }
  
    .md\:flex-45 {
      max-width: 45%;
    }
  
    .md\:flex-\[45\%\] {
      max-width: 45%;
    }
  
    .md\:flex-46 {
      max-width: 46%;
    }
  
    .md\:flex-\[46\%\] {
      max-width: 46%;
    }
  
    .md\:flex-47 {
      max-width: 47%;
    }
  
    .md\:flex-\[47\%\] {
      max-width: 47%;
    }
  
    .md\:flex-48 {
      max-width: 48%;
    }
  
    .md\:flex-\[48\%\] {
      max-width: 48%;
    }
  
    .md\:flex-49 {
      max-width: 49%;
    }
  
    .md\:flex-\[49\%\] {
      max-width: 49%;
    }
  
    .md\:flex-50 {
      max-width: 50%;
    }
  
    .md\:flex-\[50\%\] {
      max-width: 50%;
    }
  
    .md\:flex-51 {
      max-width: 51%;
    }
  
    .md\:flex-\[51\%\] {
      max-width: 51%;
    }
  
    .md\:flex-52 {
      max-width: 52%;
    }
  
    .md\:flex-\[52\%\] {
      max-width: 52%;
    }
  
    .md\:flex-53 {
      max-width: 53%;
    }
  
    .md\:flex-\[53\%\] {
      max-width: 53%;
    }
  
    .md\:flex-54 {
      max-width: 54%;
    }
  
    .md\:flex-\[54\%\] {
      max-width: 54%;
    }
  
    .md\:flex-55 {
      max-width: 55%;
    }
  
    .md\:flex-\[55\%\] {
      max-width: 55%;
    }
  
    .md\:flex-56 {
      max-width: 56%;
    }
  
    .md\:flex-\[56\%\] {
      max-width: 56%;
    }
  
    .md\:flex-57 {
      max-width: 57%;
    }
  
    .md\:flex-\[57\%\] {
      max-width: 57%;
    }
  
    .md\:flex-58 {
      max-width: 58%;
    }
  
    .md\:flex-\[58\%\] {
      max-width: 58%;
    }
  
    .md\:flex-59 {
      max-width: 59%;
    }
  
    .md\:flex-\[59\%\] {
      max-width: 59%;
    }
  
    .md\:flex-60 {
      max-width: 60%;
    }
  
    .md\:flex-\[60\%\] {
      max-width: 60%;
    }
  
    .md\:flex-61 {
      max-width: 61%;
    }
  
    .md\:flex-\[61\%\] {
      max-width: 61%;
    }
  
    .md\:flex-62 {
      max-width: 62%;
    }
  
    .md\:flex-\[62\%\] {
      max-width: 62%;
    }
  
    .md\:flex-63 {
      max-width: 63%;
    }
  
    .md\:flex-\[63\%\] {
      max-width: 63%;
    }
  
    .md\:flex-64 {
      max-width: 64%;
    }
  
    .md\:flex-\[64\%\] {
      max-width: 64%;
    }
  
    .md\:flex-65 {
      max-width: 65%;
    }
  
    .md\:flex-\[65\%\] {
      max-width: 65%;
    }
  
    .md\:flex-66 {
      max-width: 66%;
    }
  
    .md\:flex-\[66\%\] {
      max-width: 66%;
    }
  
    .md\:flex-67 {
      max-width: 67%;
    }
  
    .md\:flex-\[67\%\] {
      max-width: 67%;
    }
  
    .md\:flex-68 {
      max-width: 68%;
    }
  
    .md\:flex-\[68\%\] {
      max-width: 68%;
    }
  
    .md\:flex-69 {
      max-width: 69%;
    }
  
    .md\:flex-\[69\%\] {
      max-width: 69%;
    }
  
    .md\:flex-70 {
      max-width: 70%;
    }
  
    .md\:flex-\[70\%\] {
      max-width: 70%;
    }
  
    .md\:flex-71 {
      max-width: 71%;
    }
  
    .md\:flex-\[71\%\] {
      max-width: 71%;
    }
  
    .md\:flex-72 {
      max-width: 72%;
    }
  
    .md\:flex-\[72\%\] {
      max-width: 72%;
    }
  
    .md\:flex-73 {
      max-width: 73%;
    }
  
    .md\:flex-\[73\%\] {
      max-width: 73%;
    }
  
    .md\:flex-74 {
      max-width: 74%;
    }
  
    .md\:flex-\[74\%\] {
      max-width: 74%;
    }
  
    .md\:flex-75 {
      max-width: 75%;
    }
  
    .md\:flex-\[75\%\] {
      max-width: 75%;
    }
  
    .md\:flex-76 {
      max-width: 76%;
    }
  
    .md\:flex-\[76\%\] {
      max-width: 76%;
    }
  
    .md\:flex-77 {
      max-width: 77%;
    }
  
    .md\:flex-\[77\%\] {
      max-width: 77%;
    }
  
    .md\:flex-78 {
      max-width: 78%;
    }
  
    .md\:flex-\[78\%\] {
      max-width: 78%;
    }
  
    .md\:flex-79 {
      max-width: 79%;
    }
  
    .md\:flex-\[79\%\] {
      max-width: 79%;
    }
  
    .md\:flex-80 {
      max-width: 80%;
    }
  
    .md\:flex-\[80\%\] {
      max-width: 80%;
    }
  
    .md\:flex-81 {
      max-width: 81%;
    }
  
    .md\:flex-\[81\%\] {
      max-width: 81%;
    }
  
    .md\:flex-82 {
      max-width: 82%;
    }
  
    .md\:flex-\[82\%\] {
      max-width: 82%;
    }
  
    .md\:flex-83 {
      max-width: 83%;
    }
  
    .md\:flex-\[83\%\] {
      max-width: 83%;
    }
  
    .md\:flex-84 {
      max-width: 84%;
    }
  
    .md\:flex-\[84\%\] {
      max-width: 84%;
    }
  
    .md\:flex-85 {
      max-width: 85%;
    }
  
    .md\:flex-\[85\%\] {
      max-width: 85%;
    }
  
    .md\:flex-86 {
      max-width: 86%;
    }
  
    .md\:flex-\[86\%\] {
      max-width: 86%;
    }
  
    .md\:flex-87 {
      max-width: 87%;
    }
  
    .md\:flex-\[87\%\] {
      max-width: 87%;
    }
  
    .md\:flex-88 {
      max-width: 88%;
    }
  
    .md\:flex-\[88\%\] {
      max-width: 88%;
    }
  
    .md\:flex-89 {
      max-width: 89%;
    }
  
    .md\:flex-\[89\%\] {
      max-width: 89%;
    }
  
    .md\:flex-90 {
      max-width: 90%;
    }
  
    .md\:flex-\[90\%\] {
      max-width: 90%;
    }
  
    .md\:flex-91 {
      max-width: 91%;
    }
  
    .md\:flex-\[91\%\] {
      max-width: 91%;
    }
  
    .md\:flex-92 {
      max-width: 92%;
    }
  
    .md\:flex-\[92\%\] {
      max-width: 92%;
    }
  
    .md\:flex-93 {
      max-width: 93%;
    }
  
    .md\:flex-\[93\%\] {
      max-width: 93%;
    }
  
    .md\:flex-94 {
      max-width: 94%;
    }
  
    .md\:flex-\[94\%\] {
      max-width: 94%;
    }
  
    .md\:flex-95 {
      max-width: 95%;
    }
  
    .md\:flex-\[95\%\] {
      max-width: 95%;
    }
  
    .md\:flex-96 {
      max-width: 96%;
    }
  
    .md\:flex-\[96\%\] {
      max-width: 96%;
    }
  
    .md\:flex-97 {
      max-width: 97%;
    }
  
    .md\:flex-\[97\%\] {
      max-width: 97%;
    }
  
    .md\:flex-98 {
      max-width: 98%;
    }
  
    .md\:flex-\[98\%\] {
      max-width: 98%;
    }
  
    .md\:flex-99 {
      max-width: 99%;
    }
  
    .md\:flex-\[99\%\] {
      max-width: 99%;
    }
  
    .md\:flex-100 {
      max-width: 100%;
    }
  
    .md\:flex-\[100\%\] {
      max-width: 100%;
    }
  
    
  }
  @media (min-width: 1024px) {
    .lg\:flex-0 {
      max-width: 0%;
    }
  
    .lg\:flex-\[0\%\] {
      max-width: 0%;
    }
  
    .lg\:flex-1 {
      max-width: 1%;
    }
  
    .lg\:flex-\[1\%\] {
      max-width: 1%;
    }
  
    .lg\:flex-2 {
      max-width: 2%;
    }
  
    .lg\:flex-\[2\%\] {
      max-width: 2%;
    }
  
    .lg\:flex-3 {
      max-width: 3%;
    }
  
    .lg\:flex-\[3\%\] {
      max-width: 3%;
    }
  
    .lg\:flex-4 {
      max-width: 4%;
    }
  
    .lg\:flex-\[4\%\] {
      max-width: 4%;
    }
  
    .lg\:flex-5 {
      max-width: 5%;
    }
  
    .lg\:flex-\[5\%\] {
      max-width: 5%;
    }
  
    .lg\:flex-6 {
      max-width: 6%;
    }
  
    .lg\:flex-\[6\%\] {
      max-width: 6%;
    }
  
    .lg\:flex-7 {
      max-width: 7%;
    }
  
    .lg\:flex-\[7\%\] {
      max-width: 7%;
    }
  
    .lg\:flex-8 {
      max-width: 8%;
    }
  
    .lg\:flex-\[8\%\] {
      max-width: 8%;
    }
  
    .lg\:flex-9 {
      max-width: 9%;
    }
  
    .lg\:flex-\[9\%\] {
      max-width: 9%;
    }
  
    .lg\:flex-10 {
      max-width: 10%;
    }
  
    .lg\:flex-\[10\%\] {
      max-width: 10%;
    }
  
    .lg\:flex-11 {
      max-width: 11%;
    }
  
    .lg\:flex-\[11\%\] {
      max-width: 11%;
    }
  
    .lg\:flex-12 {
      max-width: 12%;
    }
  
    .lg\:flex-\[12\%\] {
      max-width: 12%;
    }
  
    .lg\:flex-13 {
      max-width: 13%;
    }
  
    .lg\:flex-\[13\%\] {
      max-width: 13%;
    }
  
    .lg\:flex-14 {
      max-width: 14%;
    }
  
    .lg\:flex-\[14\%\] {
      max-width: 14%;
    }
  
    .lg\:flex-15 {
      max-width: 15%;
    }
  
    .lg\:flex-\[15\%\] {
      max-width: 15%;
    }
  
    .lg\:flex-16 {
      max-width: 16%;
    }
  
    .lg\:flex-\[16\%\] {
      max-width: 16%;
    }
  
    .lg\:flex-17 {
      max-width: 17%;
    }
  
    .lg\:flex-\[17\%\] {
      max-width: 17%;
    }
  
    .lg\:flex-18 {
      max-width: 18%;
    }
  
    .lg\:flex-\[18\%\] {
      max-width: 18%;
    }
  
    .lg\:flex-19 {
      max-width: 19%;
    }
  
    .lg\:flex-\[19\%\] {
      max-width: 19%;
    }
  
    .lg\:flex-20 {
      max-width: 20%;
    }
  
    .lg\:flex-\[20\%\] {
      max-width: 20%;
    }
  
    .lg\:flex-21 {
      max-width: 21%;
    }
  
    .lg\:flex-\[21\%\] {
      max-width: 21%;
    }
  
    .lg\:flex-22 {
      max-width: 22%;
    }
  
    .lg\:flex-\[22\%\] {
      max-width: 22%;
    }
  
    .lg\:flex-23 {
      max-width: 23%;
    }
  
    .lg\:flex-\[23\%\] {
      max-width: 23%;
    }
  
    .lg\:flex-24 {
      max-width: 24%;
    }
  
    .lg\:flex-\[24\%\] {
      max-width: 24%;
    }
  
    .lg\:flex-25 {
      max-width: 25%;
    }
  
    .lg\:flex-\[25\%\] {
      max-width: 25%;
    }
  
    .lg\:flex-26 {
      max-width: 26%;
    }
  
    .lg\:flex-\[26\%\] {
      max-width: 26%;
    }
  
    .lg\:flex-27 {
      max-width: 27%;
    }
  
    .lg\:flex-\[27\%\] {
      max-width: 27%;
    }
  
    .lg\:flex-28 {
      max-width: 28%;
    }
  
    .lg\:flex-\[28\%\] {
      max-width: 28%;
    }
  
    .lg\:flex-29 {
      max-width: 29%;
    }
  
    .lg\:flex-\[29\%\] {
      max-width: 29%;
    }
  
    .lg\:flex-30 {
      max-width: 30%;
    }
  
    .lg\:flex-\[30\%\] {
      max-width: 30%;
    }
  
    .lg\:flex-31 {
      max-width: 31%;
    }
  
    .lg\:flex-\[31\%\] {
      max-width: 31%;
    }
  
    .lg\:flex-32 {
      max-width: 32%;
    }
  
    .lg\:flex-\[32\%\] {
      max-width: 32%;
    }
  
    .lg\:flex-33 {
      max-width: 33%;
    }
  
    .lg\:flex-\[33\%\] {
      max-width: 33%;
    }
  
    .lg\:flex-34 {
      max-width: 34%;
    }
  
    .lg\:flex-\[34\%\] {
      max-width: 34%;
    }
  
    .lg\:flex-35 {
      max-width: 35%;
    }
  
    .lg\:flex-\[35\%\] {
      max-width: 35%;
    }
  
    .lg\:flex-36 {
      max-width: 36%;
    }
  
    .lg\:flex-\[36\%\] {
      max-width: 36%;
    }
  
    .lg\:flex-37 {
      max-width: 37%;
    }
  
    .lg\:flex-\[37\%\] {
      max-width: 37%;
    }
  
    .lg\:flex-38 {
      max-width: 38%;
    }
  
    .lg\:flex-\[38\%\] {
      max-width: 38%;
    }
  
    .lg\:flex-39 {
      max-width: 39%;
    }
  
    .lg\:flex-\[39\%\] {
      max-width: 39%;
    }
  
    .lg\:flex-40 {
      max-width: 40%;
    }
  
    .lg\:flex-\[40\%\] {
      max-width: 40%;
    }
  
    .lg\:flex-41 {
      max-width: 41%;
    }
  
    .lg\:flex-\[41\%\] {
      max-width: 41%;
    }
  
    .lg\:flex-42 {
      max-width: 42%;
    }
  
    .lg\:flex-\[42\%\] {
      max-width: 42%;
    }
  
    .lg\:flex-43 {
      max-width: 43%;
    }
  
    .lg\:flex-\[43\%\] {
      max-width: 43%;
    }
  
    .lg\:flex-44 {
      max-width: 44%;
    }
  
    .lg\:flex-\[44\%\] {
      max-width: 44%;
    }
  
    .lg\:flex-45 {
      max-width: 45%;
    }
  
    .lg\:flex-\[45\%\] {
      max-width: 45%;
    }
  
    .lg\:flex-46 {
      max-width: 46%;
    }
  
    .lg\:flex-\[46\%\] {
      max-width: 46%;
    }
  
    .lg\:flex-47 {
      max-width: 47%;
    }
  
    .lg\:flex-\[47\%\] {
      max-width: 47%;
    }
  
    .lg\:flex-48 {
      max-width: 48%;
    }
  
    .lg\:flex-\[48\%\] {
      max-width: 48%;
    }
  
    .lg\:flex-49 {
      max-width: 49%;
    }
  
    .lg\:flex-\[49\%\] {
      max-width: 49%;
    }
  
    .lg\:flex-50 {
      max-width: 50%;
    }
  
    .lg\:flex-\[50\%\] {
      max-width: 50%;
    }
  
    .lg\:flex-51 {
      max-width: 51%;
    }
  
    .lg\:flex-\[51\%\] {
      max-width: 51%;
    }
  
    .lg\:flex-52 {
      max-width: 52%;
    }
  
    .lg\:flex-\[52\%\] {
      max-width: 52%;
    }
  
    .lg\:flex-53 {
      max-width: 53%;
    }
  
    .lg\:flex-\[53\%\] {
      max-width: 53%;
    }
  
    .lg\:flex-54 {
      max-width: 54%;
    }
  
    .lg\:flex-\[54\%\] {
      max-width: 54%;
    }
  
    .lg\:flex-55 {
      max-width: 55%;
    }
  
    .lg\:flex-\[55\%\] {
      max-width: 55%;
    }
  
    .lg\:flex-56 {
      max-width: 56%;
    }
  
    .lg\:flex-\[56\%\] {
      max-width: 56%;
    }
  
    .lg\:flex-57 {
      max-width: 57%;
    }
  
    .lg\:flex-\[57\%\] {
      max-width: 57%;
    }
  
    .lg\:flex-58 {
      max-width: 58%;
    }
  
    .lg\:flex-\[58\%\] {
      max-width: 58%;
    }
  
    .lg\:flex-59 {
      max-width: 59%;
    }
  
    .lg\:flex-\[59\%\] {
      max-width: 59%;
    }
  
    .lg\:flex-60 {
      max-width: 60%;
    }
  
    .lg\:flex-\[60\%\] {
      max-width: 60%;
    }
  
    .lg\:flex-61 {
      max-width: 61%;
    }
  
    .lg\:flex-\[61\%\] {
      max-width: 61%;
    }
  
    .lg\:flex-62 {
      max-width: 62%;
    }
  
    .lg\:flex-\[62\%\] {
      max-width: 62%;
    }
  
    .lg\:flex-63 {
      max-width: 63%;
    }
  
    .lg\:flex-\[63\%\] {
      max-width: 63%;
    }
  
    .lg\:flex-64 {
      max-width: 64%;
    }
  
    .lg\:flex-\[64\%\] {
      max-width: 64%;
    }
  
    .lg\:flex-65 {
      max-width: 65%;
    }
  
    .lg\:flex-\[65\%\] {
      max-width: 65%;
    }
  
    .lg\:flex-66 {
      max-width: 66%;
    }
  
    .lg\:flex-\[66\%\] {
      max-width: 66%;
    }
  
    .lg\:flex-67 {
      max-width: 67%;
    }
  
    .lg\:flex-\[67\%\] {
      max-width: 67%;
    }
  
    .lg\:flex-68 {
      max-width: 68%;
    }
  
    .lg\:flex-\[68\%\] {
      max-width: 68%;
    }
  
    .lg\:flex-69 {
      max-width: 69%;
    }
  
    .lg\:flex-\[69\%\] {
      max-width: 69%;
    }
  
    .lg\:flex-70 {
      max-width: 70%;
    }
  
    .lg\:flex-\[70\%\] {
      max-width: 70%;
    }
  
    .lg\:flex-71 {
      max-width: 71%;
    }
  
    .lg\:flex-\[71\%\] {
      max-width: 71%;
    }
  
    .lg\:flex-72 {
      max-width: 72%;
    }
  
    .lg\:flex-\[72\%\] {
      max-width: 72%;
    }
  
    .lg\:flex-73 {
      max-width: 73%;
    }
  
    .lg\:flex-\[73\%\] {
      max-width: 73%;
    }
  
    .lg\:flex-74 {
      max-width: 74%;
    }
  
    .lg\:flex-\[74\%\] {
      max-width: 74%;
    }
  
    .lg\:flex-75 {
      max-width: 75%;
    }
  
    .lg\:flex-\[75\%\] {
      max-width: 75%;
    }
  
    .lg\:flex-76 {
      max-width: 76%;
    }
  
    .lg\:flex-\[76\%\] {
      max-width: 76%;
    }
  
    .lg\:flex-77 {
      max-width: 77%;
    }
  
    .lg\:flex-\[77\%\] {
      max-width: 77%;
    }
  
    .lg\:flex-78 {
      max-width: 78%;
    }
  
    .lg\:flex-\[78\%\] {
      max-width: 78%;
    }
  
    .lg\:flex-79 {
      max-width: 79%;
    }
  
    .lg\:flex-\[79\%\] {
      max-width: 79%;
    }
  
    .lg\:flex-80 {
      max-width: 80%;
    }
  
    .lg\:flex-\[80\%\] {
      max-width: 80%;
    }
  
    .lg\:flex-81 {
      max-width: 81%;
    }
  
    .lg\:flex-\[81\%\] {
      max-width: 81%;
    }
  
    .lg\:flex-82 {
      max-width: 82%;
    }
  
    .lg\:flex-\[82\%\] {
      max-width: 82%;
    }
  
    .lg\:flex-83 {
      max-width: 83%;
    }
  
    .lg\:flex-\[83\%\] {
      max-width: 83%;
    }
  
    .lg\:flex-84 {
      max-width: 84%;
    }
  
    .lg\:flex-\[84\%\] {
      max-width: 84%;
    }
  
    .lg\:flex-85 {
      max-width: 85%;
    }
  
    .lg\:flex-\[85\%\] {
      max-width: 85%;
    }
  
    .lg\:flex-86 {
      max-width: 86%;
    }
  
    .lg\:flex-\[86\%\] {
      max-width: 86%;
    }
  
    .lg\:flex-87 {
      max-width: 87%;
    }
  
    .lg\:flex-\[87\%\] {
      max-width: 87%;
    }
  
    .lg\:flex-88 {
      max-width: 88%;
    }
  
    .lg\:flex-\[88\%\] {
      max-width: 88%;
    }
  
    .lg\:flex-89 {
      max-width: 89%;
    }
  
    .lg\:flex-\[89\%\] {
      max-width: 89%;
    }
  
    .lg\:flex-90 {
      max-width: 90%;
    }
  
    .lg\:flex-\[90\%\] {
      max-width: 90%;
    }
  
    .lg\:flex-91 {
      max-width: 91%;
    }
  
    .lg\:flex-\[91\%\] {
      max-width: 91%;
    }
  
    .lg\:flex-92 {
      max-width: 92%;
    }
  
    .lg\:flex-\[92\%\] {
      max-width: 92%;
    }
  
    .lg\:flex-93 {
      max-width: 93%;
    }
  
    .lg\:flex-\[93\%\] {
      max-width: 93%;
    }
  
    .lg\:flex-94 {
      max-width: 94%;
    }
  
    .lg\:flex-\[94\%\] {
      max-width: 94%;
    }
  
    .lg\:flex-95 {
      max-width: 95%;
    }
  
    .lg\:flex-\[95\%\] {
      max-width: 95%;
    }
  
    .lg\:flex-96 {
      max-width: 96%;
    }
  
    .lg\:flex-\[96\%\] {
      max-width: 96%;
    }
  
    .lg\:flex-97 {
      max-width: 97%;
    }
  
    .lg\:flex-\[97\%\] {
      max-width: 97%;
    }
  
    .lg\:flex-98 {
      max-width: 98%;
    }
  
    .lg\:flex-\[98\%\] {
      max-width: 98%;
    }
  
    .lg\:flex-99 {
      max-width: 99%;
    }
  
    .lg\:flex-\[99\%\] {
      max-width: 99%;
    }
  
    .lg\:flex-100 {
      max-width: 100%;
    }
  
    .lg\:flex-\[100\%\] {
      max-width: 100%;
    }
  
    
  }
  @media (min-width: 1280px) {
    .xl\:flex-0 {
      max-width: 0%;
    }
  
    .xl\:flex-\[0\%\] {
      max-width: 0%;
    }
  
    .xl\:flex-1 {
      max-width: 1%;
    }
  
    .xl\:flex-\[1\%\] {
      max-width: 1%;
    }
  
    .xl\:flex-2 {
      max-width: 2%;
    }
  
    .xl\:flex-\[2\%\] {
      max-width: 2%;
    }
  
    .xl\:flex-3 {
      max-width: 3%;
    }
  
    .xl\:flex-\[3\%\] {
      max-width: 3%;
    }
  
    .xl\:flex-4 {
      max-width: 4%;
    }
  
    .xl\:flex-\[4\%\] {
      max-width: 4%;
    }
  
    .xl\:flex-5 {
      max-width: 5%;
    }
  
    .xl\:flex-\[5\%\] {
      max-width: 5%;
    }
  
    .xl\:flex-6 {
      max-width: 6%;
    }
  
    .xl\:flex-\[6\%\] {
      max-width: 6%;
    }
  
    .xl\:flex-7 {
      max-width: 7%;
    }
  
    .xl\:flex-\[7\%\] {
      max-width: 7%;
    }
  
    .xl\:flex-8 {
      max-width: 8%;
    }
  
    .xl\:flex-\[8\%\] {
      max-width: 8%;
    }
  
    .xl\:flex-9 {
      max-width: 9%;
    }
  
    .xl\:flex-\[9\%\] {
      max-width: 9%;
    }
  
    .xl\:flex-10 {
      max-width: 10%;
    }
  
    .xl\:flex-\[10\%\] {
      max-width: 10%;
    }
  
    .xl\:flex-11 {
      max-width: 11%;
    }
  
    .xl\:flex-\[11\%\] {
      max-width: 11%;
    }
  
    .xl\:flex-12 {
      max-width: 12%;
    }
  
    .xl\:flex-\[12\%\] {
      max-width: 12%;
    }
  
    .xl\:flex-13 {
      max-width: 13%;
    }
  
    .xl\:flex-\[13\%\] {
      max-width: 13%;
    }
  
    .xl\:flex-14 {
      max-width: 14%;
    }
  
    .xl\:flex-\[14\%\] {
      max-width: 14%;
    }
  
    .xl\:flex-15 {
      max-width: 15%;
    }
  
    .xl\:flex-\[15\%\] {
      max-width: 15%;
    }
  
    .xl\:flex-16 {
      max-width: 16%;
    }
  
    .xl\:flex-\[16\%\] {
      max-width: 16%;
    }
  
    .xl\:flex-17 {
      max-width: 17%;
    }
  
    .xl\:flex-\[17\%\] {
      max-width: 17%;
    }
  
    .xl\:flex-18 {
      max-width: 18%;
    }
  
    .xl\:flex-\[18\%\] {
      max-width: 18%;
    }
  
    .xl\:flex-19 {
      max-width: 19%;
    }
  
    .xl\:flex-\[19\%\] {
      max-width: 19%;
    }
  
    .xl\:flex-20 {
      max-width: 20%;
    }
  
    .xl\:flex-\[20\%\] {
      max-width: 20%;
    }
  
    .xl\:flex-21 {
      max-width: 21%;
    }
  
    .xl\:flex-\[21\%\] {
      max-width: 21%;
    }
  
    .xl\:flex-22 {
      max-width: 22%;
    }
  
    .xl\:flex-\[22\%\] {
      max-width: 22%;
    }
  
    .xl\:flex-23 {
      max-width: 23%;
    }
  
    .xl\:flex-\[23\%\] {
      max-width: 23%;
    }
  
    .xl\:flex-24 {
      max-width: 24%;
    }
  
    .xl\:flex-\[24\%\] {
      max-width: 24%;
    }
  
    .xl\:flex-25 {
      max-width: 25%;
    }
  
    .xl\:flex-\[25\%\] {
      max-width: 25%;
    }
  
    .xl\:flex-26 {
      max-width: 26%;
    }
  
    .xl\:flex-\[26\%\] {
      max-width: 26%;
    }
  
    .xl\:flex-27 {
      max-width: 27%;
    }
  
    .xl\:flex-\[27\%\] {
      max-width: 27%;
    }
  
    .xl\:flex-28 {
      max-width: 28%;
    }
  
    .xl\:flex-\[28\%\] {
      max-width: 28%;
    }
  
    .xl\:flex-29 {
      max-width: 29%;
    }
  
    .xl\:flex-\[29\%\] {
      max-width: 29%;
    }
  
    .xl\:flex-30 {
      max-width: 30%;
    }
  
    .xl\:flex-\[30\%\] {
      max-width: 30%;
    }
  
    .xl\:flex-31 {
      max-width: 31%;
    }
  
    .xl\:flex-\[31\%\] {
      max-width: 31%;
    }
  
    .xl\:flex-32 {
      max-width: 32%;
    }
  
    .xl\:flex-\[32\%\] {
      max-width: 32%;
    }
  
    .xl\:flex-33 {
      max-width: 33%;
    }
  
    .xl\:flex-\[33\%\] {
      max-width: 33%;
    }
  
    .xl\:flex-34 {
      max-width: 34%;
    }
  
    .xl\:flex-\[34\%\] {
      max-width: 34%;
    }
  
    .xl\:flex-35 {
      max-width: 35%;
    }
  
    .xl\:flex-\[35\%\] {
      max-width: 35%;
    }
  
    .xl\:flex-36 {
      max-width: 36%;
    }
  
    .xl\:flex-\[36\%\] {
      max-width: 36%;
    }
  
    .xl\:flex-37 {
      max-width: 37%;
    }
  
    .xl\:flex-\[37\%\] {
      max-width: 37%;
    }
  
    .xl\:flex-38 {
      max-width: 38%;
    }
  
    .xl\:flex-\[38\%\] {
      max-width: 38%;
    }
  
    .xl\:flex-39 {
      max-width: 39%;
    }
  
    .xl\:flex-\[39\%\] {
      max-width: 39%;
    }
  
    .xl\:flex-40 {
      max-width: 40%;
    }
  
    .xl\:flex-\[40\%\] {
      max-width: 40%;
    }
  
    .xl\:flex-41 {
      max-width: 41%;
    }
  
    .xl\:flex-\[41\%\] {
      max-width: 41%;
    }
  
    .xl\:flex-42 {
      max-width: 42%;
    }
  
    .xl\:flex-\[42\%\] {
      max-width: 42%;
    }
  
    .xl\:flex-43 {
      max-width: 43%;
    }
  
    .xl\:flex-\[43\%\] {
      max-width: 43%;
    }
  
    .xl\:flex-44 {
      max-width: 44%;
    }
  
    .xl\:flex-\[44\%\] {
      max-width: 44%;
    }
  
    .xl\:flex-45 {
      max-width: 45%;
    }
  
    .xl\:flex-\[45\%\] {
      max-width: 45%;
    }
  
    .xl\:flex-46 {
      max-width: 46%;
    }
  
    .xl\:flex-\[46\%\] {
      max-width: 46%;
    }
  
    .xl\:flex-47 {
      max-width: 47%;
    }
  
    .xl\:flex-\[47\%\] {
      max-width: 47%;
    }
  
    .xl\:flex-48 {
      max-width: 48%;
    }
  
    .xl\:flex-\[48\%\] {
      max-width: 48%;
    }
  
    .xl\:flex-49 {
      max-width: 49%;
    }
  
    .xl\:flex-\[49\%\] {
      max-width: 49%;
    }
  
    .xl\:flex-50 {
      max-width: 50%;
    }
  
    .xl\:flex-\[50\%\] {
      max-width: 50%;
    }
  
    .xl\:flex-51 {
      max-width: 51%;
    }
  
    .xl\:flex-\[51\%\] {
      max-width: 51%;
    }
  
    .xl\:flex-52 {
      max-width: 52%;
    }
  
    .xl\:flex-\[52\%\] {
      max-width: 52%;
    }
  
    .xl\:flex-53 {
      max-width: 53%;
    }
  
    .xl\:flex-\[53\%\] {
      max-width: 53%;
    }
  
    .xl\:flex-54 {
      max-width: 54%;
    }
  
    .xl\:flex-\[54\%\] {
      max-width: 54%;
    }
  
    .xl\:flex-55 {
      max-width: 55%;
    }
  
    .xl\:flex-\[55\%\] {
      max-width: 55%;
    }
  
    .xl\:flex-56 {
      max-width: 56%;
    }
  
    .xl\:flex-\[56\%\] {
      max-width: 56%;
    }
  
    .xl\:flex-57 {
      max-width: 57%;
    }
  
    .xl\:flex-\[57\%\] {
      max-width: 57%;
    }
  
    .xl\:flex-58 {
      max-width: 58%;
    }
  
    .xl\:flex-\[58\%\] {
      max-width: 58%;
    }
  
    .xl\:flex-59 {
      max-width: 59%;
    }
  
    .xl\:flex-\[59\%\] {
      max-width: 59%;
    }
  
    .xl\:flex-60 {
      max-width: 60%;
    }
  
    .xl\:flex-\[60\%\] {
      max-width: 60%;
    }
  
    .xl\:flex-61 {
      max-width: 61%;
    }
  
    .xl\:flex-\[61\%\] {
      max-width: 61%;
    }
  
    .xl\:flex-62 {
      max-width: 62%;
    }
  
    .xl\:flex-\[62\%\] {
      max-width: 62%;
    }
  
    .xl\:flex-63 {
      max-width: 63%;
    }
  
    .xl\:flex-\[63\%\] {
      max-width: 63%;
    }
  
    .xl\:flex-64 {
      max-width: 64%;
    }
  
    .xl\:flex-\[64\%\] {
      max-width: 64%;
    }
  
    .xl\:flex-65 {
      max-width: 65%;
    }
  
    .xl\:flex-\[65\%\] {
      max-width: 65%;
    }
  
    .xl\:flex-66 {
      max-width: 66%;
    }
  
    .xl\:flex-\[66\%\] {
      max-width: 66%;
    }
  
    .xl\:flex-67 {
      max-width: 67%;
    }
  
    .xl\:flex-\[67\%\] {
      max-width: 67%;
    }
  
    .xl\:flex-68 {
      max-width: 68%;
    }
  
    .xl\:flex-\[68\%\] {
      max-width: 68%;
    }
  
    .xl\:flex-69 {
      max-width: 69%;
    }
  
    .xl\:flex-\[69\%\] {
      max-width: 69%;
    }
  
    .xl\:flex-70 {
      max-width: 70%;
    }
  
    .xl\:flex-\[70\%\] {
      max-width: 70%;
    }
  
    .xl\:flex-71 {
      max-width: 71%;
    }
  
    .xl\:flex-\[71\%\] {
      max-width: 71%;
    }
  
    .xl\:flex-72 {
      max-width: 72%;
    }
  
    .xl\:flex-\[72\%\] {
      max-width: 72%;
    }
  
    .xl\:flex-73 {
      max-width: 73%;
    }
  
    .xl\:flex-\[73\%\] {
      max-width: 73%;
    }
  
    .xl\:flex-74 {
      max-width: 74%;
    }
  
    .xl\:flex-\[74\%\] {
      max-width: 74%;
    }
  
    .xl\:flex-75 {
      max-width: 75%;
    }
  
    .xl\:flex-\[75\%\] {
      max-width: 75%;
    }
  
    .xl\:flex-76 {
      max-width: 76%;
    }
  
    .xl\:flex-\[76\%\] {
      max-width: 76%;
    }
  
    .xl\:flex-77 {
      max-width: 77%;
    }
  
    .xl\:flex-\[77\%\] {
      max-width: 77%;
    }
  
    .xl\:flex-78 {
      max-width: 78%;
    }
  
    .xl\:flex-\[78\%\] {
      max-width: 78%;
    }
  
    .xl\:flex-79 {
      max-width: 79%;
    }
  
    .xl\:flex-\[79\%\] {
      max-width: 79%;
    }
  
    .xl\:flex-80 {
      max-width: 80%;
    }
  
    .xl\:flex-\[80\%\] {
      max-width: 80%;
    }
  
    .xl\:flex-81 {
      max-width: 81%;
    }
  
    .xl\:flex-\[81\%\] {
      max-width: 81%;
    }
  
    .xl\:flex-82 {
      max-width: 82%;
    }
  
    .xl\:flex-\[82\%\] {
      max-width: 82%;
    }
  
    .xl\:flex-83 {
      max-width: 83%;
    }
  
    .xl\:flex-\[83\%\] {
      max-width: 83%;
    }
  
    .xl\:flex-84 {
      max-width: 84%;
    }
  
    .xl\:flex-\[84\%\] {
      max-width: 84%;
    }
  
    .xl\:flex-85 {
      max-width: 85%;
    }
  
    .xl\:flex-\[85\%\] {
      max-width: 85%;
    }
  
    .xl\:flex-86 {
      max-width: 86%;
    }
  
    .xl\:flex-\[86\%\] {
      max-width: 86%;
    }
  
    .xl\:flex-87 {
      max-width: 87%;
    }
  
    .xl\:flex-\[87\%\] {
      max-width: 87%;
    }
  
    .xl\:flex-88 {
      max-width: 88%;
    }
  
    .xl\:flex-\[88\%\] {
      max-width: 88%;
    }
  
    .xl\:flex-89 {
      max-width: 89%;
    }
  
    .xl\:flex-\[89\%\] {
      max-width: 89%;
    }
  
    .xl\:flex-90 {
      max-width: 90%;
    }
  
    .xl\:flex-\[90\%\] {
      max-width: 90%;
    }
  
    .xl\:flex-91 {
      max-width: 91%;
    }
  
    .xl\:flex-\[91\%\] {
      max-width: 91%;
    }
  
    .xl\:flex-92 {
      max-width: 92%;
    }
  
    .xl\:flex-\[92\%\] {
      max-width: 92%;
    }
  
    .xl\:flex-93 {
      max-width: 93%;
    }
  
    .xl\:flex-\[93\%\] {
      max-width: 93%;
    }
  
    .xl\:flex-94 {
      max-width: 94%;
    }
  
    .xl\:flex-\[94\%\] {
      max-width: 94%;
    }
  
    .xl\:flex-95 {
      max-width: 95%;
    }
  
    .xl\:flex-\[95\%\] {
      max-width: 95%;
    }
  
    .xl\:flex-96 {
      max-width: 96%;
    }
  
    .xl\:flex-\[96\%\] {
      max-width: 96%;
    }
  
    .xl\:flex-97 {
      max-width: 97%;
    }
  
    .xl\:flex-\[97\%\] {
      max-width: 97%;
    }
  
    .xl\:flex-98 {
      max-width: 98%;
    }
  
    .xl\:flex-\[98\%\] {
      max-width: 98%;
    }
  
    .xl\:flex-99 {
      max-width: 99%;
    }
  
    .xl\:flex-\[99\%\] {
      max-width: 99%;
    }
  
    .xl\:flex-100 {
      max-width: 100%;
    }
  
    .xl\:flex-\[100\%\] {
      max-width: 100%;
    }
  
    
  }
  @media (min-width: 1536px) {
    .\32xl\:flex-0 {
      max-width: 0%;
    }
  
    .\32xl\:flex-\[0\%\] {
      max-width: 0%;
    }
  
    .\32xl\:flex-1 {
      max-width: 1%;
    }
  
    .\32xl\:flex-\[1\%\] {
      max-width: 1%;
    }
  
    .\32xl\:flex-2 {
      max-width: 2%;
    }
  
    .\32xl\:flex-\[2\%\] {
      max-width: 2%;
    }
  
    .\32xl\:flex-3 {
      max-width: 3%;
    }
  
    .\32xl\:flex-\[3\%\] {
      max-width: 3%;
    }
  
    .\32xl\:flex-4 {
      max-width: 4%;
    }
  
    .\32xl\:flex-\[4\%\] {
      max-width: 4%;
    }
  
    .\32xl\:flex-5 {
      max-width: 5%;
    }
  
    .\32xl\:flex-\[5\%\] {
      max-width: 5%;
    }
  
    .\32xl\:flex-6 {
      max-width: 6%;
    }
  
    .\32xl\:flex-\[6\%\] {
      max-width: 6%;
    }
  
    .\32xl\:flex-7 {
      max-width: 7%;
    }
  
    .\32xl\:flex-\[7\%\] {
      max-width: 7%;
    }
  
    .\32xl\:flex-8 {
      max-width: 8%;
    }
  
    .\32xl\:flex-\[8\%\] {
      max-width: 8%;
    }
  
    .\32xl\:flex-9 {
      max-width: 9%;
    }
  
    .\32xl\:flex-\[9\%\] {
      max-width: 9%;
    }
  
    .\32xl\:flex-10 {
      max-width: 10%;
    }
  
    .\32xl\:flex-\[10\%\] {
      max-width: 10%;
    }
  
    .\32xl\:flex-11 {
      max-width: 11%;
    }
  
    .\32xl\:flex-\[11\%\] {
      max-width: 11%;
    }
  
    .\32xl\:flex-12 {
      max-width: 12%;
    }
  
    .\32xl\:flex-\[12\%\] {
      max-width: 12%;
    }
  
    .\32xl\:flex-13 {
      max-width: 13%;
    }
  
    .\32xl\:flex-\[13\%\] {
      max-width: 13%;
    }
  
    .\32xl\:flex-14 {
      max-width: 14%;
    }
  
    .\32xl\:flex-\[14\%\] {
      max-width: 14%;
    }
  
    .\32xl\:flex-15 {
      max-width: 15%;
    }
  
    .\32xl\:flex-\[15\%\] {
      max-width: 15%;
    }
  
    .\32xl\:flex-16 {
      max-width: 16%;
    }
  
    .\32xl\:flex-\[16\%\] {
      max-width: 16%;
    }
  
    .\32xl\:flex-17 {
      max-width: 17%;
    }
  
    .\32xl\:flex-\[17\%\] {
      max-width: 17%;
    }
  
    .\32xl\:flex-18 {
      max-width: 18%;
    }
  
    .\32xl\:flex-\[18\%\] {
      max-width: 18%;
    }
  
    .\32xl\:flex-19 {
      max-width: 19%;
    }
  
    .\32xl\:flex-\[19\%\] {
      max-width: 19%;
    }
  
    .\32xl\:flex-20 {
      max-width: 20%;
    }
  
    .\32xl\:flex-\[20\%\] {
      max-width: 20%;
    }
  
    .\32xl\:flex-21 {
      max-width: 21%;
    }
  
    .\32xl\:flex-\[21\%\] {
      max-width: 21%;
    }
  
    .\32xl\:flex-22 {
      max-width: 22%;
    }
  
    .\32xl\:flex-\[22\%\] {
      max-width: 22%;
    }
  
    .\32xl\:flex-23 {
      max-width: 23%;
    }
  
    .\32xl\:flex-\[23\%\] {
      max-width: 23%;
    }
  
    .\32xl\:flex-24 {
      max-width: 24%;
    }
  
    .\32xl\:flex-\[24\%\] {
      max-width: 24%;
    }
  
    .\32xl\:flex-25 {
      max-width: 25%;
    }
  
    .\32xl\:flex-\[25\%\] {
      max-width: 25%;
    }
  
    .\32xl\:flex-26 {
      max-width: 26%;
    }
  
    .\32xl\:flex-\[26\%\] {
      max-width: 26%;
    }
  
    .\32xl\:flex-27 {
      max-width: 27%;
    }
  
    .\32xl\:flex-\[27\%\] {
      max-width: 27%;
    }
  
    .\32xl\:flex-28 {
      max-width: 28%;
    }
  
    .\32xl\:flex-\[28\%\] {
      max-width: 28%;
    }
  
    .\32xl\:flex-29 {
      max-width: 29%;
    }
  
    .\32xl\:flex-\[29\%\] {
      max-width: 29%;
    }
  
    .\32xl\:flex-30 {
      max-width: 30%;
    }
  
    .\32xl\:flex-\[30\%\] {
      max-width: 30%;
    }
  
    .\32xl\:flex-31 {
      max-width: 31%;
    }
  
    .\32xl\:flex-\[31\%\] {
      max-width: 31%;
    }
  
    .\32xl\:flex-32 {
      max-width: 32%;
    }
  
    .\32xl\:flex-\[32\%\] {
      max-width: 32%;
    }
  
    .\32xl\:flex-33 {
      max-width: 33%;
    }
  
    .\32xl\:flex-\[33\%\] {
      max-width: 33%;
    }
  
    .\32xl\:flex-34 {
      max-width: 34%;
    }
  
    .\32xl\:flex-\[34\%\] {
      max-width: 34%;
    }
  
    .\32xl\:flex-35 {
      max-width: 35%;
    }
  
    .\32xl\:flex-\[35\%\] {
      max-width: 35%;
    }
  
    .\32xl\:flex-36 {
      max-width: 36%;
    }
  
    .\32xl\:flex-\[36\%\] {
      max-width: 36%;
    }
  
    .\32xl\:flex-37 {
      max-width: 37%;
    }
  
    .\32xl\:flex-\[37\%\] {
      max-width: 37%;
    }
  
    .\32xl\:flex-38 {
      max-width: 38%;
    }
  
    .\32xl\:flex-\[38\%\] {
      max-width: 38%;
    }
  
    .\32xl\:flex-39 {
      max-width: 39%;
    }
  
    .\32xl\:flex-\[39\%\] {
      max-width: 39%;
    }
  
    .\32xl\:flex-40 {
      max-width: 40%;
    }
  
    .\32xl\:flex-\[40\%\] {
      max-width: 40%;
    }
  
    .\32xl\:flex-41 {
      max-width: 41%;
    }
  
    .\32xl\:flex-\[41\%\] {
      max-width: 41%;
    }
  
    .\32xl\:flex-42 {
      max-width: 42%;
    }
  
    .\32xl\:flex-\[42\%\] {
      max-width: 42%;
    }
  
    .\32xl\:flex-43 {
      max-width: 43%;
    }
  
    .\32xl\:flex-\[43\%\] {
      max-width: 43%;
    }
  
    .\32xl\:flex-44 {
      max-width: 44%;
    }
  
    .\32xl\:flex-\[44\%\] {
      max-width: 44%;
    }
  
    .\32xl\:flex-45 {
      max-width: 45%;
    }
  
    .\32xl\:flex-\[45\%\] {
      max-width: 45%;
    }
  
    .\32xl\:flex-46 {
      max-width: 46%;
    }
  
    .\32xl\:flex-\[46\%\] {
      max-width: 46%;
    }
  
    .\32xl\:flex-47 {
      max-width: 47%;
    }
  
    .\32xl\:flex-\[47\%\] {
      max-width: 47%;
    }
  
    .\32xl\:flex-48 {
      max-width: 48%;
    }
  
    .\32xl\:flex-\[48\%\] {
      max-width: 48%;
    }
  
    .\32xl\:flex-49 {
      max-width: 49%;
    }
  
    .\32xl\:flex-\[49\%\] {
      max-width: 49%;
    }
  
    .\32xl\:flex-50 {
      max-width: 50%;
    }
  
    .\32xl\:flex-\[50\%\] {
      max-width: 50%;
    }
  
    .\32xl\:flex-51 {
      max-width: 51%;
    }
  
    .\32xl\:flex-\[51\%\] {
      max-width: 51%;
    }
  
    .\32xl\:flex-52 {
      max-width: 52%;
    }
  
    .\32xl\:flex-\[52\%\] {
      max-width: 52%;
    }
  
    .\32xl\:flex-53 {
      max-width: 53%;
    }
  
    .\32xl\:flex-\[53\%\] {
      max-width: 53%;
    }
  
    .\32xl\:flex-54 {
      max-width: 54%;
    }
  
    .\32xl\:flex-\[54\%\] {
      max-width: 54%;
    }
  
    .\32xl\:flex-55 {
      max-width: 55%;
    }
  
    .\32xl\:flex-\[55\%\] {
      max-width: 55%;
    }
  
    .\32xl\:flex-56 {
      max-width: 56%;
    }
  
    .\32xl\:flex-\[56\%\] {
      max-width: 56%;
    }
  
    .\32xl\:flex-57 {
      max-width: 57%;
    }
  
    .\32xl\:flex-\[57\%\] {
      max-width: 57%;
    }
  
    .\32xl\:flex-58 {
      max-width: 58%;
    }
  
    .\32xl\:flex-\[58\%\] {
      max-width: 58%;
    }
  
    .\32xl\:flex-59 {
      max-width: 59%;
    }
  
    .\32xl\:flex-\[59\%\] {
      max-width: 59%;
    }
  
    .\32xl\:flex-60 {
      max-width: 60%;
    }
  
    .\32xl\:flex-\[60\%\] {
      max-width: 60%;
    }
  
    .\32xl\:flex-61 {
      max-width: 61%;
    }
  
    .\32xl\:flex-\[61\%\] {
      max-width: 61%;
    }
  
    .\32xl\:flex-62 {
      max-width: 62%;
    }
  
    .\32xl\:flex-\[62\%\] {
      max-width: 62%;
    }
  
    .\32xl\:flex-63 {
      max-width: 63%;
    }
  
    .\32xl\:flex-\[63\%\] {
      max-width: 63%;
    }
  
    .\32xl\:flex-64 {
      max-width: 64%;
    }
  
    .\32xl\:flex-\[64\%\] {
      max-width: 64%;
    }
  
    .\32xl\:flex-65 {
      max-width: 65%;
    }
  
    .\32xl\:flex-\[65\%\] {
      max-width: 65%;
    }
  
    .\32xl\:flex-66 {
      max-width: 66%;
    }
  
    .\32xl\:flex-\[66\%\] {
      max-width: 66%;
    }
  
    .\32xl\:flex-67 {
      max-width: 67%;
    }
  
    .\32xl\:flex-\[67\%\] {
      max-width: 67%;
    }
  
    .\32xl\:flex-68 {
      max-width: 68%;
    }
  
    .\32xl\:flex-\[68\%\] {
      max-width: 68%;
    }
  
    .\32xl\:flex-69 {
      max-width: 69%;
    }
  
    .\32xl\:flex-\[69\%\] {
      max-width: 69%;
    }
  
    .\32xl\:flex-70 {
      max-width: 70%;
    }
  
    .\32xl\:flex-\[70\%\] {
      max-width: 70%;
    }
  
    .\32xl\:flex-71 {
      max-width: 71%;
    }
  
    .\32xl\:flex-\[71\%\] {
      max-width: 71%;
    }
  
    .\32xl\:flex-72 {
      max-width: 72%;
    }
  
    .\32xl\:flex-\[72\%\] {
      max-width: 72%;
    }
  
    .\32xl\:flex-73 {
      max-width: 73%;
    }
  
    .\32xl\:flex-\[73\%\] {
      max-width: 73%;
    }
  
    .\32xl\:flex-74 {
      max-width: 74%;
    }
  
    .\32xl\:flex-\[74\%\] {
      max-width: 74%;
    }
  
    .\32xl\:flex-75 {
      max-width: 75%;
    }
  
    .\32xl\:flex-\[75\%\] {
      max-width: 75%;
    }
  
    .\32xl\:flex-76 {
      max-width: 76%;
    }
  
    .\32xl\:flex-\[76\%\] {
      max-width: 76%;
    }
  
    .\32xl\:flex-77 {
      max-width: 77%;
    }
  
    .\32xl\:flex-\[77\%\] {
      max-width: 77%;
    }
  
    .\32xl\:flex-78 {
      max-width: 78%;
    }
  
    .\32xl\:flex-\[78\%\] {
      max-width: 78%;
    }
  
    .\32xl\:flex-79 {
      max-width: 79%;
    }
  
    .\32xl\:flex-\[79\%\] {
      max-width: 79%;
    }
  
    .\32xl\:flex-80 {
      max-width: 80%;
    }
  
    .\32xl\:flex-\[80\%\] {
      max-width: 80%;
    }
  
    .\32xl\:flex-81 {
      max-width: 81%;
    }
  
    .\32xl\:flex-\[81\%\] {
      max-width: 81%;
    }
  
    .\32xl\:flex-82 {
      max-width: 82%;
    }
  
    .\32xl\:flex-\[82\%\] {
      max-width: 82%;
    }
  
    .\32xl\:flex-83 {
      max-width: 83%;
    }
  
    .\32xl\:flex-\[83\%\] {
      max-width: 83%;
    }
  
    .\32xl\:flex-84 {
      max-width: 84%;
    }
  
    .\32xl\:flex-\[84\%\] {
      max-width: 84%;
    }
  
    .\32xl\:flex-85 {
      max-width: 85%;
    }
  
    .\32xl\:flex-\[85\%\] {
      max-width: 85%;
    }
  
    .\32xl\:flex-86 {
      max-width: 86%;
    }
  
    .\32xl\:flex-\[86\%\] {
      max-width: 86%;
    }
  
    .\32xl\:flex-87 {
      max-width: 87%;
    }
  
    .\32xl\:flex-\[87\%\] {
      max-width: 87%;
    }
  
    .\32xl\:flex-88 {
      max-width: 88%;
    }
  
    .\32xl\:flex-\[88\%\] {
      max-width: 88%;
    }
  
    .\32xl\:flex-89 {
      max-width: 89%;
    }
  
    .\32xl\:flex-\[89\%\] {
      max-width: 89%;
    }
  
    .\32xl\:flex-90 {
      max-width: 90%;
    }
  
    .\32xl\:flex-\[90\%\] {
      max-width: 90%;
    }
  
    .\32xl\:flex-91 {
      max-width: 91%;
    }
  
    .\32xl\:flex-\[91\%\] {
      max-width: 91%;
    }
  
    .\32xl\:flex-92 {
      max-width: 92%;
    }
  
    .\32xl\:flex-\[92\%\] {
      max-width: 92%;
    }
  
    .\32xl\:flex-93 {
      max-width: 93%;
    }
  
    .\32xl\:flex-\[93\%\] {
      max-width: 93%;
    }
  
    .\32xl\:flex-94 {
      max-width: 94%;
    }
  
    .\32xl\:flex-\[94\%\] {
      max-width: 94%;
    }
  
    .\32xl\:flex-95 {
      max-width: 95%;
    }
  
    .\32xl\:flex-\[95\%\] {
      max-width: 95%;
    }
  
    .\32xl\:flex-96 {
      max-width: 96%;
    }
  
    .\32xl\:flex-\[96\%\] {
      max-width: 96%;
    }
  
    .\32xl\:flex-97 {
      max-width: 97%;
    }
  
    .\32xl\:flex-\[97\%\] {
      max-width: 97%;
    }
  
    .\32xl\:flex-98 {
      max-width: 98%;
    }
  
    .\32xl\:flex-\[98\%\] {
      max-width: 98%;
    }
  
    .\32xl\:flex-99 {
      max-width: 99%;
    }
  
    .\32xl\:flex-\[99\%\] {
      max-width: 99%;
    }
  
    .\32xl\:flex-100 {
      max-width: 100%;
    }
  
    .\32xl\:flex-\[100\%\] {
      max-width: 100%;
    }
  
    
  }